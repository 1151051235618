
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem } from '@material-ui/core'
import { Question } from "../../../../interfaces/shared/Question";
import { QuestionPartType } from '../../../../shared/QuestionSpec'

interface Props {
    question:Question,
    onSelect:(type:QuestionPartType)=>void,
    onClose:()=>void
}

interface State {
    type:QuestionPartType
}

export default class AddPartDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            type: QuestionPartType.Stem
        }
    }

    render() {

        console.log('STATE ' + this.state.type)
        console.log('FOOO ' + Object.keys(QuestionPartType).filter(t => t === 'Stem'))

        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Select Part Type</DialogTitle>
                <DialogContent>
                    <p>Please select a part type to add to this question.</p>
                        <Select
                            variant="outlined"
                            value={this.state.type}
                            onChange={this.handleTypeChange}>
                        { Object.keys(QuestionPartType).map((part =>
                                    <MenuItem selected={this.state.type === part} key={part} value={part}>
                                    {part}
                                    </MenuItem>
                                )) }
                    </Select>
                </DialogContent>
                <DialogActions>
                <Button onClick={this.onSelect} color="secondary">
                    Add Part
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    handleTypeChange = (event: any) => {

        console.log('chg')
        console.dir(event)

        let newType = event.target.value as string

        this.setState({ type: newType as QuestionPartType })
    }

    onClose = () => {
        this.props.onClose()
    }

    onSelect = () => {
        this.props.onSelect(this.state.type)
    }
    
}


