
import React, { Fragment } from "react";
import request from '../../../api';
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, InputLabel } from '@material-ui/core'
import { Exam } from "../../../interfaces/examiner/Exam";
import { loadStripe } from "@stripe/stripe-js";
import { Payment } from "../../../interfaces/teacher/Payment,";

interface Props {
    onClose:()=>void
    onDelete:(exam:Exam)=>void
    exam:Exam
}

interface State {
}

export default class ExamDeleteDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Delete Exam</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wish to delete {this.props.exam.title}?</p>             
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onDelete} color="secondary">
                            Yes
                        </Button>
                        <Button onClick={this.onClose} color="secondary">
                            No
                        </Button>
                    </DialogActions>                    
            </Dialog>
            </div>
        );
    }

    onDelete = () => {
        this.props.onDelete(this.props.exam)
    }

    onClose = () => {
        this.props.onClose()
    }

}