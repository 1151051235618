
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage, ShoppingCart, People } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Exam } from '../../../interfaces/teacher/Exam';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import PurchaseExamDialog from './PurchaseExamDialog';

interface Props {
}

interface State {
  data: Exam[] | null
  totalRows: number
  rowsPerPage: number
  page: number
  sortColumn: string
  sortOrder: 'asc' | 'desc'
  showPurchase: Exam | null
  message: string | null
}

export default class TeacherExamList extends React.Component<Props, State> {

  constructor(props: Props) {

    super(props)

    this.state = {
      data: [],
      totalRows: 0,
      rowsPerPage: 10,
      page: 1,
      sortColumn: 'openDate',
      sortOrder: 'desc',
      showPurchase: null,
      message: null,
    }
  }

  columns: any[] = [
    {
      name: 'Exam',
      selector: 'title',
      sortable: true,
      wrap: true,
    },
    {
      name: 'Start',
      selector: 'openDate',
      sortable: true,
      cell: (exam: Exam) => new Date(exam.openDate).toLocaleDateString()
    },
    {
      name: 'End',
      selector: 'closeDate',
      sortable: true,
      cell: (exam: Exam) => new Date(exam.closeDate).toLocaleDateString()
    },
    {
      name: `Places Bought`,
      sortable: false,
      cell: (exam: Exam) => {
        if (exam.pricingModel == "PER_STUDENT") {
          return `${exam.numPlacesAvailable}`
        }
        if (exam.pricingModel == "FLAT_FEE") {
          if (exam.numPlacesAvailable > exam.numFreePlaces) {
            return `Unlimited`
          }
          return `${exam.numPlacesAvailable}`
        }
        if (exam.pricingModel == "FREE") {
          return `Unlimited`
        }
        if (exam.pricingModel == "LOCKED") {
          return `Unlimited`
        }
      }
    },
    {
      cell: (exam: Exam) => {
        if (!examEnded(exam.closeDate)) {

          if (exam.pricingModel == "PER_STUDENT") {
            return <Button onClick={() => this.purchaseStudents(exam)}>Enrol</Button>
          }
          if (exam.pricingModel == "FLAT_FEE") {
            if (exam.numPlacesAvailable > exam.numFreePlaces) {
              return ''
            }
            return <Button onClick={() => this.purchaseStudents(exam)}>Enrol</Button>
          }
          if (exam.pricingModel == "FREE") {
            if (exam.purchased) {
              return ''
            } else
              return <Button onClick={() => this.purchaseStudents(exam)}>Enrol</Button>
          }
          if (exam.pricingModel == "LOCKED") {
            if (exam.purchased) {
              return ''
            }
          }
        }
      }

    },
    {
      cell: (exam: Exam) => {
        return exam.type == "ONLINE" ? <Fragment>
        {exam.purchased && (
          <Button component={Link} to={"/teacher/exams/" + exam.id}>
            {exam.marksReleased 
              ? (!examEnded(exam.closeDate) 
                  ? "Manage Exam" 
                  : "Download Results")
              : "Manage Exam"}
          </Button>
        )}
      </Fragment> :
        <Fragment>
          {exam.purchased && <Button component={Link} to={"/teacher/exams/" + exam.id}>
            {exam.marksReleased ? "Download Results" : "Manage Exam"}
          </Button>}
        </Fragment>
      }
    }
  ]

  render() {

    let { data, totalRows, rowsPerPage, page } = this.state

    if (data === null) {
      return <div>Loading...</div>
    }

    return (
      <Fragment>
        {this.state.showPurchase && <PurchaseExamDialog exam={this.state.showPurchase} onClose={this.onClose} onPurchase={this.onPurchase} />}
        <DataTable
          columns={this.columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
          sortServer
          onSort={this.handleSort}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onChangePage={this.handleChangePage}
          noHeader
        />
      </Fragment>
    )
  }

  componentDidMount() {
    this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder } = this.state

    let { exams, total } = await (
      await request(`/api/teacher/exams?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: exams, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage: number) => {
    await this.setState(prevState => ({ ...prevState, rowsPerPage }))
    this.reload()
  }

  handleChangePage = async (page: number) => {
    await this.setState(prevState => ({ ...prevState, page }))
    this.reload()
  }

  handleSort = async (column: any, sortDirection: any) => {
    await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
    this.reload()
  }

  purchaseStudents = async (exam: Exam) => {
    await this.setState(prevState => ({ ...prevState, showPurchase: exam }))
  }

  onClose = () => {
    this.setState(prevState => ({ ...prevState, showPurchase: null }))
  }

  onPurchase = () => {
    this.setState(prevState => ({ ...prevState, showPurchase: null }))
    this.reload()
  }
}

function examEnded(endDate: string) {
  let examEndDate = new Date(endDate)
  return new Date() > examEndDate
}