
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, TextField } from "@material-ui/core"
import React, { Fragment } from "react"
import { Link, Redirect } from "react-router-dom"
import request, { apiLogin, getConfigSync, isLoggedIn } from "../../api"

interface Props {
}

interface State {
    username:string
    password:string
    cookies:boolean
    error:string
    picture:string
}

export default class Login extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props)

        this.state = {
            username: '',
            password: '',
            cookies: false,
            error: '',
            picture: randomFromArray(getConfigSync().loginPictures)
        }

    }

    render() {

        let canLogin = this.state.cookies && this.state.username !== '' && this.state.password !== ''

        return <Container maxWidth="sm">
            <Fragment>
                <form noValidate onSubmit={this.onClickLogin}>
                    <Grid container direction="column" alignItems="center">
                        <h1>{getConfigSync().competitionShortName} Exam Portal</h1>
                        <img src={this.state.picture} style={{width: '50%', maxHeight: '200px'}} />
                        <br />
                        <p> <strong>Students:</strong> Please login below with the account and password provided by your teacher. <br /><br />
                        <strong>Teachers:</strong> Please login below with your email address or three-letter school code as the username. You can reset your password below, if required. <br /> If your school has not taken part in our competitions previously, you can request access to the exam portal via the link below.</p>
                        <br />
                        <TextField id="username" label="Username" variant="outlined" fullWidth onChange={this.onChangeUsername} />
                        <br />
                        <TextField id="password" label="Password" type="password" variant="outlined" fullWidth onChange={this.onChangePassword} helperText="Passwords are case sensitive"/>
                        <br/>
                        <FormControlLabel
                        control={
                        <Checkbox
                                    onClick={this.toggleCookies}
                            checked={this.state.cookies}
                            color="primary"
                        />
                        }
                        label={`I accept the ${getConfigSync().competitionShortName} privacy policy and use of cookies`}
                    />
                        {
                            this.state.error &&
                                <Fragment>
                                    <div style={{color: 'red'}}>{this.state.error}</div>
                                    <br/>
                                </Fragment>
                        }
                        <Button type="submit" variant="outlined" size="large" disabled={!canLogin}>Login</Button>
                    </Grid>
                </form>
                <Grid container justify="center"  spacing={2} style={{ marginTop: 10 }}>
                    <Grid item sm={6}>
                        <Button color="primary" variant="outlined" component={Link} to={'/resetTeacherPassword'}>Reset teacher password</Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button color="primary" variant="outlined"  onClick={() => window.location.href=getConfigSync().competitionSignup}>Sign-up for teacher access</Button>
                    </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center"  style={{ marginTop: 15 }}>
                    <Button onClick={() => window.location.href=getConfigSync().competitionHomepage}>{getConfigSync().competitionShortName} Homepage</Button>
                </Grid>
            </Fragment>

        </Container>
    }

    onChangeUsername = (e:any) => {
        this.setState((prevState) => ({ ...prevState, username: e.target.value }))
    }

    onChangePassword = (e:any) => {
        this.setState((prevState) => ({ ...prevState, password: e.target.value }))
    }

    toggleCookies = () => {
        this.setState((prevState) => ({ ...prevState, cookies: !prevState.cookies }))
    }

    onClickLogin = async (e:any) => {

        e.preventDefault()

        let { username, password } = this.state

        let res = await apiLogin(username, password)

        if(res.error) {
            this.setState((prevState) => ({ ...prevState, error: 'Username or password incorrect' }))
        } else {
            window.location.href = '/' + res.type
        }
    }
}

function randomFromArray(arr:any[]) { return arr[Math.floor(Math.random() * arr.length)] }