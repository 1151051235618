
// https://codesandbox.io/s/ql08j35j3q

import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

const grid = 8;

const getItemStyle = (isDragging:any, draggableStyle:any) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? '#ccc' : '#ddd',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = (isDraggingOver:any) => ({
    background: isDraggingOver ? '#ccffcc' : '#f0f0f0',
    padding: grid,
    overflowY: 'none' as any,
    overflowX: 'none' as any,
    minWidth: '200px'
});

export interface ListEntry {
    id:string
    content:JSX.Element
}

export interface List {
    heading?:JSX.Element
    entries:ListEntry[]
}


export interface Props {
    lists:List[],
    onChange:(lists:List[])=>void
}
export default class DragDrop extends Component<Props> {

    onDragEnd = (result:any) => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        let listsCopy:List[] = this.props.lists.map(l => ({ ...l, entries: l.entries.slice(0) }))

        let sourceId = parseInt(source.droppableId.split('-')[1])
        let destId = parseInt(destination.droppableId.split('-')[1])

        if (sourceId === destId) {

            let list = listsCopy[sourceId]
            list.entries.splice(destination.index, 0, list.entries.splice(source.index, 1)[0]);

        } else {

            let sourceList = listsCopy[sourceId]
            let destList = listsCopy[destId]

            destList.entries.splice(destination.index, 0, sourceList.entries.splice(source.index, 1)[0]);
        }

        this.props.onChange(listsCopy);
    };

    render() {
        return (
            <DragDropContext onDragEnd={this.onDragEnd}>
                {
                    this.props.lists.map((list, i) => 
                        <Droppable droppableId={'droppable-' + i}>
                        {(provided:any, snapshot:any) => (
                            <div style={{display: 'inline-block', verticalAlign: 'top'}}>
                                { list.heading && list.heading }
                            <div
                                ref={provided.innerRef}
                                style={getListStyle(snapshot.isDraggingOver)}>
                                {list.entries.map((item, index) => {
                                    return <Draggable
                                        key={item.id}
                                        draggableId={item.id}
                                        index={index}>
                                        {(provided:any, snapshot:any) => (
                                            <div
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                {...provided.dragHandleProps}
                                                style={getItemStyle(
                                                    snapshot.isDragging,
                                                    provided.draggableProps.style
                                                )}>
                                                {item.content}
                                            </div>
                                        )}
                                    </Draggable>
                                })}
                                {provided.placeholder}
                            </div>
                            </div>
                        )}
                    </Droppable>
                    )
                }
            </DragDropContext>
        );
    }
}

