
import React from "react";



export default function SavingIndicator(props:{saving:boolean}) {

    return (<div style={{ position: 'fixed', right: 0, bottom: 0, zIndex:1000, padding: '8px' }}>
        { props.saving && "Saving..." }
    </div>)
}
