import { Button, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { Fragment } from "react"
import { Question } from "../../../../../interfaces/shared/Question"
import { Content, QuestionPart, QuestionPartMultipleTrueFalse } from "../../../../../shared/QuestionSpec"
import uuid from "../../../../../uuid"
import ContentEditor from './ContentEditor'


interface Props {
    part: QuestionPartMultipleTrueFalse
    onUpdate: (oldPart: QuestionPart, newPart: QuestionPart) => void
}

export default class MultipleTrueFalseEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let part = this.props.part

        return <Fragment>
            <div>
                <h3>Action Statement</h3>
                <ContentEditor subject={part} content={part.actionStatement} onUpdateContent={this.onUpdateActionStatement}/>
            </div>
            <div>
                <h3>Options</h3>
                <TableContainer component={Paper}>           
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Order</TableCell>
                            <TableCell align="left">Content</TableCell>
                            <TableCell align="left">Is True?</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {part.questions.map((question, i) => (
                            <TableRow>
                                <TableCell component="th" scope="question">
                                    {i > 0 && <Button onClick={() => this.moveRow(i, i - 1)}>▲</Button>}
                                    <br />
                                    {i < part.questions.length - 1 && <Button onClick={() => this.moveRow(i, i + 1)}>▼</Button>}
                                    <br />
                                    <Button onClick={() => this.deleteRow(i)}><Delete /></Button>
                                </TableCell>
                                <TableCell align="left">
                                    <ContentEditor subject={question} content={question.content} onUpdateContent={this.onUpdateContent} />
                                </TableCell>
                                <TableCell align="left">
                                    <Checkbox checked={question._answer} onClick={() => this.toggleCorrectOption(i)}></Checkbox>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Button color="primary" variant="outlined" onClick={this.addOption}>
                                    + Add Option
                                </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
        </Fragment>
    }

    onUpdateActionStatement = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            actionStatement: newContent
        }

        this.props.onUpdate(part, newPart)
    }

    onUpdateContent = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            questions: part.questions.map(question => {
                if (question === subject) {
                    return {
                        ...question,
                        content: newContent
                    }
                } else {
                    return question
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }   

    addOption = () => {

        let part = this.props.part

        let newPart = {
            ...part,
            questions: part.questions.concat([
                {
                    id: uuid(),
                    content: {
                        text: null,
                        image: null
                    },
                    _answer: false
                }
            ])
        }

        this.props.onUpdate(part, newPart)
    }

    moveRow(n: number, n2: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart.questions.splice(n2, 0, newPart.questions.splice(n, 1)[0])

        this.props.onUpdate(part, newPart)
    }

    deleteRow(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        part.questions.splice(n, 1)

        this.props.onUpdate(part, newPart)
    }

    toggleCorrectOption(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        part.questions[n]._answer = !part.questions[n]._answer

        this.props.onUpdate(part, newPart)
    }
}




