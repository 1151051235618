import { Button, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { Fragment } from "react"
import { Content, QuestionPart, QuestionPartOneWordAnswer } from "../../../../../shared/QuestionSpec"
import ContentEditor from './ContentEditor'

interface Props {
    part:QuestionPartOneWordAnswer
    onUpdate:(oldPart: QuestionPart, newPart: QuestionPart)=>void
  }
  

export default class OneWordAnswerEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let part = this.props.part

        return <Fragment>
            <div>
                <h3>Action Statement</h3>
                <ContentEditor subject={part} content={part.actionStatement} onUpdateContent={this.onUpdateActionStatement}/>
            </div>
            <h3>Answers</h3>
            <TableContainer component={Paper}>
                <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell align="left">Answer</TableCell>
                            <TableCell align="left">Case Insensitive</TableCell>
                            <TableCell align="left">Ignore Spaces</TableCell>
                            <TableCell align="left">Ignore Punctuation</TableCell>
                            <TableCell align="left">Numerical Answer</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {part._correctAnswers.map((answer, i) => (
                            <TableRow>
                                <TableCell align="left">
                                    <TextField onChange={(e) => this.onUpdateAnswerText(i, e)} value={answer.text} placeholder="Enter Answer..." />
                                </TableCell>
                                <TableCell align="left">
                                    <Checkbox checked={answer.ignoreCase} onClick={() => this.toggleCaseInsensitive(i)}></Checkbox>
                                </TableCell>
                                <TableCell align="left">
                                    <Checkbox checked={answer.ignoreSpaces} onClick={() => this.toggleIgnoreSpaces(i)}></Checkbox>
                                </TableCell>
                                <TableCell align="left">
                                    <Checkbox checked={answer.ignorePunctuation} onClick={() => this.toggleIgnorePunctuation(i)}></Checkbox>
                                </TableCell>
                                <TableCell align="left">
                                    <Checkbox checked={answer.numericalAnswer} onClick={() => this.toggleNumericalAnswer(i)}></Checkbox>
                                </TableCell>
                            </TableRow>
                        ))}
                        <TableRow>
                            <TableCell colSpan={3}>
                                <Button color="primary" variant="outlined" onClick={this.addAnswer}>
                                    + Add Answer
                            </Button>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </Fragment>
    }

    onUpdateContent = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            content: newContent
        }

        this.props.onUpdate(part, newPart)
    }

    addAnswer = () => {

        let part = this.props.part

        let newPart = {
            ...part,
            _correctAnswers: part._correctAnswers.concat([
                {
                    text: '',
                    ignoreCase: true,
                    ignorePunctuation: true,
                    ignoreSpaces: true,
                    numericalAnswer: false
                }
            ])
        }

        this.props.onUpdate(part, newPart)
    }

    deleteRow(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart._correctAnswers.splice(n, 1)

        this.props.onUpdate(part, newPart)
    }

    toggleCaseInsensitive(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart._correctAnswers[n].ignoreCase = 
            !newPart._correctAnswers[n].ignoreCase

        this.props.onUpdate(part, newPart)
    }

    toggleIgnoreSpaces(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart._correctAnswers[n].ignoreSpaces = 
            !newPart._correctAnswers[n].ignoreSpaces

        this.props.onUpdate(part, newPart)
    }
    
    toggleNumericalAnswer(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart._correctAnswers[n].numericalAnswer = 
            !newPart._correctAnswers[n].numericalAnswer

        this.props.onUpdate(part, newPart)
    }

    toggleIgnorePunctuation(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart._correctAnswers[n].ignorePunctuation = 
            !newPart._correctAnswers[n].ignorePunctuation

        this.props.onUpdate(part, newPart)
    }

    onUpdateAnswerText= (n: number, e: any) => {

        let part = this.props.part

        let newPart = {
            ...part,
        }

        newPart._correctAnswers[n].text=e.target.value

        this.props.onUpdate(part, newPart)
    }

    onUpdateActionStatement  = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            actionStatement: newContent
        }

        this.props.onUpdate(part, newPart)
    }
}