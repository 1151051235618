

import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle } from '@material-ui/core'
import { Paper } from "../../../interfaces/shared/Paper";
import { Question } from "../../../interfaces/shared/Question";
import ExaminerQuestionPickerList from "./ExaminerQuestionPickerList";

interface Props {
    paper:Paper
    onAdd:(question:Question) => void
    onClose:() => void
}

interface State {
}

export default class AddQuestionToPaperDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)
    }

    render() {

        return (
            <div>
            <Dialog maxWidth="md" fullWidth open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add Question to Paper</DialogTitle>
                <DialogContent>
                    <ExaminerQuestionPickerList onSelect={this.onSelectQuestion} />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    onSelectQuestion = (question:Question) => {
        this.props.onAdd(question)
    }
    
}

