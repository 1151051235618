// CustomLink.js
import React, { ReactNode } from 'react';

interface CustomLinkProps {
  children: ReactNode;
  [key: string]: any; // Allow any additional props
}

const studentContentTable: React.FC<CustomLinkProps> = ({ children, ...props }) => {
  return (
    <table {...props} style={{ borderCollapse: 'collapse' }}>
      {children}
    </table>
  );
};

export default studentContentTable;
