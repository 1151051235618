import apiRequest from "./api"


export async function studentPoll() {
    while(true) {
        let res = await apiRequest('/api/student/poll')
        if(res.status === 200) {
            let t = await res.text()
             if(t === 'refresh') {
                 window.location.reload()
            }
         }
        await delay()
    }
   
   
   async function delay() {
       return new Promise<void>((resolve, reject) => {
           setTimeout(() => resolve(), 5000)
       })
   }
}
           