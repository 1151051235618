
import { Button, debounce, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Paper as MaterialPaper, Link, Paper, Box, TableHead } from '@material-ui/core';
import React, { Fragment } from 'react';
import ExaminerContainer from '../ExaminerContainer';

import '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import SavingIndicator from '../../../components/SavingIndicator';
import { Cancel } from '@material-ui/icons';
import { Student } from '../../../interfaces/examiner/Student';
import request, { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import { QuestionPartType } from '../../../shared/QuestionSpec';
import { Answer } from '../../../interfaces/examiner/Answer';
import { AnswerSpec } from '../../../shared/AnswerSpec';

interface Props {
    examId: string
    id: string
}

interface State {
    student: Student | null,
    saving: boolean
}

export default class ExaminerStudentPage extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            student: null,
            saving: false
        }
    }

    render() {

        let { student, saving } = this.state

        if (!isLoggedIn('examiner')) {
            return <Redirect to='/login' />
        }

        if (!student) {
            return <ExaminerContainer heading={"Loading..."}>Loading...</ExaminerContainer>
        }

        let overallScore = student!.scores.filter((s: any) => !s.paperId)[0]?.score

        return (
            <ExaminerContainer heading={student.username}>
                <SavingIndicator saving={saving} />
                <div><h2>{student.username}</h2></div>
                <TextField autoFocus margin="dense"
                    id="name" label="Name"
                    fullWidth defaultValue={student.name}
                    onChange={(e) => this.handleFieldChange('name', e)} />
                <h2>Results</h2>
                Overall score: {formatPercentage(overallScore)}
                {
                    student.sittings.map(sitting => {

                        let paperScore = student!.scores.filter((s: any) => s.paperId === sitting.paper.id && !s.questionId)[0]?.score

                        let totalPaperTime = 0
                        for (let i = 0; i < sitting.answers.length; i++) {
                            sitting.answers[i].answer.version > 1 ? totalPaperTime += sitting.answers[i].answer.timeOnQuestion : totalPaperTime += 0
                        }

                        let datesMin = sitting.answers.map((s: { createdAt: any; }) => s.createdAt)
                        let orderedDatesMin = datesMin.sort(function (a: string, b: string) {
                            return Date.parse(a) - Date.parse(b);
                        });
                        let minDate = orderedDatesMin[0]

                        let datesMax = sitting.answers.map((s: { updatedAt: any; }) => s.updatedAt)
                        let orderedDatesMax = datesMax.sort(function (a: string, b: string) {
                            return - Date.parse(a) + Date.parse(b);
                        });
                        let maxDate = orderedDatesMax[0]

                        return <Fragment>
                            <h3>{sitting.paper.title}</h3>
                            <p>
                                Paper score: {formatPercentage(paperScore)}
                                <br />
                                Time on paper (mins): {Math.round(totalPaperTime / 60)}
                                <br />
                                Sitting opened and closed: {sitting.started} to  {sitting.finished} (that's {Math.floor((new Date(sitting.finished).getTime() - new Date(sitting.started).getTime()) / 1000 / 60)} mins)
                                <br />
                                MinDate: {minDate} and MaxDate: {maxDate}
                            </p>
                            <TableContainer>
                                <Table>
                                    <TableBody>
                                        {
                                            sitting.paper.questions.map((question: any) => {

                                                let answer: AnswerSpec = sitting.answers.filter((a: Answer) => a.questionId === question.id)[0]?.answer
                                                let time = sitting.answers.filter((a: Answer) => a.questionId === question.id)[0]?.updatedAt
                                                let timeCreated = sitting.answers.filter((a: Answer) => a.questionId === question.id)[0]?.createdAt
                                                
                                                let questionScore = student!.scores.filter((s: any) => s.questionId === question.id && !s.questionPartId)[0]?.score.toString()
                                                questionScore = formatPercentage(questionScore)

                                                return <Fragment>
                                                    <TableRow>
                                                        <TableCell style={{ backgroundColor: '#aaa' }}>{question.description}</TableCell>
                                                        <TableCell style={{ backgroundColor: '#aaa' }}>{questionScore}</TableCell>
                                                        <TableCell style={{ backgroundColor: '#aaa' }}>{answer && answer.version > 1 ? (answer.answered ? "Flagged: " + answer.everFlagged!.toString() + " Time: " + Math.round(answer.timeOnQuestion!).toString() : "Opened, not answered") : "Did not open!"}</TableCell>
                                                        <TableCell style={{ backgroundColor: '#aaa' }}></TableCell>
                                                    </TableRow>
                                                    {
                                                        question.questionSpec.parts.filter((p: any) => p.type !== QuestionPartType.Stem).map((part: any) => {

                                                            let partScore = student!.scores.filter((s: any) => s.questionId === question.id && s.questionPartId === part.id)[0]?.score.toString()
                                                            partScore = formatPercentage(partScore)
                                                            let answerPart = answer ? answer.parts.filter(p => p.questionPartId == part.id)[0]?.questionPartId : "na"

                                                            return <TableRow>
                                                                <TableCell>{part.type}</TableCell>
                                                                {/* <TableCell>{part && JSON.stringify(part)}</TableCell> */}
                                                                <TableCell>{partScore}</TableCell>
                                                                <TableCell>{timeCreated}</TableCell>
                                                                <TableCell>{time}</TableCell>
                                                            </TableRow>

                                                        })
                                                    }
                                                </Fragment>
                                            })
                                        }
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </Fragment>

                    })
                }
            </ExaminerContainer>
        )
    }

    componentDidMount() {

        this.reloadStudent()

    }

    private async reloadStudent() {

        let student = await (await request(`/api/examiner/exams/${this.props.examId}/students/` + this.props.id)).json()

        this.setState(prevState => ({ ...prevState, student }))
    }

    async saveWithDelay() {
        await this.setState(prevState => ({ ...prevState, saving: true }))
        this.saveDebounced()
    }

    saveDebounced = debounce(async () => this.save(), 1000)

    async save() {
        await request(`/api/examiner/exams/${this.props.examId}/students/` + this.props.id, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(this.state.student)
        })
        await this.setState(prevState => ({ ...prevState, saving: false }))
    }

    handleFieldChange = async (field: string, e: any) => {
        let student = this.state.student as Student
        let newStudent: Student = { ...student, [field]: e.target.value }
        await this.setState(prevState => ({ ...prevState, student: newStudent }))
        this.saveWithDelay()
    }

}

function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    s = s + ''
    if (s.length > 5) {
        s = s.substr(0, 5)
    }
    return s + '%'
}

