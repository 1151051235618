
import { Checkbox, Grid } from "@material-ui/core"
import React from "react"
import Content from "../../../../components/questions/Content"
import { Score } from "../../../../interfaces/examiner/Score"
import { AnswerPart, AnswerPartListDivideIntoGroups } from "../../../../shared/AnswerSpec"
import { QuestionPartListDivideIntoGroups } from "../../../../shared/QuestionSpec"

interface Props {
    part:QuestionPartListDivideIntoGroups
    answers:AnswerPartListDivideIntoGroups[]|null
    scores:Score[]
}

export default function ListDivideIntoGroupsScore(props:Props) {

    let { part,scores, answers} = props

    let totalScores = scores.filter((s: Score) => s.type === "PAPER")
    let totals = new Map()  
    for (let t of totalScores) { totals.set(t.examPaperSittingId, t) }

    return <div>
         <Content content={part.actionStatement} />
         <p>Avg score: {avgScore(scores.filter((s: Score) => s.questionPartId === part.id))}</p>
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <table>
         <tr>
                        <td>
                                    Option
                        </td>
                        {part.groups.map(g=>{
                           return <td>
                                {g.heading.text}
                            </td>}
                        )}
                        <td>
                               Not Sorted
                        </td>
                        <td>
                                %
                        </td>
                        <td>
                                Corr
                        </td>
                    </tr>
         {
             part.options.map((option) => {
                let optGroups = answers!.map(a=>a.optionToGroup.filter((opt) => opt.optionId === option.id)[0]?.group).filter(g=>g)
                 return(
                    <tr>
                        <td>
                                    {option.content.text}

                        </td>
                        {part.groups.map(g=>{
                            return (<td>
                                {g.id==option._correctGroupId ? <b>{optGroups.filter(j=>j ===g.id).length}</b> : optGroups.filter(j=>j ===g.id).length}
                                
                            </td>)}
                        )}
                        <td>
                                {answers!.length-optGroups.length}
                        </td>
                        <td>
                                {formatPercentage(optGroups.filter(j=>j === option._correctGroupId).length/answers!.length*100)}
                        </td>
                        <td>
                                {answers && answers.length > 0 ? corrScore(option, answers, totals) : "na"}
                        </td>
                    </tr>
                 )
             })
         }
         </table>
    </div>

   
}

function avgScore(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        total = total + parseFloat(score[i].score);
    }
    var avg = total / i
    return formatPercentage(avg);
}


function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    let s2 = Math.round(s * 100) / 100
    return s2 + '%'
}

function pcorr(x: number[], y: number[]) {
    let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        sumY2 = 0;
    const minLength = x.length = y.length = Math.min(x.length, y.length),
        reduce = (xi: number, idx: number) => {
            const yi = y[idx];
            sumX += xi;
            sumY += yi;
            sumXY += xi * yi;
            sumX2 += xi * xi;
            sumY2 += yi * yi;
        }
    x.forEach(reduce);
    return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
};

function corrScore(option: any, answers: any, scores: any) {
    var wrongRight = [];
    var score = [];
    for (var i = 0; i < answers.length; i++) {
        let id = answers[i].examPaperSittingId
        if (scores.get(id) && answers[i].optionToGroup && answers[i].optionToGroup.filter((opt:any) => opt.optionId === option.id)[0]) {
            wrongRight.push(answers[i].optionToGroup.filter((opt:any) => opt.optionId === option.id)[0].group === option._correctGroupId ? 1 : 0)
            score.push(parseFloat(scores.get(id).score));
        }
    }
    console.log(wrongRight, score)
    var corr = pcorr(wrongRight, score)
    return corr.toFixed(2);
}