import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Receipt, QuestionAnswer, People, Dashboard, Timer, PlayArrow, AccessTime, Check } from '@material-ui/icons';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import ResponsiveDrawer from '../../ResponsiveDrawer';
import { Box, Button, Card, CardContent, Container, CssBaseline, Grid } from '@material-ui/core';
import { spacing } from '@material-ui/system';
import { Link, Redirect } from 'react-router-dom'
import { Exam } from '../../interfaces/student/Exam';
import { Paper } from '../../interfaces/shared/Paper';

import { format } from 'date-fns'
import request, { isLoggedIn } from '../../api';
import { Home } from '../../interfaces/student/Home';
import StudentDetailsDialog from './StudentDetailsDialog';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'
interface Props {
}

interface State {
    exam:Exam|null,
    name:string
    yearGroup:string
    redirectToSittingId:string|null
    message:string|null
    navigate: boolean
    tempPassword: boolean
    gender:string
}

export default class StudentHome extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props)

        this.state = {
            exam: null,
            redirectToSittingId:null,
            name: '',
            yearGroup: '',
            gender: '',
            message: null,
            navigate: false,
            tempPassword: false,
        }

        this.load()
    }

    render() {

        let { name ,exam, redirectToSittingId, message, navigate, tempPassword, yearGroup, gender} = this.state


    if(!isLoggedIn('student')) {
        return <Redirect to='/login' />
    }

    if (navigate) {
        request('/api/student/logout/', { method: 'DELETE' })
        sessionStorage.removeItem(`ukbc_student`)
        return <Redirect to="/login" push={true} />
    }

        if(redirectToSittingId !== null) {
            return <Redirect to={`/student/sittings/${redirectToSittingId}`} push={true} />
        }

        let examsSection =
            exam === null ?
                <div>No exam to show</div>
                : this.renderExam(exam, name)

        return <main  style={{ background: '#f7f7ff'}}>
            {
                tempPassword &&
                <StudentDetailsDialog message={message} yearGroup={yearGroup} name={name} gender={gender} onSubmit={this.onSubmitDetails} />
            }
            <Container maxWidth="sm">
                <Box p={2}>
                    { examsSection }
                </Box>
                <Box display="flex" justifyContent="center" p={2}>
                    <Button onClick={()=> this.setState(prevState => ({ ...prevState, navigate: true }))}>Logout</Button>
                </Box>
            </Container>
            </main>


    }

    private renderExam(exam:Exam, name:string) {
        return <Card>
            <CardContent>
                <h3><Grid container direction="row" alignItems="center"><AccessTime/>&nbsp;&nbsp;{exam.title}</Grid></h3>
                <b>Opening Date:</b> {renderDate(exam.openDate)}
                <br/>
                <b>Closing Date:</b> {renderDate(exam.closeDate)}
                <br/>
                <h4>{name}</h4>
                <h3>Rubric</h3>
                <p style={{whiteSpace: 'pre-line'}}><ReactMarkdown plugins={[gfm]}>{exam.studentRubric}</ReactMarkdown></p>
                {
                    exam.papers.map(paper => (
                        paper.sitting ?
                            this.renderStartedPaperDetails(exam, paper) :
                            this.renderNotYetStartedPaperDetails(exam, paper)
                    ))
                }
            </CardContent>
        </Card>
    }

    private renderNotYetStartedPaperDetails(exam:Exam, paper:Paper) {
        return <Card style={{marginTop:'10px'}}> <CardContent>
            <h4><Grid container direction="row" alignItems="center"><Receipt/>&nbsp;&nbsp;{paper.title}</Grid></h4>
            <b>Duration:</b> {paper.duration?.toString() || '?'} minutes
            <br />
            <b>Total questions:</b> {paper.questionCount?.toString() || '?'} questions
            <br />
            <b>Total marks:</b> {paper.markCount?.toString() || '?'} marks
            <br />
            <br />
            <Button disabled={!paper.isActive} variant="outlined" color="primary" onClick={() => this.onClickSitPaper(exam, paper)}><Timer /> &nbsp;Sit Paper</Button>
        </CardContent> </Card>
    } 

    private renderStartedPaperDetails(exam:Exam, paper:Paper) {

        // let sitting = paper.sittings[0]

        return <Card style={{backgroundColor: 'rgba(0,0,0,0.05)', marginTop:'10px'}}> <CardContent>
            <h4><Grid container direction="row" alignItems="center"><Receipt/>&nbsp;&nbsp;{paper.title}</Grid></h4>
            <b>Duration:</b> {paper.duration?.toString() || '?'} minutes
            <br />
            <b>Total questions:</b> {paper.questionCount?.toString() || '?'} questions
            <br />
            <b>Total marks:</b> {paper.markCount?.toString() || '?'} marks
            <br />
            <br />
            { paper.sitting!.finished ?
                <p>✓ Submitted {renderDate(paper.sitting!.finished)}</p>
            : 
                <Button variant="outlined" color="primary" onClick={() => this.onClickContinuePaper(exam, paper)}><PlayArrow /> &nbsp;Continue</Button>
            }
        </CardContent> </Card>
    }




    async load() {

        let res:Home = await (await request('/api/student')).json()

        this.setState((prevState) => ({ ...prevState, exam: res.exam, name: res.name, tempPassword: res.tempPassword, yearGroup: res.yearGroup, gender:res.gender }))
    }

    onClickSitPaper = async (exam:Exam, paper:Paper) => {

        let res:any = await (await request('/api/student/exam/papers/' + paper.id + '/start', { method: 'POST' })).json()

        this.setState((prevState) => ({ ...prevState, redirectToSittingId: res.sittingId }))

    }

    onClickContinuePaper = async (exam:Exam, paper:Paper) => {

        this.setState((prevState) => ({ ...prevState, redirectToSittingId: paper.sitting!.id }))

    }

    onSubmitDetails = async (name:string, yearGroup:string,password1:string,password2:string,gender:string) => {

        let message:string = await (await request('/api/student', {
            method: 'PUT',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                name, yearGroup, password1, password2, gender
            })
        })).text()
        if (message == "ok"){
            this.setState((prevState) => ({ ...prevState, name, tempPassword: false }))
        }
        else {
            this.setState((prevState) => ({ ...prevState, message }))
        }
    }

}


function renderDate(d:string) {
    return format(new Date(d), 'dd/MM/yyyy HH:mm')
}
