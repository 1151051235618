

import { Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Receipt } from '@material-ui/icons'
import { Exam } from '../../interfaces/examiner/Exam'
import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import ExaminerNav from './ExaminerContainer';
import ExaminerContainer from './ExaminerContainer';
import ExaminerExamList from './exams/ExaminerExamList';
import { isLoggedIn } from '../../api';

interface Props {
}

interface State {
  exams:Exam[]|null
}

export default class ExaminerDashboard extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      exams: null
    }
  }

  render() {

    let { exams } = this.state

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    return (
          <ExaminerContainer heading="Examiner Dashboard">

            <h2>Exams</h2>
            <ExaminerExamList isSuperuser = {false} />

        </ExaminerContainer>
    )
  }


}

