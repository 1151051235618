
let config:any = null

export function getConfigSync() {

    if(!config) {
        throw new Error('config not yet loaded')
    }

    return config
}

export async function getConfig() {

    if(config)
        return config

    let frontendConfig = await (await fetch('/config.json')).json()
    let backendConfig = await (await fetch(frontendConfig.apiUrl + '/api/config')).json()

    config = {
        ...frontendConfig,
        ...backendConfig
    }

    return config
}

export default async function apiRequest(input:RequestInfo, init?:RequestInit|undefined):Promise<Response> {

    let reqType = input.toString().split('/')[2]

    let token = sessionStorage.getItem(`ukbc_${reqType}`)

    init = init || {}
    init.headers = init.headers || {};

    (init.headers as any)['x-api-key'] = token

    let res = await fetch((await getConfig()).apiUrl + input, init)

    // if (res.status === 403) {
    //     window.location.href = '/' + reqType + '/login'
    // }

    return res
}

export async function apiLogin(username:string, password:string):Promise<{type:string|null,error:string|null}> {

    let res = await fetch((await getConfig()).apiUrl + `/api/login`, {
        method: 'POST',
        body: JSON.stringify({
            username, password
        }),
        headers: {
            'content-type': 'application/json'
        }
    })

    if(res.status !== 200) {
        return { error: await res.text(), type: null }
    }

    let { token, type, superuser } = await res.json()

    sessionStorage.setItem(`ukbc_${type}`, token)

    if (superuser) sessionStorage.setItem(`ukbc_superuser`, superuser)

    return { error: null, type }
}

export async function apiResetPassword(username:string):Promise<{error:string}> {

    let res = await fetch((await getConfig()).apiUrl + `/api/resetPassword`, {
        method: 'POST',
        body: JSON.stringify({
            username
        }),
        headers: {
            'content-type': 'application/json'
        }
    })

    return { error: await res.text()}

}

export function isLoggedIn(userType:string):boolean {

    let token = sessionStorage.getItem(`ukbc_${userType}`)

    return !!token
}

