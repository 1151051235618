
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage, EmojiEvents } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Student } from '../../../interfaces/teacher/Student';
import DataTable from 'react-data-table-component';
import AddStudentsDialog from './AddStudentsDialog';
import { Exam } from '../../../interfaces/teacher/Exam';
import request from '../../../api';
import ExaminerContainer from '../../examiner/ExaminerContainer';
import FileSaver from 'file-saver'

interface Props {
  exam: Exam
  onStudentsChanged: () => void
}

interface State {
  data: Student[] | null
  totalRows: number
  rowsPerPage: number
  page: number
  sortColumn: string
  sortOrder: 'asc' | 'desc'
  showAddStudentsDialog: boolean
  downloadDisabled: boolean
}

export default class TeacherStudentList extends React.Component<Props, State> {

  constructor(props: Props) {

    super(props)

    this.state = {
      data: [],
      totalRows: 0,
      rowsPerPage: 10,
      page: 1,
      sortColumn: 'username',
      sortOrder: 'desc',
      showAddStudentsDialog: false,
      downloadDisabled: false
    }
    console.dir(this.props.exam)
  }

  columns: any[] = [
    {
      name: 'Username',
      selector: 'username',
      sortable: true
    },
    {
      name: 'Name',
      selector: 'name',
      sortable: true
    },
    {
      name: 'Year Group',
      selector: 'yearGroup',
      sortable: true
    },
    this.props.exam.marksReleased === true && {
      name: 'Medal',
      cell: (student: Student) => <Fragment>
        {student.scores[0] ? (student.scores[0].medal ? <span style={{ color: medalToColour(student.scores[0].medal), fontWeight: "bold" }}>{student.scores[0].medal} </span> : '') : ''}
      </Fragment>
    },
    {
      cell: (student: Student) => <Fragment>
        <Button component={Link} to={`/teacher/exams/${student.examId}/students/` + student.id}><Edit /></Button>
        {this.props.exam.certificate && this.props.exam.marksReleased === true && (student.scores[0] ? student.scores[0].medal : false) && <Button onClick={() => this.downloadCertificate(student.id)}>{this.state.downloadDisabled ? <EmojiEvents color="disabled" /> : <EmojiEvents /> }</Button>}
      </Fragment>
    }
  ].filter(column => column)

  render() {

    let { data, totalRows, rowsPerPage, page, showAddStudentsDialog } = this.state

    if (data === null) {
      return <div>Loading...</div>
    }

    return (
      <Fragment>
        {showAddStudentsDialog && <AddStudentsDialog onAddStudents={this.onAddStudents} onClose={this.onCloseAddStudents} maxStudent={this.props.exam.numPlacesAvailable - this.props.exam.numPlacesUsed} />}
        {!examEnded(this.props.exam.closeDate) && < Button disabled={this.props.exam.numPlacesAvailable <= this.props.exam.numPlacesUsed} variant="outlined" color="primary" onClick={this.handleCreateStudent}>
          Create Students
        </Button>}
        {!examEnded(this.props.exam.closeDate) && < Button disabled={this.state.downloadDisabled} variant="outlined" color="primary" onClick={this.downloadDetails}>
          Download Account Information
        </Button>}
        {this.props.exam.marksReleased &&
            <Button variant="outlined" color="primary" disabled={this.state.downloadDisabled} onClick={this.downloadResults}>
              Download Results
            </Button>
        }
            {this.props.exam.marksReleased && this.props.exam.certificate &&
            <Button  disabled={this.state.downloadDisabled} variant="outlined" color="primary" onClick={this.downloadCertificates}>
            Download Certificates
            </Button>
        }
        <br />
        {!examEnded(this.props.exam.closeDate) && <Fragment>
          <br />
          Students will be prompted to complete their details when they first sign in. Pre-filling student details is optional. 
          <br />
          </Fragment>}
        <br />
        <DataTable
          columns={this.columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
          paginationRowsPerPageOptions={[10, 25, 50, 100, 500]}
          sortServer
          onSort={this.handleSort}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onChangePage={this.handleChangePage}
          noHeader
        />
      </Fragment>
    )
  }

  componentDidMount() {
    this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder } = this.state

    let { students, total } = await (
      await request(`/api/teacher/exams/${this.props.exam.id}/students?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}`)
    ).json()

    await this.setState(prevState => ({ ...prevState, data: students, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage: number) => {
    await this.setState(prevState => ({ ...prevState, rowsPerPage }))
    this.reload()
  }

  handleChangePage = async (page: number) => {
    await this.setState(prevState => ({ ...prevState, page }))
    this.reload()
  }

  handleSort = async (column: any, sortDirection: any) => {
    await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
    this.reload()
  }

  handleCreateStudent = async () => {
    this.setState(prevState => ({ ...prevState, showAddStudentsDialog: true }))

  }

  onAddStudents = async (n: number) => {
    await request(`/api/teacher/exams/${this.props.exam.id}/students`, {
      method: 'POST',
      body: JSON.stringify({ n: n }),
      headers: { "content-type": "application/json" }
    })
    this.props.onStudentsChanged()
    await this.reload()
    this.setState(prevState => ({ ...prevState, showAddStudentsDialog: false }))
  }

  onCloseAddStudents = async () => {
    this.setState(prevState => ({ ...prevState, showAddStudentsDialog: false }))
  }

  downloadResults = async () => {
    this.setState(prevState => ({ ...prevState, downloadDisabled: true }))
    let r = await request(`/api/teacher/exams/${this.props.exam.id}/results`)

    let results = await r.text()

    var blob = new Blob([results], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'results.csv')
    this.setState(prevState => ({ ...prevState, downloadDisabled: false }))
  }

  downloadDetails = async () => {
    this.setState(prevState => ({ ...prevState, downloadDisabled: true }))
    let r = await request(`/api/teacher/exams/${this.props.exam.id}/studentsDetails`)
    let results = await r.text()
    var blob = new Blob([results], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'studentDetails.csv')
    this.setState(prevState => ({ ...prevState, downloadDisabled: false }))
  }

  downloadCertificates = async () => {
    this.setState(prevState => ({ ...prevState, downloadDisabled: true }))
    let r = await request(`/api/teacher/exams/${this.props.exam.id}/certificates`)
    const blob = await r.blob();
    FileSaver.saveAs(blob, `${this.props.exam.title}_Certificates.pdf`);
    this.setState(prevState => ({ ...prevState, downloadDisabled: false }))
  }

  downloadCertificate = async (studentId:string) => {
    this.setState(prevState => ({ ...prevState, downloadDisabled: true }))
    let r = await request(`/api/teacher/exams/${this.props.exam.id}/certificate/${studentId}`)
    const blob = await r.blob();
    FileSaver.saveAs(blob, `${this.props.exam.title}_SingleCertificate.pdf`);
    this.setState(prevState => ({ ...prevState, downloadDisabled: false }))

  }

}

function examEnded(endDate: string) {
  let examEndDate = new Date(endDate)
  return new Date() > examEndDate
}

function medalToColour(s: string) {
  if (s == "Gold") return "#ffd700"
  if (s == "Silver") return "#bec2cb"
  if (s == "Bronze") return "#cd7f32"
  return "black"
}