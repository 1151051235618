import { FormControl, InputLabel, MenuItem, Select } from "@material-ui/core"
import React from "react"
import { Question } from "../../../../../interfaces/shared/Question"
import { QuestionPart, QuestionPartDragAndDrop } from "../../../../../shared/QuestionSpec"

interface Props {
    part: QuestionPartDragAndDrop
    onUpdate: (oldPart: QuestionPart, newPart: QuestionPart) => void
}



export default class DragAndDropEditor extends React.Component<Props> {

  constructor(props:Props) {
    super(props)
  }

  render() {

      return <FormControl>
      </FormControl>
  }

}


