

import { Exam } from '../../../interfaces/teacher/Exam'
import React from 'react';
import TeacherContainer from '../TeacherContainer';
import TeacherExamsList from './TeacherPaymentsList';
import { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import TeacherPaymentsList from './TeacherPaymentsList';

interface Props {
}

interface State {
}

export default class TeacherPaymentsPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
    }
  }

  render() {

    if(!isLoggedIn('teacher')) {
        return <Redirect to='/login' />
    }


    return (
          <TeacherContainer heading="Payments">
            <TeacherPaymentsList/>
          </TeacherContainer>
    )
  }

}

