
import React from "react";
import { Redirect } from "react-router-dom";
import { isLoggedIn } from "../../../api";
import ExaminerContainer from "../ExaminerContainer";
import QuestionEditor from "./questionEditor/QuestionEditor";

interface Props {
  id: string
}

interface State {
}

export default class ExaminerEditQuestionPage extends React.Component<Props, State> {

  constructor(props:Props) {

      super(props)

      this.state = {
      }
  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }

      return (
          <ExaminerContainer heading={"Edit Question"}>
          <QuestionEditor id={this.props.id}/>
          </ExaminerContainer>
      )
  }
}






