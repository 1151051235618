
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table, TextField } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Teacher } from '../../../interfaces/examiner/Teacher';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import FileSaver from 'file-saver';
import { Exam } from '../../../interfaces/examiner/Exam';

interface Props {
    exams:Exam[]
    onClickExam:(examId:string)=>void
}

interface State {
  data:Teacher[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc',
  searchQuery:string
}

export default class ExaminerTeacherExams extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:1,
      page:1,
      sortColumn:'openDate',
      sortOrder:'desc',
      searchQuery:''
    }
  }

   columns: any[] = [
        // {
        //     name: 'Timestamp',
        //     selector: 'timestamp',
        //     sortable: true,
        //     cell: (teacher: Teacher) => new Date(teacher.timestamp).toLocaleDateString()
        // },
        {
            name: 'Exam',
            selector: 'title',
            sortable: false
        },
        {
            name: 'Signed Up',
            cell: (exam: Exam) => exam.teachers.length>0 ? "✔" : "✘"
        },
        {
            name: 'Places Bought',
            cell: (exam: Exam) => exam.teachers[0] ? exam.teachers[0].numPlacesAvailable : "na"
        },
        {
            name: 'Places Used',
            cell: (exam: Exam) => exam.teachers[0] ? exam.teachers[0].numPlacesUsed : "na"
        }
    ]

  render() {

    let { data, totalRows, rowsPerPage, page, searchQuery } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            <DataTable
                
                columns={this.columns}
                data={this.props.exams}
                noHeader
                highlightOnHover={true}
                pointerOnHover={true}
                onRowClicked={this.onRowClicked}
            />
      </Fragment>
    )
  }

  componentDidMount() {

  }

  async componentDidUpdate(prevProps: { exams: Exam[]; }) {
    if(prevProps.exams!=this.props.exams)
     {
       console.log("change")
     }
   } 

  onRowClicked = async (row:any) =>{
    this.props.onClickExam(row.id)
}


 

}
