
import { Checkbox, FormControlLabel, Grid } from "@material-ui/core"
import React from "react"
import { AnswerPart, AnswerPartMultipleTrueFalse } from "../../shared/AnswerSpec"
import { QuestionPartMultipleTrueFalse } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartMultipleTrueFalse
    answer:AnswerPartMultipleTrueFalse|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function MultipleTrueFalse(props:Props) {

    let { part, answer } = props

    return <div>
         <Content content={part.actionStatement} />
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <table>
         {
             part.questions.map((question) => {
                 let answerForThisQuestion = answer?.answers.filter(a => a.questionId === question.id)[0]
                 let selected: boolean|null = answerForThisQuestion !== undefined ? answerForThisQuestion.answer : null
                 return <tr>
                    <td>
                                <Content content={question.content} />
                    </td>
                    <td>
                         <FormControlLabel
                             control={
                                 <Checkbox checked={selected === true} onClick={() => handleClickOption(question.id, true)} />}
                             label="True"
                         />
                         <FormControlLabel
                             control={
                                 <Checkbox checked={selected === false} onClick={() => handleClickOption(question.id, false)} />}
                             label="False"
                         />
                    </td>
                </tr>
             })
         }
         </table>
    </div>

    function handleClickOption(questionId:string, trueOrFalse:boolean) {

        let answers:any[] = answer ? answer.answers : []

        let found = false

        for(let existingAnswer of answers) {
            if(existingAnswer.questionId === questionId) {
                found = true
                existingAnswer.answer = trueOrFalse
                break
            }
        }

        if(!found) {
            answers.push({ questionId, answer: trueOrFalse })
        }

        let newAnswer:AnswerPartMultipleTrueFalse = {
            questionPartId: props.part.id,
            answers
        }

        props.onUpdate(newAnswer)

    }
}

