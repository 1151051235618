import React, { Fragment } from 'react';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Receipt, QuestionAnswer, People, Dashboard, Timer, Person, RadioButtonChecked, RadioButtonUnchecked, Flag } from '@material-ui/icons';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import ResponsiveDrawer from '../../../ResponsiveDrawer';
import { Box, CssBaseline } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom'
import { AnswerSpec } from '../../../shared/AnswerSpec';
import { getConfigSync, isLoggedIn } from '../../../api';
import StudentTimer from './StudentTimer';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      background: '#f7f7ff',
    }
  })
);

interface Props {
  started:string
  children:any
  questions: {
    id: string,
    title:string
    answer: AnswerSpec | null,
    score: number
  }[]
  currentQuestionId: string
  handleQuestionChange:(id:string)=>void
  studentName: string
}

export default function StudentSittingContainer(props: Props) {
  const classes = useStyles();

    if(!isLoggedIn('student')) {
        return <Redirect to='/login' />
    }


  const drawer = (
    <div>
      {/* <div className={classes.toolbar} /> */}
      <img className='centreImage' src={getConfigSync().logo} width={150}/>

      <Divider />
      <List>
        {
          props.questions.map((question, index)=> {
            let primary = question.title==='' ? `Question ${index + 1}` : question.title
            return (
              <ListItem
                button
                selected={question.id === props.currentQuestionId}
                onClick={() => props.handleQuestionChange(question.id)}
              > 
                <ListItemIcon> 
                  {question.answer ? (question.answer!.flagged ? <Flag style={{ color: `#ff9292` }}/>  :  question.answer.answered ? <RadioButtonChecked /> : <RadioButtonUnchecked />) : <RadioButtonUnchecked />}
                </ListItemIcon>
                <ListItemText primary={primary} secondary = {question.score > 1 ? `${question.score} marks` : `${question.score} mark`}/>
              </ListItem>
            ); 
          })
        }
      </List>
      {/* <Divider />
      <List>
      </List> */}
    </div>
  );
  return <div className={classes.root}>
    <CssBaseline/>
    <ResponsiveDrawer heading={[props.studentName," - Time spent on paper: ", <StudentTimer started={props.started} />]}>
      {drawer}
    </ResponsiveDrawer>

    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Box>
      {props.children}
      </Box>
    </main>
    </div>

}

