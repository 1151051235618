
import React, { useEffect, useState } from "react"
import { getConfigSync } from "../../api"
import { Content as ContentSpec } from "../../shared/QuestionSpec"
import ReactMarkdown from 'react-markdown'
import gfm from 'remark-gfm'
import studentContentTable from "./helpers/studentContentTable"
import studentContentTd from "./helpers/studentContentTd"
interface Props {
    content:ContentSpec
}

export default function Content(props:Props) {

    let { content } = props
    let [ imageLoaded, setImageLoaded ] = useState<boolean>(false);
    useEffect(() => setImageLoaded(false), [ content.image ])
    return <div>
        { content.text &&
            <div style={{whiteSpace: 'pre-line', fontSize: '1.1rem', fontFamily: 'Lucida Sans', padding: `0 5px` }}>
                <ReactMarkdown
                    plugins={[gfm]}
                        renderers={{
                        // https://github.com/remarkjs/react-markdown/blob/f3187c12794e5942997fe87fe85dd774190d02f1/src/renderers.js
                        table: studentContentTable,
                        tableCell: studentContentTd,
                        emphasis: ({ node, ...props }) => { 
                            if (props.children[0].props.children && typeof props.children[0].props.children === 'string' && props.children[0].props.children.startsWith('^')) { 
                                  return <sup style={{marginLeft: '-0.3em'}}>{props.children[0].props.children.substring(1)}</sup> 
                             } 
                            if (props.children[0].props.children && typeof props.children[0].props.children === 'string' && props.children[0].props.children.startsWith('~')) { 
                                  return <sub style={{marginLeft: '-0.3em'}}>{props.children[0].props.children.substring(1)}</sub> 
                             } 
                           return <em {...props} /> 
                          },
                        }}>
                    {content.text}
                </ReactMarkdown>
                </div> }
        { content.image &&
            <img onLoad={() => setImageLoaded(true)} style={ (imageLoaded ? {opacity: 1} : {opacity: 0, width:'1px',height:'1px'})}  id={content.image} className='centreImage' src={getConfigSync().apiUrl + "/api/images/" + content.image} width={content.imageSize||300} title={content.imageAlt||''}/> }
         { content.image &&
            !imageLoaded && <div className={"loading"}>Image Loading</div> }
   </div>
}

