
import React from "react"
import { QuestionPartVideo } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartVideo
}

export default function Video(props:Props) {

    let { part } = props

    return <div>
        <Content content={part.content} />
        <div className="video-responsive">
            <iframe
            className = "centreImage"
            width="853"
            height="480"
            src={`https://www.youtube.com/embed/${part.youtubeLink}`}
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="Embedded youtube"
            />
        </div>
    </div>
}

