
import React, { Fragment } from "react";
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, Fade } from '@material-ui/core'
import { getConfigSync } from "../../api";

interface Props {
    onSubmit:(password1:string,password2:string)=>void
    message:string|null
    name:string
}

interface State {
    password1:string
    password2:string
}

export default class TeacherDetailsDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            password1:'',
            password2:'',
        }
    }

    render() {

        let { password1, password2 } = this.state

        let passwordsSame = (password1 == password2)
        let canPressAdd = password1 && password2 && passwordsSame && (password1.length>=12)

        return (
            <div>
            <Dialog open={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Teacher Password</DialogTitle>
                <DialogContent>
                    {/* ugly help? */}
                    {
                        this.props.message &&
                        <Fragment>
                            <div style={{color: 'red'}}>{this.props.message}</div>
                            <br/>
                        </Fragment>
                    }
                    <p>
                        {`Welcome to ${getConfigSync().competitionShortName} ${this.props.name}.`} 
                        <br />
                        Please change your temporary password.
                    </p>
                    <TextField
                        required
                        type="password"
                        label="Password"
                        value = {password1}
                        onChange = {this.onChangePassword1}
                        helperText="Passwords must be >=12 characters"
                    />
                     <br/>
                    <TextField
                        required
                        type="password"
                        label="Retype password"
                        value = {password2}
                        onChange = {this.onChangePassword2}
                    />
                    {
                        <Fade in={!passwordsSame && (password2 != '')}>
                                <div style={{color: 'red'}}>
                                    Passwords are not the same.
                                </div>
                        </Fade>
                    }
                </DialogContent>
                <DialogActions>
                <Button disabled={!canPressAdd} onClick={this.onSubmit} color="secondary">
                    Save and complete school sign-up
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
    onChangePassword1 = (e: any) => {
        this.setState((prevState) => ({ ...prevState, password1: e.target.value }))
    }

    onChangePassword2 = (e: any) => {
        this.setState((prevState) => ({ ...prevState, password2: e.target.value }))
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.password1,this.state.password2)
    }

}

