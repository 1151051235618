

import { Button, debounce, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Paper as MaterialPaper, Link, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { Exam, ExamPaper } from '../../../interfaces/examiner/Exam'
import React, { Fragment } from 'react';
import ExaminerContainer from '../ExaminerContainer';

import '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import SavingIndicator from '../../../components/SavingIndicator';
import { Cancel, PictureAsPdf } from '@material-ui/icons';
import AddPaperToExamDialog from './AddPaperToExamDialog';
import { exec } from 'child_process';
import { Student } from '../../../interfaces/examiner/Student';
import DataTable from 'react-data-table-component';
import ExaminerStudentsList from './ExaminerStudentsList';
import request, { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import { Paper } from '../../../interfaces/shared/Paper';
import FileSaver from 'file-saver';

interface Props {
    id: string
}

interface State {
    exam: Exam | null,
    saving: boolean,
    showAddPaperDialog: boolean,
    remarking: boolean
    teacherFilter: string | undefined
    uploadMessage: string
}

export default class ExaminerExamPage extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            exam: null,
            saving: false,
            showAddPaperDialog: false,
            remarking: false,
            teacherFilter: undefined,
            uploadMessage: '',
        }
    }


    render() {

        let { exam, saving, showAddPaperDialog, teacherFilter, uploadMessage } = this.state

        if (!isLoggedIn('examiner')) {
            return <Redirect to='/login' />
        }


        if (!exam) {
            return <ExaminerContainer heading={"Loading..."}>Loading...</ExaminerContainer>
        }

        return (
            <ExaminerContainer heading={exam.title}>
                {
                    showAddPaperDialog &&
                    <AddPaperToExamDialog exam={exam} onAdd={this.onAddPaper} onClose={this.onCloseAddPaperDialog} />
                }
                <SavingIndicator saving={saving} />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Exam Title"
                    fullWidth
                    defaultValue={exam.title}
                    onChange={this.handleTitleChange}
                />
                <br />
                <br />
                <InputLabel id="type">Exam Type</InputLabel>
                <Select
                    labelId="type"
                    name="type"
                    value={exam.type}
                    onChange={this.handleChange}
                    disabled={exam.isLive}
                >
                    <MenuItem value={"ONLINE"}>Online</MenuItem>
                    <MenuItem value={"PDF"}>PDF</MenuItem>
                </Select>
                <br />
                <br />
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Opening Date"
                        value={exam.openDate}
                        onChange={this.handleOpenDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    &nbsp;
                    &nbsp;
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Opening Time"
                        format="hh:mm"
                        value={exam.openDate}
                        onChange={this.handleOpenDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                    <br />
                    <KeyboardDatePicker
                        disableToolbar
                        variant="inline"
                        format="dd/MM/yyyy"
                        margin="normal"
                        id="date-picker-inline"
                        label="Closing Date"
                        value={exam.closeDate}
                        onChange={this.handleCloseDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                    />
                    &nbsp;
                    &nbsp;
                    <KeyboardTimePicker
                        margin="normal"
                        id="time-picker"
                        label="Closing Time"
                        format="hh:mm"
                        value={exam.closeDate}
                        onChange={this.handleCloseDateChange}
                        KeyboardButtonProps={{
                            'aria-label': 'change time',
                        }}
                    />
                </MuiPickersUtilsProvider>
                <br />
                <br />
                <InputLabel id="pricingmodelLabel">Pricing Model</InputLabel>
                <Select
                    labelId="pricingmodelLabel"
                    name="pricingModel"
                    value={exam.pricingModel}
                    onChange={this.handleChange}
                    disabled={exam.isLive}
                >
                    <MenuItem value={"PER_STUDENT"}>Per Student (same for all)</MenuItem>
                    <MenuItem value={"FLAT_FEE"}>Flat Fee (same for all)</MenuItem>
                    <MenuItem value={"FREE"}>Free</MenuItem>
                    <MenuItem value={"FLAT_FEE_INT"}>UK Free/Int Flat Fee</MenuItem>
                    <MenuItem value={"LOCKED"}>Locked: chosen schools only</MenuItem>
                </Select>
                <br />
                <br />
                <InputLabel id="allowInvoicesLabel">Allow Invoices</InputLabel>
                <Select
                    labelId="allowInvoicesLabel"
                    name="allowInvoicesByLocation"
                    value={exam.allowInvoicesByLocation}
                    onChange={this.handleChange}
                    disabled={exam.isLive}
                >
                    <MenuItem value={"ALL"}>All Schools</MenuItem>
                    <MenuItem value={"NONE"}>No Schools</MenuItem>
                    <MenuItem value={"UK_ONLY"}>Only UK Schools</MenuItem>
                </Select>
                <br />
                <br />
                <InputLabel id="whereCanViewLabel">Restrict Teachers Who Can View</InputLabel>
                <Select
                    labelId="whereCanViewLabel"
                    name="whereCanView"
                    value={exam.whereCanView}
                    onChange={this.handleChange}
                    disabled={exam.isLive}
                >
                    <MenuItem value={"ALL"}>All Schools</MenuItem>
                    <MenuItem value={"OVERSEAS_ONLY"}>Only Overseas Schools</MenuItem>
                    <MenuItem value={"UK_ONLY"}>Only UK Schools</MenuItem>
                </Select>
                <br />
                <br />
                <FormControlLabel
                    control={
                        <Checkbox checked={exam.freePlacesUkOnly} disabled={exam.isLive} onClick={() => this.handletoggleFreePlacesUkOnly()} />}
                    label="Free places UK only?"
                />
                <br />
                <br />
                <FormControlLabel
                    control={
                        <Checkbox checked={exam.freePlacesNonFeePayingOnly} disabled={exam.isLive} onClick={() => this.handletoggleFreePlacesNonFeePayingOnly()} />}
                    label="Free places Non-fee-paying only?"
                />
                <br />
                <br />
                {
                    exam.pricingModel === "PER_STUDENT" &&
                    <Fragment>
                        <TextField
                            margin="dense"
                            name="numFreePlaces"
                            label="Number of Free Places"
                            fullWidth
                            type="number"
                            defaultValue={exam.numFreePlaces}
                            onChange={this.handleChange}
                            disabled={exam.isLive}
                        />
                        <TextField
                            margin="dense"
                            name="perStudentPrice"
                            label="Price Per Student"
                            fullWidth
                            type="number"
                            defaultValue={exam.perStudentPrice}
                            onChange={this.handleChange}
                            disabled={exam.isLive}
                        />
                        <TextField
                            margin="dense"
                            name="perStudentMaxPrice"
                            label="Max Price"
                            fullWidth
                            type="number"
                            defaultValue={exam.perStudentMaxPrice}
                            onChange={this.handleChange}
                            disabled={exam.isLive}
                            helperText="Set to 0 for no maximum"
                        />
                    </Fragment>
                }
                {
                    (exam.pricingModel === "FLAT_FEE" || exam.pricingModel === "FLAT_FEE_INT") &&
                    <Fragment>
                        {exam.pricingModel === "FLAT_FEE_INT" && "Unlimited free places for UK schools & Flat Fee for international schools."}
                        <TextField
                            margin="dense"
                            name="numFreePlaces"
                            label="Number of Free Places"
                            fullWidth
                            type="number"
                            defaultValue={exam.numFreePlaces}
                            onChange={this.handleChange}
                            disabled={exam.isLive}
                        />
                        <TextField
                            margin="dense"
                            name="flatFeePrice"
                            label="Price"
                            fullWidth
                            type="number"
                            defaultValue={exam.flatFeePrice}
                            onChange={this.handleChange}
                            disabled={exam.isLive}
                        />
                    </Fragment>
                }
                <br />
                <br />
                {exam.type == "ONLINE" && <TextField
                    margin="dense"
                    id="studentRubric"
                    label="Rubric for Students"
                    multiline
                    rows={8}
                    fullWidth
                    variant="filled"
                    defaultValue={exam.studentRubric}
                    onChange={this.handleStudentRubricChange}
                />}
                <TextField
                    margin="dense"
                    id="teacherRubric"
                    label="Rubric for Teachers"
                    multiline
                    rows={8}
                    fullWidth
                    variant="filled"
                    defaultValue={exam.teacherRubric}
                    onChange={this.handleTeacherRubricChange}
                />
                <br />
                <br />
                {exam.type === "PDF" && <Fragment>
                    {exam.pdf ? <Fragment><Button color="primary" variant="outlined" onClick={() => this.deletePdf(1)}>Delete PDF1</Button><Button onClick={() => this.downloadPdf(1)}><PictureAsPdf /></Button></Fragment> : <Button color="primary" variant="outlined" onClick={() => this.addPdf(1)}>+ Add PDF1</Button>}
                    {exam.pdf2 ? <Fragment><Button color="primary" variant="outlined" onClick={() => this.deletePdf(2)}>Delete PDF2</Button><Button onClick={() => this.downloadPdf(2)}><PictureAsPdf /></Button></Fragment> : <Button color="primary" variant="outlined" onClick={() => this.addPdf(2)}>+ Add PDF2</Button>}
                </Fragment>}
                <br />
                <br />
                {!exam.isArchived && !exam.isLive && <Button color="primary" variant="outlined" onClick={this.handletoggleLive}>
                    Allow teachers to view
                </Button>}
                {!exam.isArchived && exam.isLive && <Button color="primary" variant="outlined" onClick={this.handletoggleLive}>
                    Prevent teachers from viewing
                </Button>}
                <br />
                <br />
                {exam.pricingModel === "LOCKED" && <Button color="primary" variant="outlined" onClick={this.handleImportCSV2}>
                    Upload school codes for access
                </Button>}
                <br />
                <br />
                {exam.teacherHack!.length} teachers signed up.
                <br />
                <br />
                <Button color="primary" variant="outlined" onClick={this.downloadSignedUp}>
                    Download signed up teacher details
                </Button>
                <Button color="primary" variant="outlined" onClick={this.downloadNotSignedUp}>
                    Download teacher list with consent NOT signed up
                </Button>
                <br />
                <br />
                {exam.type === "ONLINE" && !exam.isArchived && <Fragment>{(
                    this.state.remarking ?
                        <Button color="primary" variant="outlined" disabled>Re-marking...</Button>
                        :
                        <Button color="primary" variant="outlined" onClick={this.remark}>Re-mark all students</Button>)}
                    <br />
                    <br />
                </Fragment>}
                {exam.type === "ONLINE" && <Fragment>
                    <Button color="primary" variant="outlined" onClick={this.handleImportCSV}>Upload Medals</Button>
                    {exam.certificate ? <Fragment><Button color="primary" variant="outlined" onClick={this.deleteCertificate}>Delete Certificate</Button><Button onClick={() => this.downloadCertificate()}><PictureAsPdf /></Button></Fragment> : <Button color="primary" variant="outlined" onClick={this.addCertificate}>+ Add Certificate</Button>}
                    {uploadMessage ? <p> {uploadMessage} </p> : <br/>}
                    <br />
                    <FormControlLabel
                        control={
                            <Checkbox checked={exam.marksReleased} onClick={() => this.handletoggleReleased()} />}
                        label="Release marks?"
                    />
                    <br />
                    <br />
                </Fragment>}
                <FormControlLabel
                    control={
                        <Checkbox checked={exam.isArchived} onClick={() => this.handletoggleArchived()} />}
                    label="Archive exam?"
                />
                {exam.type === "ONLINE" && <Fragment><h2>Papers</h2>

                    <TableContainer component={MaterialPaper}>
                        <Table aria-label="simple table">
                            <TableBody>
                                {exam.papers.map((paper: ExamPaper, i: number) => (
                                    <TableRow>
                                        {!exam!.isArchived &&
                                            <TableCell component="th" scope="option">
                                                {i > 0 && <Button onClick={() => this.movePaper(i, i - 1)}>▲</Button>}
                                                {i < exam!.papers.length - 1 && <Button onClick={() => this.movePaper(i, i + 1)}>▼</Button>}
                                                <Button onClick={() => this.removePaper(paper, i)}><Cancel /></Button>
                                            </TableCell>}
                                        <TableCell align="left">
                                            <Link href={'/examiner/papers/' + paper.id}>{paper.title}</Link>
                                        </TableCell>
                                    </TableRow>
                                ))}
                                <TableRow>
                                    <TableCell colSpan={3}>
                                        {!exam!.isArchived && <Button color="primary" variant="outlined" onClick={this.addPaper}>
                                            + Add Paper
                                        </Button>}
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <h2>Students</h2>
                    Filter by school:
                    <Select margin="dense"
                        id="teacher" label="Teacher"
                        value={teacherFilter}
                        displayEmpty
                        onChange={(e) => this.handleTeacherFilterChange(e)}>
                        <MenuItem value={undefined}>
                            All
                        </MenuItem>
                        {exam.teacherHack!.map((t, i) => {
                            return (
                                <MenuItem
                                    value={t.id}>
                                    {t.schoolCode + " - " + t.school}
                                </MenuItem>
                            );
                        })}
                    </Select>
                    <br />
                    <br />
                    <ExaminerStudentsList filter={teacherFilter} exam={exam} />
                </Fragment>}
            </ExaminerContainer>
        )
    }

    componentDidMount() {

        this.reloadExam()

    }

    private async reloadExam() {

        let exam = await (await request('/api/examiner/exams/' + this.props.id)).json()

        this.setState(prevState => ({ ...prevState, exam }))
    }

    handleOpenDateChange = async (date: any) => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, openDate: date.toString() }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handleCloseDateChange = async (date: any) => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, closeDate: date.toString() }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handleTitleChange = async (ev: any) => {

        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, title: ev.target.value }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handleTeacherFilterChange = async (ev: any) => {

        await this.setState(prevState => ({ ...prevState, teacherFilter: ev.target.value }))
    }

    handleChange = async (ev: any) => {

        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, [ev.target.name]: ev.target.value }
        console.log(ev)
        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handleTeacherRubricChange = async (ev: any) => {

        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, teacherRubric: ev.target.value }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handleStudentRubricChange = async (ev: any) => {

        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, studentRubric: ev.target.value }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    addCertificate = () => {

        let input = document.createElement('input')
        input.type = 'file'

        input.onchange = async (e: any) => {

            let exam = this.state.exam as Exam


            var file = e.target.files[0]

            let uploadRes = await request(`/api/examiner/exams/${this.props.id}/uploadCertificate`, {
                method: 'POST',
                body: file
            })

            let body = await uploadRes.text()

            // body should be the sha1 hash of the image

            if (body.length !== 40) {
                console.log('upload failed: ' + body)
                return
            }

            let newExam: Exam = { ...exam, certificate: body }
            await this.setState(prevState => ({ ...prevState, exam: newExam }))
            this.save()
        }

        input.click()


        // let newContent = { ...content, image: '' }
        // onUpdateContent(subject, newContent)
    }

    deleteCertificate = async () => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, certificate: null }
        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    downloadCertificate = async () => {
        let r = await request(`/api/examiner/exams/${this.props.id}/downloadCertificate`)
        const blob = await r.blob();
        FileSaver.saveAs(blob, `${this.state.exam!.title}_Certificate.pdf`);
    }

    addPdf = (num: number) => {

        let input = document.createElement('input')
        input.type = 'file'

        input.onchange = async (e: any) => {

            let exam = this.state.exam as Exam


            var file = e.target.files[0]

            let uploadRes = await request(`/api/examiner/exams/${this.props.id}/uploadPdf`, {
                method: 'POST',
                body: file
            })

            let body = await uploadRes.text()

            // body should be the sha1 hash of the image

            if (body.length !== 40) {
                console.log('upload failed: ' + body)
                return
            }

            let newExam: Exam = { ...exam, ['pdf' + (num == 2 ? '2' : '')]: body }
            await this.setState(prevState => ({ ...prevState, exam: newExam }))
            this.save()
        }

        input.click()


        // let newContent = { ...content, image: '' }
        // onUpdateContent(subject, newContent)
    }

    deletePdf = async (num: number) => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, ['pdf' + (num == 2 ? '2' : '')]: null }
        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    downloadPdf = async (num: number) => {
        let r = await request(`/api/examiner/exams/${this.props.id}/downloadPdf/${num}`)
        const blob = await r.blob();
        FileSaver.saveAs(blob, `${this.state.exam!.title}_PDF${num}.pdf`);
    }


    async saveWithDelay() {
        await this.setState(prevState => ({ ...prevState, saving: true }))
        this.saveDebounced()
    }

    saveDebounced = debounce(async () => this.save(), 1000)

    async save() {

        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam }
        delete newExam.teacherHack

        await request('/api/examiner/exams/' + this.props.id, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(newExam)
        })
        await this.setState(prevState => ({ ...prevState, saving: false }))
    }

    async movePaper(n: number, n2: number) {

        let exam = this.state.exam as Exam

        let newExam: Exam = { ...exam }

        newExam.papers.splice(n2, 0, newExam.papers.splice(n, 1)[0])

        for (let n = 0; n < newExam.papers.length; ++n) {
            newExam.papers[n].order = n
        }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    async removePaper(paper: Paper, n: number) {
        let exam = this.state.exam as Exam

        let newExam: Exam = { ...exam }

        newExam.papers.splice(n, 1)
        //is this needed?
        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
        await request(`/api/examiner/exams/${this.props.id}/papers/${paper.id}`, {
            method: 'DELETE',
        })
    }

    addPaper = async () => {
        await this.setState(prevState => ({ ...prevState, showAddPaperDialog: true }))
    }

    onAddPaper = async (paper: Paper) => {

        await request('/api/examiner/exams/' + this.props.id + '/papers', {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({ id: paper.id })
        })

        await this.reloadExam()

        await this.setState(prevState => ({ ...prevState, showAddPaperDialog: false }))
    }

    onCloseAddPaperDialog = async () => {
        await this.setState(prevState => ({ ...prevState, showAddPaperDialog: false }))
    }

    handletoggleLive = async () => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, isLive: !exam.isLive }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handletoggleReleased = async () => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, marksReleased: !exam.marksReleased }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handletoggleFreePlacesUkOnly = async () => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, freePlacesUkOnly: !exam.freePlacesUkOnly }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handletoggleFreePlacesNonFeePayingOnly = async () => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, freePlacesNonFeePayingOnly: !exam.freePlacesNonFeePayingOnly }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }

    handletoggleArchived = async () => {
        let exam = this.state.exam as Exam
        let newExam: Exam = { ...exam, isArchived: !exam.isArchived }

        await this.setState(prevState => ({ ...prevState, exam: newExam }))
        this.save()
    }



    studentsColumns: any[] = [
        {
            name: 'Username',
            selector: 'username',
            sortable: true
        }
    ]



    remark = async () => {
        await this.setState(prevState => ({ ...prevState, remarking: true }))
        await request('/api/examiner/exams/' + this.props.id + '/remark', {
            method: 'POST'
        })
        await this.setState(prevState => ({ ...prevState, remarking: false }))
    }

    remarkAsdan = async () => {
        await this.setState(prevState => ({ ...prevState, remarking: true }))
        await request('/api/examiner/exams/' + this.props.id + '/remarkAsdan', {
            method: 'POST'
        })
        await this.setState(prevState => ({ ...prevState, remarking: false }))
    }


    downloadSignedUp = async () => {
        // await this.setState(prevState => ({ ...prevState, downloading: true}))
        let r = await request(`/api/examiner/teachers/downloadTeachers?examId=${this.props.id}&selection=ENROLLED`)

        let results = await r.text()

        var blob = new Blob([results], { type: 'text/csv' })
        FileSaver.saveAs(blob, 'signedUpTeachers.csv')
        // await this.setState(prevState => ({ ...prevState, downloading: false}))
    }

    downloadNotSignedUp = async () => {
        // await this.setState(prevState => ({ ...prevState, downloading: true}))
        let r = await request(`/api/examiner/teachers/downloadTeachers?examId=${this.props.id}&selection=CONTACT`)

        let results = await r.text()

        var blob = new Blob([results], { type: 'text/csv' })
        FileSaver.saveAs(blob, 'notSignedUpTeachers.csv')
        // await this.setState(prevState => ({ ...prevState, downloading: false}))
    }

    handleImportCSV = async () => {

        await this.setState(prevState => ({ ...prevState, uploadMessage:'' }))
        let input = document.createElement('input')
        input.type = 'file'

        input.onchange = async (e: any) => {
            var file = e.target.files[0]

            let uploadRes = await request('/api/examiner/exams/' + this.props.id + '/awardMedals', {
                method: 'POST',
                body: file,
                headers: {
                    'content-type': 'text/plain'
                }
            })
            let message = await uploadRes.text()
            await this.setState(prevState => ({ ...prevState, uploadMessage: message }))
            this.reloadExam()

        }

        input.click()



    }

    handleImportCSV2 = async () => {

        await this.setState(prevState => ({ ...prevState, uploadMessage:'' }))
        let input = document.createElement('input')
        input.type = 'file'

        input.onchange = async (e: any) => {
            var file = e.target.files[0]

            let uploadRes = await request('/api/examiner/exams/' + this.props.id + '/giveExamPlaces', {
                method: 'POST',
                body: file,
                headers: {
                    'content-type': 'text/plain'
                }
            })
            let message = await uploadRes.text()
            await this.setState(prevState => ({ ...prevState, uploadMessage: message }))
            this.reloadExam()

        }

        input.click()



    }
}
