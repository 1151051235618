
import { Button, Checkbox, Container, FormControlLabel, FormGroup, Grid, TextField } from "@material-ui/core"
import React, { Fragment } from "react"
import { Link, Redirect } from "react-router-dom"
import request, { apiLogin, apiResetPassword, getConfigSync, isLoggedIn } from "../../api"

interface Props {
}

interface State {
    username:string
    error:string
    picture:string
    cookies:boolean
}

export default class ForgotTeacherPassword extends React.Component<Props, State> {

    constructor(props:Props) {
        super(props)

        this.state = {
            username: '',
            error: '',
            picture:  randomFromArray(getConfigSync().loginPictures),
            cookies: false
        }

    }

    render() {

        let canLogin = this.state.cookies && this.state.username !== ''

        return <Container maxWidth="sm">
            <Fragment>
                <form noValidate onSubmit={this.onClickPassword}>
                    <Grid container direction="column" alignItems="center">
                        <h1>{getConfigSync().competitionShortName} 2022 Forgotten Password</h1>
                        <br />
                        <img src={this.state.picture} style={{width: '50%', maxHeight: '200px'}} />
                        <br />
                        <TextField id="username" label="Username" variant="outlined" fullWidth onChange={this.onChangeUsername} />
                        <br />
                        <FormControlLabel
                        control={
                        <Checkbox
                                    onClick={this.toggleCookies}
                            checked={this.state.cookies}
                            color="primary"
                        />
                        }
                        label="Please send me an e-mail with a new temporary password"
                    />
                        {
                            this.state.error &&
                                <Fragment>
                                    <div style={{color: 'red'}}>{this.state.error}</div>
                                    <br/>
                                </Fragment>
                        }
                        <Button type="submit" variant="outlined" size="large" disabled={!canLogin}>Reset Password</Button>
                    </Grid>
                </form>
                <Grid container justify="center"  spacing={2} style={{ marginTop: 10 }}>
                    <Grid item sm={6}>
                        <Button color="primary" variant="outlined" component={Link} to={'/login'}>Return to login</Button>
                    </Grid>
                    <Grid item sm={6}>
                        <Button color="primary" variant="outlined"  onClick={() => window.location.href=getConfigSync().competitionSignup}>Sign-up for teacher access</Button>
                    </Grid>
                </Grid>
                <Grid container direction="column" alignItems="center"  style={{ marginTop: 15 }}>
                    <Button onClick={() => window.location.href=getConfigSync().competitionHomepage}>{getConfigSync().competitionShortName} Homepage</Button>
                </Grid>
            </Fragment>

        </Container>
    }

    onChangeUsername = (e:any) => {
        this.setState((prevState) => ({ ...prevState, username: e.target.value }))
    }

    toggleCookies = () => {
        this.setState((prevState) => ({ ...prevState, cookies: !prevState.cookies }))
    }

    onClickPassword = async (e:any) => {

        e.preventDefault()

        let { username } = this.state

        let res = await apiResetPassword(username) 
        
        this.setState((prevState) => ({ ...prevState, error: res.error }))

        if(res.error="Password reset successfully"){
            this.setState((prevState) => ({ ...prevState, canLogin: false, username:'' }))
        }
    }
}

function randomFromArray(arr:any[]) { return arr[Math.floor(Math.random() * arr.length)] }
