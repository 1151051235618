
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem } from '@material-ui/core'
import { ExamHasTeacher } from "../../../interfaces/examiner/Exam";

interface Props {
    onAddStudents:(n:number)=>void,
    onClose:()=>void,
    examHasTeacher:ExamHasTeacher
}

interface State {
    n:number
    canPressAdd: boolean
}

export default class IncreaseStudentsDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            n: 5,
            canPressAdd: true
        }
    }

    render() {
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add students</DialogTitle>
                <DialogContent>
                    <p>Please select the number of students to add to the teacher for free. </p>
                    <p>They currently have used {this.props.examHasTeacher.numPlacesUsed} of {this.props.examHasTeacher.numPlacesAvailable}.</p>
                   <TextField
                        label="Students"
                        type="number"
                        value = {this.state.n}
                        onChange = {this.onChangeNumber}
                        InputProps={{ inputProps: { min: 0} }}
                    />
                </DialogContent>
                <DialogActions>
                <Button disabled={(!this.state.canPressAdd)} onClick={this.onAddStudents} color="secondary">
                    Add Students
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onChangeNumber = (event: any) => {

        let newNumber = parseInt(event.target.value)

        this.setState({ n: newNumber})
    }

    onClose = () => {
        this.props.onClose()
    }

    onAddStudents = () => {
        this.setState(prevState => ({ ...prevState, canPressAdd: false }))
        this.props.onAddStudents(this.state.n)
    }
    
}