import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { Fragment } from "react"
import { Content, QuestionPart, QuestionPartPlotFunction, QuestionPartOneWordAnswer, QuestionPartVideo } from "../../../../../shared/QuestionSpec"
import ContentEditor from './ContentEditor'
import uuid from "../../../../../uuid"
import { AnyCnameRecord } from "dns"

interface Props {
    part: QuestionPartPlotFunction
    onUpdate: (oldPart: QuestionPart, newPart: QuestionPart) => void
}


export default class PlotFunctionEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let part = this.props.part

        return <Fragment>
            <div>
                <h3>Function Type</h3>
                <InputLabel id="schooltype-label">Function Type</InputLabel>
                <Select
                    required
                    label="Function Type"
                    value={part.functionType}
                    onChange={(e) => this.handleFieldChange('functionType', e)}
                >
                    <MenuItem
                        key={"LINEAR"}
                        value={"LINEAR"}>
                        {"LINEAR"}
                    </MenuItem>
                    <MenuItem
                        key={"LOGISTIC"}
                        value={"LOGISTIC"}>
                        {"LOGISTIC"}
                    </MenuItem>
                    <MenuItem
                        key={"MM"}
                        value={"MM"}>
                        {"Michaelis-Menten"}
                    </MenuItem>
                </Select>
            </div>
            <div>
                <h3>Content</h3>
                <ContentEditor subject={part} content={part.content} onUpdateContent={this.onUpdateContent} />
            </div>
            <div>
                <h3>Y Name</h3>
                <ContentEditor subject={part} content={part.y} onUpdateContent={this.onUpdateContentY} />
            </div>
            <div>
                <h3>Settings</h3>
                <TextField type="number" onChange={(e) => this.onUpdateNoise(e)} value={part.noise} placeholder="Noise" label='Noise' />
                <br /><br />
                <TextField type="number" onChange={(e) => this.handleConstantFieldChange('constanty', e)} value={part.constanty} placeholder="constant" label='Y Constant' />
                <br /><br />
                <TextField type="number" onChange={(e) => this.handleConstantFieldChange('constantx', e)} value={part.constantx} placeholder="constant" label='X Constant' />
            </div>
            <div>
                <h3>Plot?</h3>
                <FormControlLabel
                    control={
                        <Checkbox checked={part.plot} onClick={() => this.handleTogglePlot()} />}
                    label="Plot graph as student generates data?"
                />
            </div>
            {/* Linear Function */}
            {part.functionType === 'LINEAR' && <div>
                <h3>Variables</h3>
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Order</TableCell>
                                <TableCell align="left">Name</TableCell>
                                <TableCell align="left">Default Value</TableCell>
                                <TableCell align="left">Coefficient</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {part.linearVariables.map((variable, i) => (
                                <TableRow>
                                    <TableCell component="th" scope="question">
                                        {i > 0 && <Button onClick={() => this.moveRow(i, i - 1)}>▲</Button>}
                                        <br />
                                        {i < part.linearVariables.length - 1 && <Button onClick={() => this.moveRow(i, i + 1)}>▼</Button>}
                                        <br />
                                        <Button onClick={() => this.deleteRow(i)}><Delete /></Button>
                                    </TableCell>
                                    <TableCell component="th" scope="question">
                                        <ContentEditor subject={variable} content={variable.name} onUpdateContent={this.onUpdateVariableName} />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField type="number" onChange={(e) => this.onUpdateDefaultValue(variable, e)} value={variable.defaultValue} placeholder="defaultValue" />
                                    </TableCell>
                                    <TableCell align="left">
                                        <TextField type="number" onChange={(e) => this.onUpdateCoefficient(variable, e)} value={variable.coefficient} placeholder="coefficient" />
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Button color="primary" variant="outlined" onClick={this.addOption}>
                                        + Add Option
                                    </Button>
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
            {/* Logistic Function */}
            {(part.functionType === 'LOGISTIC' || part.functionType === 'MM') && <div>
                <h3>Variables</h3>
                <TextField type="number" onChange={(e) => this.onUpdateLogistic('L', e)} value={part.logisticVariables.L} placeholder="L" label="L - the curve's maximum value " />
                <br /><br />
                <TextField type="number" onChange={(e) => this.onUpdateLogistic('k', e)} value={part.logisticVariables.k} placeholder="k" label="k - logistic growth rate or steepness of the curve" />
                <br /><br />
                {part.functionType === 'LOGISTIC' && <Fragment><TextField type="number" onChange={(e) => this.onUpdateLogistic('x0', e)} value={part.logisticVariables.x0} placeholder="x0" label="x0 - the x value of the sigmoid midpoint" />
                <br /><br /> </Fragment>} 
                <TableContainer component={Paper}>
                    <Table aria-label="simple table">
                        <TableHead>
                            <TableRow>
                                <TableCell align="left">Independent Variable</TableCell>
                                <TableCell align="left">Starting Value</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="question">
                                    <ContentEditor subject={part.logisticVariables} content={part.logisticVariables.name} onUpdateContent={this.onUpdateLogVariableName} />
                                </TableCell>
                                <TableCell align="left">
                                    <TextField type="number" onChange={(e) => this.onUpdateLogDefaultValue(e)} value={part.logisticVariables.defaultValue} placeholder="defaultValue" />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </div>}
        </Fragment>
    }

    onUpdateContent = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            content: newContent
        }

        this.props.onUpdate(part, newPart)
    }

    onUpdateVariableName = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            linearVariables: part.linearVariables.map(variable => {
                if (variable === subject) {
                    return {
                        ...variable,
                        name: newContent
                    }
                } else {
                    return variable
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }

    onUpdateLogVariableName = (subject: any, newContent: Content) => {
        let part = this.props.part
        let newLogVar = { ...part.logisticVariables, name: newContent }
        let newPart = {
            ...part,
            logisticVariables: newLogVar
        }
        this.props.onUpdate(part, newPart)
    }

    onUpdateLogDefaultValue = (e: any) => {
        let part = this.props.part
        let newLogVar = { ...part.logisticVariables, defaultValue: parseInt(e.target.value) }
        let newPart = {
            ...part,
            logisticVariables: newLogVar
        }
        this.props.onUpdate(part, newPart)
    }

    handleConstantFieldChange = async (field: string, e: any) => {
        let part = this.props.part

        let newPart = {
            ...part,
            [field]: parseInt(e.target.value)
        }

        this.props.onUpdate(part, newPart)
    }

    handleFieldChange = async (field: string, e: any) => {
        let part = this.props.part

        let newPart = {
            ...part,
            [field]: e.target.value
        }

        this.props.onUpdate(part, newPart)
    }

    onUpdateLogistic = async (field: string, e: any) => {
        let part = this.props.part
        let newLogVar = { ...part.logisticVariables, [field]: parseInt(e.target.value) }
        let newPart = {
            ...part,
            logisticVariables: newLogVar
        }
        this.props.onUpdate(part, newPart)
    }


    onUpdateDefaultValue = (subject: any, e: any) => {

        let part = this.props.part

        let newPart = {
            ...part,
            linearVariables: part.linearVariables.map(variable => {
                if (variable === subject) {
                    return {
                        ...variable,
                        defaultValue: parseInt(e.target.value)
                    }
                } else {
                    return variable
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }

    onUpdateCoefficient = (subject: any, e: any) => {

        let part = this.props.part

        let newPart = {
            ...part,
            linearVariables: part.linearVariables.map(variable => {
                if (variable === subject) {
                    return {
                        ...variable,
                        coefficient: parseInt(e.target.value)
                    }
                } else {
                    return variable
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }


    addOption = () => {

        let part = this.props.part

        let newPart = {
            ...part,
            linearVariables: part.linearVariables.concat([
                {
                    id: uuid(),
                    name: {
                        text: null,
                        image: null
                    },
                    defaultValue: 1,
                    coefficient: 1

                }
            ])
        }

        this.props.onUpdate(part, newPart)
    }

    moveRow(n: number, n2: number) {

        let part = this.props.part

        let newPart = { ...part }

        newPart.linearVariables.splice(n2, 0, newPart.linearVariables.splice(n, 1)[0])

        this.props.onUpdate(part, newPart)
    }

    deleteRow(n: number) {

        let part = this.props.part

        let newPart = { ...part }

        part.linearVariables.splice(n, 1)

        this.props.onUpdate(part, newPart)
    }

    handleTogglePlot = () => {
        let part = this.props.part

        let newPart = { ...part }
        newPart.plot = !part.plot

        this.props.onUpdate(part, newPart)
    }

    onUpdateNoise = (e: any) => {
        let part = this.props.part

        let newPart = { ...part }
        newPart.noise = parseInt(e.target.value)

        this.props.onUpdate(part, newPart)
    }

    onUpdateContentY = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            y: newContent
        }

        this.props.onUpdate(part, newPart)
    }



}