import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'fast-text-encoding/text';
import 'core-js'
import React, { useEffect, useState } from 'react';
import { Route, BrowserRouter } from 'react-router-dom'


import Home from './pages/'
import ExaminerDashboard from './pages/examiner/ExaminerDashboard'
import ExaminerExamPage from './pages/examiner/exams/ExaminerExamPage'
import ExaminerExamsPage from './pages/examiner/exams/ExaminerExamsPage'

import ExaminerEditQuestionPage from './pages/examiner/questions/ExaminerEditQuestionPage'
import ExaminerQuestionsPage from './pages/examiner/questions/ExaminerQuestionsPage'

import ExaminerPapersPage from './pages/examiner/papers/ExaminerPapersPage'
import ExaminerEditPaperPage from './pages/examiner/papers/ExaminerEditPaperPage'
import StudentHome from './pages/student/StudentHome';

import TeacherDashboard from './pages/teacher/TeacherDashboard'
import StudentSittingPage from './pages/student/exams/StudentSittingPage'
import ExaminerTeachersPage from './pages/examiner/teachers/ExaminerTeachersPage';
import ExaminerTeacherPage from './pages/examiner/teachers/ExaminerTeacherPage';
import TeacherStudentPage from './pages/teacher/exams/TeacherStudentPage';
import ExaminerStudentPage from './pages/examiner/exams/ExaminerStudentPage';

import TeacherExamsPage from './pages/teacher/exams/TeacherExamsPage';
import TeacherExamPage from './pages/teacher/exams/TeacherExamPage';
import TeacherTeacherPage from './pages/teacher/TeacherTeacherPage';

import Login from './pages/login/Login';
import ForgotTeacherPassword from './pages/login/ForgotTeacherPassword';
import TeacherSittingsPage from './pages/teacher/sittings/TeacherInvigilationPage';
import apiRequest, { getConfig } from './api';
import ExaminerPaperScoresPage from './pages/examiner/papers/ExaminerPaperScoresPage';
import TeacherPaymentsPage from './pages/teacher/payments/TeacherPaymentsPage';
import ExaminerPaymentsPage from './pages/examiner/payments/ExaminerPaymentsPage';
import ExaminerExaminersPage from './pages/examiner/examiners/ExaminerExaminersPage';
import ExaminerQuestionScoresPage from './pages/examiner/questions/ExaminerQuestionScoresPage';

function App() {

  let [configLoaded,setConfigLoaded] = useState<boolean>()

  useEffect(() => {
	getConfig().then(() => setConfigLoaded(true))
  }, [])

  if(!configLoaded) {
	return <div className={"loading"}>Loading</div> 
  }

  return (
    <div className="App">
      <BrowserRouter>
      <Route exact path='/' component={Home}></Route>

      <Route exact path='/login' component={Login}></Route>
      <Route exact path='/resetTeacherPassword' component={ForgotTeacherPassword}></Route>

      <Route exact path='/examiner' component={ExaminerDashboard}></Route>

      <Route exact path='/examiner/exams' component={ExaminerExamsPage}/>
      <Route exact path='/examiner/exams/:id' component={(props:any) => <ExaminerExamPage id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/questions' component={ExaminerQuestionsPage}/>
      <Route exact path='/examiner/questions/:id' component={(props:any) => <ExaminerEditQuestionPage id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/questions/:id/scores' component={(props:any) => <ExaminerQuestionScoresPage id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/papers' component={ExaminerPapersPage}/>
      <Route exact path='/examiner/papers/:id' component={(props:any) => <ExaminerEditPaperPage id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/papers/:id/scores' component={(props:any) => <ExaminerPaperScoresPage id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/teachers' component={ExaminerTeachersPage}/>
      <Route exact path='/examiner/teachers/:id' component={(props:any) => <ExaminerTeacherPage id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/exams/:examId/students/:id' component={(props:any) => <ExaminerStudentPage examId={props.match.params.examId} id={props.match.params.id}/>}></Route>
      <Route exact path='/examiner/payments' component={ExaminerPaymentsPage}></Route>
      <Route exact path='/examiner/examiners' component={ExaminerExaminersPage}></Route>


      <Route exact path='/student' component={StudentHome}></Route>
      <Route exact path='/student/sittings/:id' component={(props:any) => <StudentSittingPage id={props.match.params.id}/>}></Route>


      <Route exact path='/teacher' component={TeacherDashboard}></Route>
      <Route exact path='/teacher/updateDetails' component={TeacherTeacherPage}></Route>
      <Route exact path='/teacher/exams/:examId/students/:id' component={(props:any) => <TeacherStudentPage examId={props.match.params.examId} id={props.match.params.id}/>}></Route>
      <Route exact path='/teacher/exams' component={TeacherExamsPage}></Route>
      <Route exact path='/teacher/exams/:id' component={(props:any) => <TeacherExamPage id={props.match.params.id}/>}></Route>
      <Route exact path='/teacher/sittings' component={TeacherSittingsPage}></Route>
      <Route exact path='/teacher/payments' component={TeacherPaymentsPage}></Route>

      </BrowserRouter>
    </div>
  );
}

export default App


