
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Exam } from '../../../interfaces/examiner/Exam';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import ExamDeleteDialog from './ExamDeleteDialog';

interface Props {
  isSuperuser:boolean
}

interface State {
  data:Exam[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc'
  deleteExamDialog: Exam | null
}

export default class ExaminerExamList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'openDate',
      sortOrder:'desc',
      deleteExamDialog:null
    }
  }

   columns: any[] = [
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            cell: (exam: Exam) => new Date(exam.created).toLocaleDateString()
        },
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
            wrap:true,
        },
        {
          name: 'Start date',
          selector: 'openDate',
          sortable: true,
          cell: (exam: Exam) => new Date(exam.openDate).toLocaleDateString()
      },
      {
        name: 'Close date',
        selector: 'closeDate',
        sortable: true,
        cell: (exam: Exam) => new Date(exam.closeDate).toLocaleDateString()
    },
        {
          name: 'Teacher can view',
          selector: 'isLive',
          sortable: false,
          cell: (exam: Exam) => exam.isLive ? "✔" : "✘"
      },
      {
        name: 'Marks released',
        selector: 'marksReleased',
        sortable: false,
        cell: (exam: Exam) => exam.marksReleased ? "✔" : "✘"
    },
    {
      name: 'Archived',
      selector: 'isArchived',
      sortable: false,
      cell: (exam: Exam) => exam.isArchived ? "✔" : "✘"
  },
        {
            cell: (exam: Exam) => <Fragment>
                <Button component={Link} to={"/examiner/exams/" + exam.id}><Edit /></Button>
               {this.props.isSuperuser && <Button onClick={() => this.openDeleteExamDialog(exam)}><Delete /></Button>}
            </Fragment>
        }
    ]

  render() {

    let { data, totalRows, rowsPerPage, page } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
          {this.state.deleteExamDialog && <ExamDeleteDialog exam={this.state.deleteExamDialog} onClose={this.closeDeleteExamDialog} onDelete={this.handleDeleteExam} />}
            <Button variant="outlined" color="primary" onClick={this.handleCreateExam}>
                Create Exam
            </Button>
            <br/>
            <br/>
            <DataTable
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder } = this.state

    let { exams, total } = await (
        await request(`/api/examiner/exams?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: exams, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  handleCreateExam = async () => {
    await request('/api/examiner/exams', {
        method: 'POST',
        body: JSON.stringify({
            title: 'New Exam',
            openDate: new Date().getTime(),
            closeDate: new Date().getTime() + 1000*60*60*24*7
        }),
        headers: { 'content-type': 'application/json' }
    })
    this.reload()
  }

  openDeleteExamDialog = async (exam:Exam) => {
    await this.setState(prevState => ({ ...prevState, deleteExamDialog:exam }))
  }

  handleDeleteExam = async (exam:Exam) => {
    await request('/api/examiner/exams/' + exam.id, { method: 'DELETE' })
    this.reload()
    await this.setState(prevState => ({ ...prevState, deleteExamDialog:null }))
  }

  closeDeleteExamDialog = async () => {
    await this.setState(prevState => ({ ...prevState, deleteExamDialog:null }))
  }


}
