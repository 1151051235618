
import React, { Fragment } from "react";
import request from '../../../api';
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, InputLabel } from '@material-ui/core'
import { Exam } from "../../../interfaces/teacher/Exam";
import { loadStripe } from "@stripe/stripe-js";
import { Payment } from "../../../interfaces/teacher/Payment,";

interface Props {
    onClose:()=>void
    onAccept:(payment:Payment)=>void
    payment:Payment
}

interface State {
}

export default class ConfirmPaymentDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Confirm Payment</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wish to CONFIRM the payment?</p>             
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onAccept} color="secondary">
                            Yes
                        </Button>
                        <Button onClick={this.onClose} color="secondary">
                            No
                        </Button>
                    </DialogActions>                    
            </Dialog>
            </div>
        );
    }

    onAccept = () => {
        this.props.onAccept(this.props.payment)
    }

    onClose = () => {
        this.props.onClose()
    }

}