
import { TextField } from "@material-ui/core"
import React from "react"
import { AnswerPart, AnswerPartOneWordAnswer } from "../../../../shared/AnswerSpec"
import { QuestionPartOneWordAnswer } from "../../../../shared/QuestionSpec"
import Content from "../../../../components/questions/Content"
import { Score } from "../../../../interfaces/examiner/Score"
import { TagCloud } from 'react-tagcloud'

interface Props {
    part: QuestionPartOneWordAnswer
    answers: AnswerPartOneWordAnswer[] | null
    scores: Score[]
}

export default function OneWordAnswerScore(props: Props) {

    let { part, answers, scores } = props
    if (answers && answers.length>0){
        answers = answers.filter(a => a)    

        if (part._correctAnswers[0].numericalAnswer){
            answers.forEach(function(a) {
                a.answer = parseFloat(a.answer.replace(/[^0-9\.]/g, '')).toString()
            });
        }

        var data = Array.from(new Set(answers.map(a=>a.answer))).map(a =>
            ({value:a, count: answers!.filter(f => f.answer === a).length}));
        data.sort((a, b) => a.count < b.count ? 1 : a.count > b.count ? -1 : 0)
        data = data.slice(0, 50)
        
        let totalScores = scores.filter((s: Score) => s.type === "PAPER")
        var totals = new Map()  
        for (let t of totalScores) { totals.set(t.examPaperSittingId, t) }

    } else{
        data = [{ value: 'Loading', count: 1 }]
    }
    console.log(data)
    const options = {
        luminosity: 'light',
        hue: 'blue',
    }
    return <div>
        <Content content={part.actionStatement} />
        <p>Avg score: {formatPercentage(avgScore(scores.filter((s: Score) => s.questionPartId === part.id)))}</p>
        <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
        <br />
        Answers: {part._correctAnswers.map(c=> c.text + ", ")}
        <br />
        <div style={{maxWidth:"50%"}}>
            <TagCloud
                minSize={12}
                maxSize={55}
                colorOptions={options}
                tags={data}
                onClick={(tag:{value:string, count:number}) => {
                    let correlationValue = tagCorr(tag.value, answers, totals)
                    return(alert(`'${tag.value}' has ${tag.count} answers. Paper correlation for answer: ${correlationValue}`))
                }}
            />
        </div>
    </div>

    function avgScore(score: any) {
        var total = 0;
        for (var i = 0; i < score.length; i++) {
            total = total + parseFloat(score[i].score);
        }
        var avg = total / i
        return avg;
    }

    function sumAnswers(answers: any, option: any) {
        var sum = 0;
        var all = 0;
        for (var i = 0; i < answers.length; i++) {
            if (answers[i] && answers[i].selectedButtonId) {
                sum = sum + (answers[i].selectedButtonId === option.id ? 1 : 0)
                all = all + 1
            }
        }
        return { sum, all }
    }

    function tagCorr(value:string, answers: any, scores: any) {
        var wrongRight = [];
        var score = [];
        for (var i = 0; i < answers.length; i++) {
            let id = answers[i].examPaperSittingId
            if (scores.get(id) && answers[i].answer) {
                wrongRight.push((answers[i].answer === value) ? 1 : 0)
                score.push(parseFloat(scores.get(id).score));
            }
        }
        console.log(wrongRight, score)
        var corr = pcorr(wrongRight, score)
        return corr.toFixed(2);
    }

    function pcorr(x: number[], y: number[]) {
        let sumX = 0,
            sumY = 0,
            sumXY = 0,
            sumX2 = 0,
            sumY2 = 0;
        const minLength = x.length = y.length = Math.min(x.length, y.length),
            reduce = (xi: number, idx: number) => {
                const yi = y[idx];
                sumX += xi;
                sumY += yi;
                sumXY += xi * yi;
                sumX2 += xi * xi;
                sumY2 += yi * yi;
            }
        x.forEach(reduce);
        return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
    };
    
    function corrScore(option: any, answers: any, scores: any) {
        var wrongRight = [];
        var score = [];
        for (var i = 0; i < answers.length; i++) {
            let id = answers[i].examPaperSittingId
            if (scores.get(id) && answers[i].selectedButtonId) {
                wrongRight.push((answers[i].selectedButtonId === option.id) ? 1 : 0)
                score.push(parseFloat(scores.get(id).score));
            }
        }
        console.log(wrongRight, score)
        var corr = pcorr(wrongRight, score)
        return corr.toFixed(2);
    }
}

function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    let s2 = Math.round(s * 100) / 100
    return s2 + '%'
}



