import { Button, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { Fragment } from "react"
import { Content, QuestionPart, QuestionPartOneWordAnswer, QuestionPartVideo } from "../../../../../shared/QuestionSpec"
import ContentEditor from './ContentEditor'

interface Props {
    part:QuestionPartVideo
    onUpdate:(oldPart: QuestionPart, newPart: QuestionPart)=>void
  }
  

export default class VideoEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let part = this.props.part

        return <Fragment>
            <div>
                <ContentEditor subject={part} content={part.content} onUpdateContent={this.onUpdateContent}/>
            </div>
            <br />
            <br />
            <div>
                <InputLabel id="label">Enter YouTube Embed ID</InputLabel>
                <TextField onChange={(e) => this.onUpdateYouTubeID(e)} value={part.youtubeLink} placeholder="Enter EmbedID..." />
            </div>
        </Fragment>
    }

    onUpdateContent = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            content: newContent
        }

        this.props.onUpdate(part, newPart)
    }

    onUpdateYouTubeID= (e: any) => {

        let part = this.props.part

        let newPart = {
            ...part,
        }

        newPart.youtubeLink=e.target.value

        this.props.onUpdate(part, newPart)
    }

}