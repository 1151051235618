
import { Button, CircularProgress, debounce, Link, Paper as MaterialPaper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { Fragment } from "react";
import ExaminerContainer from "../ExaminerContainer";
import { Paper } from '../../../interfaces/shared/Paper'
import SavingIndicator from "../../../components/SavingIndicator";
import { Question } from "../../../interfaces/shared/Question";
import { Cancel, Delete } from "@material-ui/icons";
import request, { isLoggedIn } from "../../../api";
import { Redirect } from "react-router-dom";
import 'chart.js';
import { VictoryChart, VictoryBoxPlot, VictoryHistogram, VictoryAxis, VictoryLabel } from "victory";
import { Score } from "../../../interfaces/examiner/Score";
const reactChartkick = require('react-chartkick');


interface Props {
    id: string
}

interface State {
    scores: Score[] | null,
    question: Question | null,
}

export default class ExaminerPaperScoresPage extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            scores: null,
            question: null,
        }

        this.loadPaper()
    }

    render() {

        let { scores, question } = this.state

        if (!isLoggedIn('examiner')) {
            return <Redirect to='/login' />
        }


        if (!scores || !question) {
            return <ExaminerContainer heading={"Loading..."}>Loading...</ExaminerContainer>
        }

        if (scores.length === 0) {
            return <ExaminerContainer heading={question.description}>No results yet...</ExaminerContainer>
        }

        let totalScores = scores!.filter((s: Score) => s.type === "PAPER")
        let totals = new Map()
        for (let t of totalScores) { totals.set(t.studentId, t) }
        let questionScoreArray = scores!.filter((s: Score) => s.type === "QUESTION")
        let histDataAll = arrayDataFromScore(questionScoreArray)
        return (
            <ExaminerContainer heading={question.description}>
                <h2>Intra-question level analysis</h2>
                <h3>Sittings: {(scores.filter((s: Score) => s.questionId && !s.questionPartId)).length}</h3>
                <h3>Mean score: {formatPercentage(avgScore((scores.filter((s: any) => s.questionId && !s.questionPartId))))}</h3>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "30%", margin: "auto" }}>
                        <VictoryChart domainPadding={30} padding={{ left: 80, top: 50, bottom: 50, right: 80 }} >
                            <VictoryHistogram
                                cornerRadius={3}
                                data={histDataAll}
                            />
                            <VictoryAxis label="Score (%)" />
                            <VictoryAxis
                                axisLabelComponent={<VictoryLabel dy={-24} />}
                                dependentAxis
                                label="Students" />
                        </VictoryChart>
                    </div>

                    <div style={{ width: "60%", margin: "auto" }}>
                        <VictoryChart domainPadding={10} height={250} width={750} >
                            <VictoryBoxPlot
                                boxWidth={10}
                                data={boxPlotData(scores, question)}
                            />
                            <VictoryAxis label="Part Number" />
                            <VictoryAxis
                                dependentAxis
                                label="Score (%)" />
                        </VictoryChart>
                    </div>
                </div>
                <TableContainer component={MaterialPaper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="option">
                                    Part Number
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Type
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Average
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Pearson Correlation
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Pie
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Histogram
                            </TableCell>
                            </TableRow>
                            {question.questionSpec.parts.map((part: any, i: number) => {
                                console.log(part)
                                let questionScoreArray = scores!.filter((s: Score) => s.questionPartId === part.id)
                                if (questionScoreArray.length > 0) {
                                    var avg = formatPercentage(avgScore(questionScoreArray))
                                    var correct = totalRight(questionScoreArray);
                                    var attempted = questionScoreArray.length;
                                    var corScore = corrScore(questionScoreArray, totals)
                                    var allData = arrayDataFromScore(questionScoreArray)
                                }
                                else {
                                    var avg = "na"
                                    var correct = 0
                                    var attempted = 0
                                    var corScore = "na"
                                    var allData = [{ x: 0 }]
                                }
                                return (
                                    <Fragment>
                                        <TableRow>
                                            <TableCell rowSpan={2} component="th" scope="option">
                                                {(i + 1).toString()}
                                            </TableCell>
                                            <TableCell colSpan={5}>{part.actionStatement.text ? part.actionStatement.text : "No Action Statement Text"}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell component="th" scope="option">
                                                {part.type}
                                            </TableCell>
                                            <TableCell component="th" scope="option">
                                                {avg}
                                            </TableCell>
                                            <TableCell component="th" scope="option">
                                                {corScore}
                                            </TableCell>
                                            <TableCell component="th" scope="option">
                                                <reactChartkick.PieChart data={[["Correct", correct], ["Incorrect", attempted - correct]]} height="150px" />
                                            </TableCell>
                                            <TableCell component="th" scope="option">
                                                <VictoryChart domainPadding={10}>
                                                    <VictoryHistogram
                                                        cornerRadius={3}
                                                        data={allData}
                                                    />
                                                </VictoryChart>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ExaminerContainer>
        )

    }


    private async loadPaper() {

        let { question, scores } = await (await request(`/api/examiner/questions/${this.props.id}/getScores`)).json()
        question.questionSpec.parts = question.questionSpec.parts.filter((p: any) => p.type !== "Stem")
        this.setState(prevState => ({ ...prevState, scores, question }))
    }


}

function avgScore(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        total = total + parseFloat(score[i].score);
    }
    var avg = total / i
    return avg;
}

function corrScore(score: any, totals: any) {
    var questionScore = [];
    var answerScore = [];
    for (var i = 0; i < score.length; i++) {
        questionScore.push(parseFloat(score[i].score));
        answerScore.push(parseFloat(totals.get(score[i].studentId).score))
    }
    var corr = pcorr(questionScore, answerScore)
    return corr.toFixed(2);
}

function arrayDataFromScore(score: any) {
    var questionScore = [];
    for (var i = 0; i < score.length; i++) {
        questionScore.push({ x: parseFloat(score[i].score) });
    }
    return questionScore;
}

function totalRight(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        if (score[i].score > 0) total = total + 1;
    }
    return total;
}

function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    s = s + ''
    if (s.length > 5) {
        s = s.substr(0, 5)
    }
    return s + '%'
}

function pcorr(x: number[], y: number[]) {
    let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        sumY2 = 0;
    const minLength = x.length = y.length = Math.min(x.length, y.length),
        reduce = (xi: number, idx: number) => {
            const yi = y[idx];
            sumX += xi;
            sumY += yi;
            sumXY += xi * yi;
            sumX2 += xi * xi;
            sumY2 += yi * yi;
        }
    x.forEach(reduce);
    return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
};

function boxPlotData(scores1: any, question: Question) {
    let data = []
    for (var i = 0; i < question.questionSpec.parts.length; i++) {
        let desc = question.questionSpec.parts[i].type
        let score = scores1.filter((s: Score) => s.questionPartId === question.questionSpec.parts[i].id)
        let scores = []
        for (var j = 0; j < score.length; j++) {
            scores.push(parseFloat(score[j].score));
        }
        if (scores.length > 0) {
            data.push(
                { x: (i + 1).toString(), y: scores }
            )
        }
    }
    console.log(data)
    return data
}