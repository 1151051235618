
import React from "react"
import { AnswerPart, AnswerPartListOrdering } from "../../shared/AnswerSpec"
import { QuestionPartListOrdering } from "../../shared/QuestionSpec"
import Content from './Content'
import DragDrop, { List } from "./helpers/DragDrop"

interface Props {
    part:QuestionPartListOrdering
    answer:AnswerPartListOrdering|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function ListOrdering(props:Props) {

    let { part, answer, onUpdate } = props

    if(answer) {
        part.options = answer.ordering.map(id => part.options.filter(o => o.id === id)[0])
    }

    let lists:List[] = [
        {
            entries: part.options.map(option => ({
                id: option.id,
                content: <Content content={option.content} />
            }))
        }
    ]

    return <div>
         <Content content={part.actionStatement} />
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <DragDrop lists={lists} onChange={handleChange} />
    </div>

    function handleChange(lists:any[]) {

        let list = lists[0]

        let newAnswer:AnswerPartListOrdering =
            answer ? answer : { questionPartId: part.id, ordering: [] }

        newAnswer.ordering = list.entries.map((e:any) => e.id)

        onUpdate(newAnswer)
    }
}

