
import React from "react"
import { AnswerPart, AnswerPartDragAndDrop } from "../../shared/AnswerSpec"
import { QuestionPartDragAndDrop } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartDragAndDrop
    answer:AnswerPartDragAndDrop|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function DragAndDrop(props:Props) {
    return <div></div>
}


