
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import { Edit, QuestionAnswer, Delete, Add, FindInPage, Cancel, FileCopy, Pageview } from '@material-ui/icons'
import { Exam } from '../../../interfaces/examiner/Exam'
import { Paper } from '../../../interfaces/shared/Paper'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Question } from '../../../interfaces/shared/Question';
import DataTable from 'react-data-table-component';
import AssignPaperDialog from './AssignPaperDialog';
import request from '../../../api';
import TestQuestionDialog from './questionEditor/TestQuestionDialog';
import QuestionDeleteDialog from './QuestionDeleteDialog';

interface Props {
}

interface State {
  data:Question[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc',
  assigningPaperTo:Question|null,
  searchQuery:string
  unassignedOnly: boolean
  showTestQuestionDialog: Question | null
  deleteQuestionDialog: Question | null
}

export default class ExaminerQuestionList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'created',
      sortOrder:'desc',
      assigningPaperTo: null,
      searchQuery:'',
      unassignedOnly: false,
      showTestQuestionDialog: null,
      deleteQuestionDialog: null,
    }
  }

   columns: any[] = [
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            cell: (question: Question) => new Date(question.created).toLocaleDateString()
        },
        {
            name: 'Paper',
            selector: 'paper',
            sortable: true,
            cell: (question: Question) => (question.paper ?
                <Fragment>
                    <Link to={"/examiner/papers/" + question.paper.id}>{question.paper.title}</Link>
                    {!question.isArchived && <Cancel style={{ cursor: 'pointer' }} onClick={() => this.unassignPaper(question)} />}
                </Fragment>
                :
                (<FindInPage color="disabled" style={{ cursor: 'pointer' }} onClick={() => this.assignPaper(question)} />)
            )
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true
        },
        {
            cell: (question: Question) => <Fragment>
                <Button onClick={() => this.handleTestQuestion(question)}><Pageview /></Button>
                <Button onClick={() => this.handleDuplicateQuestion(question)}><FileCopy /></Button>
                {!question.isArchived && <Button component={Link} to={"/examiner/questions/" + question.id}><Edit /></Button>}
                {!question.isArchived && <Button onClick={() => this.openDeleteQuestionDialog(question)}><Delete /></Button>}
            </Fragment>
        }
    ]

  render() {

    let { data, totalRows, rowsPerPage, page, assigningPaperTo, searchQuery, unassignedOnly} = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            {
                assigningPaperTo &&
                    <AssignPaperDialog question={assigningPaperTo} onAssign={this.onAssignPaper} onClose={this.onCloseAssignPaperDialog} />
            }
            {
                this.state.showTestQuestionDialog &&
                <TestQuestionDialog question={this.state.showTestQuestionDialog} onClose={this.handleCloseTestQuestionDialog} />
            }        
            {this.state.deleteQuestionDialog && <QuestionDeleteDialog question={this.state.deleteQuestionDialog} onClose={this.closeDeleteQuestionDialog} onDelete={this.handleDeleteQuestion} />}

            <Button variant="outlined" color="primary" onClick={this.handleCreateQuestion}>
                Create Question
            </Button>
            <br/>
            <br/>
            <TextField margin="dense"
                    id="searchQuery" label="Search..."
                    defaultValue="" 
                    onChange={(e) => this.handleFieldChange(e.target.value)} 
            />
            <FormControlLabel
                        control={
                        <Checkbox checked={unassignedOnly} onClick={() => this.toggleUnassignedOnly()} />
                        }
                        label="Unassigned only"
                    />
            <br/>
            <DataTable
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder, searchQuery, unassignedOnly } = this.state

    let { questions, total } = await (
        await request(`/api/examiner/questions?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&searchQuery=${searchQuery}&unAssigned=${unassignedOnly}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: questions, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  handleCreateQuestion = async () => {
    await request('/api/examiner/questions', { method: 'POST' })
    this.reload()
  }
 
  openDeleteQuestionDialog = async (question:Question) => {
    await this.setState(prevState => ({ ...prevState, deleteQuestionDialog:question }))
  }
  
  closeDeleteQuestionDialog = async () => {
    await this.setState(prevState => ({ ...prevState, deleteQuestionDialog:null }))
  }
  handleDeleteQuestion = async (question:Question) => {
    await request('/api/examiner/questions/' + question.id, { method: 'DELETE' })
    this.reload()
    await this.setState(prevState => ({ ...prevState, deleteQuestionDialog:null }))
  }

  handleDuplicateQuestion = async (question:Question) => {
    await request(`/api/examiner/questions/${question.id}/duplicate`, { method: 'POST' })
    this.reload()
  }

    onAssignPaper = async (question: Question, paper: Paper) => {
        let body = {
            paper: paper.id
        }
        await request(`/api/examiner/questions/${question.id}/paper`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        await this.setState(prevState => ({ ...prevState, assigningPaperTo: null }))
        this.reload()
    }

    onCloseAssignPaperDialog = async () => {
        await this.setState(prevState => ({ ...prevState, assigningPaperTo: null }))
    }

    assignPaper = async (question: Question) => {
        await this.setState(prevState => ({ ...prevState, assigningPaperTo: question }))
    }

    unassignPaper = async (question: Question) => {
        await request(`/api/examiner/questions/${question.id}/paper`, {
            method: 'DELETE'
        })
        this.reload()
    }

    handleFieldChange = async (searchQuery:string) => {
        await this.setState(prevState => ({ ...prevState, searchQuery }))
        this.reload()
    }

    toggleUnassignedOnly = async () => {
        let unassignedOnly = !this.state.unassignedOnly
        await this.setState(prevState => ({ ...prevState, unassignedOnly }))
        this.reload()
    }

    handleTestQuestion = (question:Question) => {
        this.setState(prevState => ({ ...prevState, showTestQuestionDialog: question}))
    }

    handleCloseTestQuestionDialog = () => {
        this.setState(prevState => ({ ...prevState, showTestQuestionDialog: null }))
    }
}
