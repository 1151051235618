
import React, { Fragment } from "react";
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, Fade, InputLabel } from '@material-ui/core'
import { getConfigSync } from "../../api"
interface Props {
    onSubmit:(name:string, yearGroup:string,password1:string,password2:string,gender:string)=>void
    message:string|null
    name:string
    yearGroup:string
    gender:string
}

interface State {
    password1:string
    password2:string
    name:string
    yearGroup:string
    gender:string
}

export default class StudentDetailsDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            name:this.props.name,
            yearGroup:this.props.yearGroup,
            gender:this.props.gender,
            password1:'',
            password2:'',
        }
    }

    render() {

        let {name, yearGroup, password1, password2, gender } = this.state
        let passwordsSame = (password1 == password2)
        let canPressAdd = name && yearGroup && password1 && password2 && passwordsSame

        if (getConfigSync().collectGender == true) {
            if (!gender) canPressAdd = false
        } 

        return (
            <div>
            <Dialog open={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Student Details</DialogTitle>
                <DialogContent>
                    {/* ugly help? */}
                    {
                        this.props.message &&
                        <Fragment>
                            <div style={{color: 'red'}}>{this.props.message}</div>
                            <br/>
                        </Fragment>
                    }
                    <p>Please enter your name, year group and set a new password to continue.</p>
                    
                    <InputLabel id="name-label">Full name as you wish to appear on certificate (seen by teacher)</InputLabel>
                    <TextField
                        required 
                        label=""
                        value = {name}
                        onChange = {this.onChangeName}
                    />
                    <br/>
                    <br />
                    <InputLabel id="yeargroup-label">Year Group</InputLabel>
                    <Select
                        required
                        label="Year Group"
                        value = {yearGroup}
                        onChange = {this.onChangeYearGroup}
                    >
                        <MenuItem
                            key={"Year Group A (ages 13-14)"}
                            value={"Year Group A (ages 13-14)"}>
                              {"Year Group A (ages 13-14)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group B (ages 14-15)"}
                            value={"Year Group B (ages 14-15)"}>
                              {"Year Group B (ages 14-15)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group C (ages 15-16)"}
                            value={"Year Group C (ages 15-16)"}>
                              {"Year Group C (ages 15-16)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group D (ages 16-17)"}
                            value={"Year Group D (ages 16-17)"}>
                              {"Year Group D (ages 16-17)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group E (ages 17-18)"}
                            value={"Year Group E (ages 17-18)"}>
                              {"Year Group E (ages 17-18)"}
                        </MenuItem>
                    </Select>
                    { (getConfigSync().collectGender == true) && <Fragment><br/>
                    <br />
                    <InputLabel id="gender-label">Gender</InputLabel>
                    <Select
                        required
                        label="Gender"
                        value = {gender}
                        onChange = {this.onChangeGender}
                    >
                        <MenuItem
                            key={"Male"}
                            value={"Male"}>
                              {"Male"}
                        </MenuItem>
                        <MenuItem
                            key={"Female"}
                            value={"Female"}>
                              {"Female"}
                        </MenuItem>
                        <MenuItem
                            key={"Other"}
                            value={"Other"}>
                              {"Other"}
                        </MenuItem>
                        <MenuItem
                            key={"Prefer not to say"}
                            value={"Prefer not to say"}>
                              {"Prefer not to say"}
                        </MenuItem>
                    </Select></Fragment>}
                     <br/>
                    <TextField
                        required
                        type="password"
                        label="Password"
                        value = {password1}
                        onChange = {this.onChangePassword1}
                    />
                     <br/>
                    <TextField
                        required
                        type="password"
                        label="Retype password"
                        value = {password2}
                        onChange = {this.onChangePassword2}
                    />
                    {
                        <Fade in={!passwordsSame && (password2 != '')}>
                                <div style={{color: 'red'}}>
                                    Passwords are not the same.
                                </div>
                        </Fade>
                    }
                </DialogContent>
                <DialogActions>
                <Button disabled={!canPressAdd} onClick={this.onSubmit} color="secondary">
                    Save
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onChangeName = (e: any) => {
        this.setState((prevState) => ({ ...prevState, name: e.target.value }))
    }

    onChangeYearGroup = (e: any) => {
        this.setState((prevState) => ({ ...prevState, yearGroup: e.target.value }))
    }

    onChangeGender = (e: any) => {
        this.setState((prevState) => ({ ...prevState, gender: e.target.value }))
    }

    onChangePassword1 = (e: any) => {
        this.setState((prevState) => ({ ...prevState, password1: e.target.value }))
    }

    onChangePassword2 = (e: any) => {
        this.setState((prevState) => ({ ...prevState, password2: e.target.value }))
    }

    onSubmit = () => {
        this.props.onSubmit(this.state.name, this.state.yearGroup,this.state.password1,this.state.password2, this.state.gender)
    }

}

