

import React, { Fragment } from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, MenuItem, InputLabel, Select, FormControl, Box, Paper, Grid, FormControlLabel, Checkbox, debounce } from '@material-ui/core'
import ExaminerContainer from '../../ExaminerContainer';
import Editor from "./editors/Editor";
import { newQuestionPart, QuestionPart, QuestionPartType } from "../../../../shared/QuestionSpec";
import AddPartDialog from "./AddPartDialog";
import request from "../../../../api";
import TestQuestionDialog from "./TestQuestionDialog";
import { Question } from "../../../../interfaces/shared/Question";
import { FileCopy } from "@material-ui/icons";
import uuid from "../../../../uuid"
import SavingIndicator from "../../../../components/SavingIndicator";
import { createNoSubstitutionTemplateLiteral } from "typescript";

interface Props {
    id: string
}

interface State {
    question:Question|null
    savesPending:number,
    showAddPartDialog:boolean
    showTestQuestionDialog:boolean
    saving:boolean
}

export default class QuestionEditor extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            question: null,
            savesPending: 0,
            showAddPartDialog: false,
            showTestQuestionDialog: false,
            saving:false
        }
    }

    render() {

        let { question } = this.state

        if (question === null) {
            return <div>Loading...</div>
        }

        return (
            <Fragment>
                <SavingIndicator saving={this.state.saving} />
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Description"
                    fullWidth
                    defaultValue={question.description}
                    onChange={this.handleQuestionDescriptionChange}
                />
                <FormControlLabel
                    control={
                        <Checkbox checked={question.showDescription} onClick={() => this.handletoggleShowDescription()} />}
                    label="Show description to student instead of question number?"
                />
                {
                    this.state.showTestQuestionDialog &&
                    <TestQuestionDialog question={question} onClose={this.handleCloseTestQuestionDialog} />
                }
                {
                    this.state.showAddPartDialog &&
                    <AddPartDialog question={question} onClose={this.handleCloseAddPartDialog} onSelect={this.handleAddPartDialogSelect} />
                }
                
                { question.questionSpec.parts.map((part, i) => <Fragment>
                <Box m={1}>
                <Paper>
                    <Box p={1.5}>
                        <Editor part={part} onUpdate={this.handleUpdatePart} />
                        <br/>
                        <Grid container justify="flex-end">                                
                                {i > 0 && <Button variant="outlined" onClick={() => this.onMovePartUp(part)}>▲</Button>}
                                {i < question!.questionSpec.parts.length - 1 && <Button variant="outlined" onClick={() => this.onMovePartDown(part)}>▼</Button>}
                                <Button variant="outlined" onClick={() => this.duplicatePart(part)}>Duplicate</Button>
                                <Button variant="outlined" color="secondary" onClick={() => this.onRemovePart(part)}>Remove</Button>
                        </Grid>
                    </Box>
                </Paper>
            </Box>
                </Fragment>)
            }
                            <br />
                <Button variant="outlined" color="primary" onClick={this.handleAddPart}>
                    + Add Part
            </Button>
                <Button variant="outlined" color="primary" onClick={this.handleTestQuestion}>
                    ! Test Question
            </Button>
            </Fragment>
        )
    }

    componentDidMount() {

        this.reloadQuestion()

    }

    private async reloadQuestion() {

        let question = await (await request('/api/examiner/questions/' + this.props.id)).json()

        this.setState(prevState => ({ ...prevState, question }))
    }

    handleQuestionDescriptionChange = async (ev: any) => {

        let question = this.state.question as Question

        question.description = ev.target.value

        this.saveQuestion(question)
    }

    handletoggleShowDescription = async () => {
        let question = this.state.question as Question

        question.showDescription = !question.showDescription

        this.saveQuestion(question)
      }

    handleUpdatePart = async (oldPart: QuestionPart, newPart: QuestionPart) => {

        let question = this.state.question

        if (question === null)
            return

        for (let i = 0; i < question.questionSpec.parts.length; ++i) {
            if (question.questionSpec.parts[i] === oldPart) {
                question.questionSpec.parts[i] = newPart
                break
            }
        }

        this.saveQuestion(question)
    }
    
    onRemovePart = async (part: QuestionPart) => {

        let question = this.state.question

        if (question === null)
            return

        for (let i = 0; i < question.questionSpec.parts.length; ++i) {
            if (question.questionSpec.parts[i] === part) {
                question.questionSpec.parts.splice(i, 1)
                break
            }
        }

        this.saveQuestion(question)
    }

    onMovePartUp = async (part: QuestionPart) => {

        let question = this.state.question

        if (question === null)
            return

        for (let i = 0; i < question.questionSpec.parts.length; ++i) {
            if (question.questionSpec.parts[i] === part) {
                question.questionSpec.parts[i] = question.questionSpec.parts[i-1]
                question.questionSpec.parts[i-1]=part
                break
            }
        }

        this.saveQuestion(question)
    }

    onMovePartDown = async (part: QuestionPart) => {

        let question = this.state.question

        if (question === null)
            return

        for (let i = 0; i < question.questionSpec.parts.length; ++i) {
            if (question.questionSpec.parts[i] === part) {
                question.questionSpec.parts[i] = question.questionSpec.parts[i+1]
                question.questionSpec.parts[i+1]=part
                break
            }
        }

        this.saveQuestion(question)
    }

    duplicatePart = (part:QuestionPart) => {


        let question = this.state.question

        if (question === null)
            return

        for (let i = 0; i < question.questionSpec.parts.length; ++i) {
            if (question.questionSpec.parts[i] === part) {
                question.questionSpec.parts.splice(i, 0, {
                    ...JSON.parse(JSON.stringify(part)),
                    id: uuid()
                })
                break
            }
        }

        this.saveQuestion(question)
    }


    handleAddPart = () => {
        this.setState(prevState => ({ ...prevState, showAddPartDialog: true }))
    }

    handleCloseAddPartDialog = () => {
        this.setState(prevState => ({ ...prevState, showAddPartDialog: false }))
    }

    handleTestQuestion = () => {
        this.setState(prevState => ({ ...prevState, showTestQuestionDialog: true }))
    }

    handleCloseTestQuestionDialog = () => {
        this.setState(prevState => ({ ...prevState, showTestQuestionDialog: false }))
    }
    
    handleAddPartDialogSelect = (type:QuestionPartType) => {

        let question = this.state.question

        if (question === null)
            return

        console.log('qpt is ' + type)

        question.questionSpec.parts.push(newQuestionPart(type))

        this.setState(prevState => ({ ...prevState, showAddPartDialog: false }))
        this.saveQuestion(question)
    }

    async saveQuestion(question: Question){
        await this.setState(prevState => ({ ...prevState, saving: true }))
        this.saveDebounced(question)
    }

    saveDebounced = debounce(async (question) => await this.save(question), 1000)


    async save(question: Question) {

        this.setState(prevState => ({
            ...prevState,
            question,
            savesPending: prevState.savesPending + 1
        }))

        let res = await request(
            '/api/examiner/questions/' + this.props.id,
            {
                method: 'PUT',
                body: JSON.stringify(question, null, 2),
                headers: {
                    'content-type': 'application/json'
                }
            }
        )

        let saveResult = await res.text()

        if (saveResult === 'ok') {
            this.setState(prevState => ({
                ...prevState,
                savesPending: prevState.savesPending - 1,
                saving:false
            }))
        }
    }
}






