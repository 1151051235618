
import React, { Fragment } from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem } from '@material-ui/core'
import { Question } from "../../../interfaces/shared/Question";
import { QuestionPartType } from '../../../shared/QuestionSpec'
import SittingQuestion from "../../../components/questions/SittingQuestion";
import { Score } from "../../../interfaces/examiner/Score";
import { Answer } from "../../../interfaces/examiner/Answer";
import SittingQuestionScore from "./scoreComponents/SittingQuestionScore";
import request from "../../../api";
import { VictoryChart, VictoryBoxPlot, VictoryHistogram, VictoryAxis, VictoryLabel, VictoryScatter } from "victory";
import { time } from "console";

interface Props {
    question: Question
    onClose: () => void
}

interface State {
    answers: Answer[] | null,
    scores: Score[] | null,
}

export default class ScoreQuestionDialog extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            answers: null,
            scores: null,
        }
    }

    render() {
        let question = this.props.question
        let { answers, scores } = this.state
        let timeArray = answers ? answers.filter(a=>a.answer.version>1).filter(a => a.answer.answered == true).map(a => a.answer.timeOnQuestion!) : [0,1]
        let avTime = timeArray.length > 2 ? timeArray.reduce((a, b) => (a + b)) / timeArray.length : 0
        let medTime = timeArray.length > 2 ? this.median(timeArray) : 0
        let sdTime = timeArray.length > 2 ? Math.sqrt(timeArray.map(x => Math.pow(x - avTime, 2)).reduce((a, b) => a + b) / timeArray.length) : 0
        let maxTime = avTime + 1.96 * sdTime
        let histDataAll = timeArray.length > 2 ? arrayDataFromAnswerTime(answers!.filter(a=>a.answer.version>1)) : [{ x: 0 }, { x: 1 }]
        let scatterDataAll = (timeArray.length > 2 && scores) ? arrayDataFromAnswerTimeScores(answers!.filter(a=>a.answer.version>1), scores) : [{ x: 1, y: 2 }, { x: 2, y: 3 }]
        return (
            <div>
                <Dialog fullScreen open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                    <DialogTitle id="form-dialog-title">{question.description}</DialogTitle>
                    <DialogContent>
                        {(answers && scores && question) ?
                            <Fragment>
                                <p>
                                    Viewed Question Count: {answers.length}, 100%
                                </p>
                                <p>
                                    Answer Count: {answers.filter(a => a.answer.answered == true).length}, {this.formatPercentage(answers.filter(a => a.answer.answered == true).length / answers.length * 100)}
                                    <br />
                                    Full Answer Count: {answers.filter(a => a.answer.parts.length == question.questionSpec.parts.filter(p=>p.type!="Stem").length).length}, {this.formatPercentage(answers.filter(a => a.answer.parts.length == question.questionSpec.parts.filter(p=>p.type!="Stem").length).length/answers.filter(a => a.answer.answered == true).length * 100)} 
                                </p>
                                <p>
                                    Ever Flagged Count: {answers.filter(a => a.answer.everFlagged == true).length}, {this.formatPercentage(answers.filter(a => a.answer.everFlagged == true).length / answers.length * 100)}
                                    <br />
                                    Still Flagged Count: {answers.filter(a => a.answer.flagged == true).length}, {this.formatPercentage(answers.filter(a => a.answer.flagged == true).length / answers.filter(a => a.answer.everFlagged == true).length * 100)}
                                </p>
                                <p>
                                    Mean Time (if answered): {Math.round(avTime)} seconds (SD time: {Math.round(sdTime)} seconds)
                                    <br />
                                    Median Time (if answered): {Math.round(medTime)} seconds
                                </p>
                                <div style={{ display: "flex" }}>
                                    <div style={{ width: "30%" }}>
                                        <VictoryChart domainPadding={30} padding={{ left: 80, top: 50, bottom: 50, right: 80 }} >
                                            <VictoryScatter
                                                data={scatterDataAll.filter(d => d.y! <= maxTime)}
                                            />
                                            <VictoryAxis label="Score (%)" />
                                            <VictoryAxis
                                                axisLabelComponent={<VictoryLabel dy={-24} />}
                                                dependentAxis
                                                label="Time (s)" />
                                        </VictoryChart>
                                    </div>
                                    <div style={{ width: "30%" }}>
                                        <VictoryChart domainPadding={30} padding={{ left: 80, top: 50, bottom: 50, right: 80 }} >
                                            <VictoryHistogram
                                                cornerRadius={3}
                                                data={histDataAll.filter(d => d.x <= maxTime)}
                                            />
                                            <VictoryAxis label="Time (s)" />
                                            <VictoryAxis
                                                axisLabelComponent={<VictoryLabel dy={-24} />}
                                                dependentAxis
                                                label="Students" />
                                        </VictoryChart>
                                    </div>
                                    <div style={{alignSelf: "flex-end" }}>
                                        <p>
                                            Note these graphs remove time 95% outliers
                                        </p>
                                    </div>
                                </div>
                                <SittingQuestionScore question={question} answers={answers} scores={scores} />
                            </Fragment>
                            :
                            <div className={"loading"}>Loading</div>

                        }
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onClose} color="secondary">
                            Close Question Analysis
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    componentDidMount() {
        this.loadQuestion()
    }

    private async loadQuestion() {
        let { scores, answers } = await (await request(`/api/examiner/questions/${this.props.question.id}/getScores`)).json()
        this.setState(prevState => ({ ...prevState, scores, answers }))
    }

    formatPercentage(s: any) {
        if (s === null || s === undefined || s === '') {
            return 'n/a'
        }
        let s2 = Math.round(s * 100) / 100
        return s2 + '%'
    }

    median(values:number[]){
        if(values.length ===0) return 0;
      
        values.sort(function(a,b){
          return a-b;
        });
      
        var half = Math.floor(values.length / 2);
        
        if (values.length % 2)
          return values[half];
        
        return (values[half - 1] + values[half]) / 2.0;
      }

}

function arrayDataFromAnswerTime(answers: Answer[]) {
    var answerTimes = [];
    for (var i = 0; i < answers.length; i++) {
        answerTimes.push({ x: answers[i].answer.timeOnQuestion! });
    }
    return answerTimes;
}

function arrayDataFromAnswerTimeScores(answers: Answer[], scores: any) {
    let data = []
    let scoresToUse = scores.filter((s: { type: string; }) => s.type == "QUESTION")
    for (var i = 0; i < scoresToUse.length; i++) {
        let studentId = scoresToUse[i].examPaperSittingId
        let answerTime = answers.filter(a => a.examPaperSittingId == studentId)[0].answer.timeOnQuestion
        let score = scoresToUse[i].score
        data.push(
            { x: parseFloat(score), y: answerTime }
        )
    }
    return data;
}

function getStandardDeviation(array: number[]) {
    const n = array.length
    const mean = array.reduce((a, b) => a + b) / n
    return Math.sqrt(array.map(x => Math.pow(x - mean, 2)).reduce((a, b) => a + b) / n)
}
