

import { Button, debounce, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Paper as MaterialPaper, Link } from '@material-ui/core';
import { Exam } from '../../../interfaces/teacher/Exam'
import React, { Fragment } from 'react';
import TeacherContainer from '../TeacherContainer';

import '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SavingIndicator from '../../../components/SavingIndicator';
import { Cancel, ExposureZeroTwoTone, PictureAsPdf } from '@material-ui/icons';
import { exec } from 'child_process';
import TeacherStudentsList from './TeacherStudentsList';
import request, { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm'
import FileSaver from 'file-saver';
interface Props {
  id: string
}

interface State {
  exam:Exam|null
  saving:boolean
  downloadDisabled:boolean
}

export default class TeacherExamPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      exam: null,
      saving: false,
      downloadDisabled:false
    }
  }

  render() {

    let { exam, saving } = this.state


    if(!isLoggedIn('teacher')) {
        return <Redirect to='/login' />
    }


    if(!exam) {
        return <TeacherContainer heading={"Loading..."}>Loading...</TeacherContainer>
    }

    return (
        <TeacherContainer heading={exam.title}>
            <SavingIndicator saving={saving} />
                <h1> {exam.title} </h1>
                <h2>Rubric</h2>
                <p style={{whiteSpace: 'pre-line'}}><ReactMarkdown plugins={[gfm]}>{exam.teacherRubric}</ReactMarkdown></p>
                {exam.type=="ONLINE" && <Fragment><h2>Students</h2>
                {exam.pricingModel != "FREE" && <p>{`You have used ${exam.numPlacesUsed} of ${exam.numPlacesAvailable} student places.`}</p>}
            <TeacherStudentsList exam = {exam} onStudentsChanged={() => this.reloadExam()}/>
            </Fragment>}

            {exam.type=="PDF" && <Fragment><h2>Download Exam PDF</h2>
            <p>The exam opens at {new Date(exam.openDate).toLocaleString()} and closes at  {new Date(exam.closeDate).toLocaleString()}.</p>
            {exam.pdf && <Button color="primary" variant="outlined" onClick={() => this.downloadPdf(1)} disabled={!exam.isOpen || this.state.downloadDisabled}>
                    Download PDF1 &nbsp; <PictureAsPdf />
            </Button>}
            {exam.pdf2 && <Button color="primary" variant="outlined" onClick={() => this.downloadPdf(2)} disabled={!exam.isOpen || this.state.downloadDisabled}>
                    Download PDF2 &nbsp; <PictureAsPdf />
            </Button>}
            </Fragment>}
        </TeacherContainer>
    )
  }

  componentDidMount() {

    this.reloadExam()

  }

  private async reloadExam() {

    let exam = await (await request('/api/teacher/exams/' + this.props.id)).json()

    this.setState(prevState => ({ ...prevState, exam }))
  }

  downloadPdf = async (num:number) => {
    await this.setState(prevState => ({ ...prevState, downloadDisabled: true }))
    let r = await request(`/api/teacher/exams/${this.props.id}/downloadPdf/${num}`)
    const blob = await r.blob();
    FileSaver.saveAs(blob, `${this.state.exam!.title}_PDF.pdf`);
    await this.setState(prevState => ({ ...prevState, downloadDisabled: false }))
  }

}


