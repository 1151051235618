
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage, EmojiEvents, PlayCircleFilled } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Student } from '../../../interfaces/examiner/Student';
import DataTable from 'react-data-table-component';
import { Exam } from '../../../interfaces/examiner/Exam';
import { Paper } from '../../../interfaces/shared/Paper';
import request from '../../../api';
import FileSaver from 'file-saver'
import StartSittingDialog from './StartSittingDialog';

interface Props {
  exam:Exam
  filter: string | undefined
}

interface State {
  data:Student[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  papers: Paper[], // for columns
  sortColumn:string
  sortOrder:'asc'|'desc'
  showAddStudentsDialog:boolean
  downloading:boolean
  startSittingDialog: Student | null
}

export default class ExaminerStudentsList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      papers:[], // for columns
      sortColumn:'username',
      sortOrder:'desc',
      showAddStudentsDialog: false,
      downloading:false,
      startSittingDialog:null,
    }
    console.dir(this.props.exam)
  }


  render() {

    let { data, totalRows, rowsPerPage, page, showAddStudentsDialog, papers } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

      let columns: any[] = [
          {
              name: 'Username',
              selector: 'username',
              sortable: true
          },
          ...papers.map((paper) => ({
              name: paper.title,
              selector: (student:Student) => {
                  let score = student.scores.filter(score => !score.questionId && score.paperId === paper.id)[0]
                  if(score === undefined)
                    return ''
                  return formatPercentage(score.score)
              },
              sortable: false
          })),
          {
              name: 'Total',
              selector: (student:Student) => {
                  let score = student.scores.filter(score => !score.questionId && !score.paperId)[0]
                  if(score === undefined)
                    return ''
                  return formatPercentage(score.score)
              },
              sortable: false
          },
          {
            name: 'Medal',
            selector: (student:Student) => {
                let score = student.scores.filter(score => !score.questionId && !score.paperId)[0]
                if(score === undefined)
                  return ''
                return <span style={{color:medalToColour(score.medal), fontWeight:"bold"}}>{score.medal}</span>
            },
            sortable: false
        },
          {
              cell: (student: Student) => {
              let score = student.scores.filter(score => !score.questionId && !score.paperId)[0]
              return (<Fragment>
                  <Button component={Link} to={`/examiner/exams/${this.props.exam.id}/students/` + student.id}><Edit /></Button>
                  {this.props.exam.certificate && (score ? score.medal : false) && <Button onClick={() => this.downloadCertificate(student.id)}><EmojiEvents /></Button>}
                  {student.teacher.schoolType === 'Test' && <Button onClick={() => this.openStartSittingDialog(student)}><PlayCircleFilled /></Button>}
              </Fragment>)}
          }
      ]

    return (
        <Fragment>
            {this.state.startSittingDialog && <StartSittingDialog student={this.state.startSittingDialog} onClose={this.closeStartSittingDialog} onStart={this.handleStartSitting} />}
            <Button disabled={this.state.downloading} onClick={this.downloadResults}>Download Results</Button>
            <DataTable
                columns={columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  async componentDidUpdate(prevProps: { filter: string | undefined; }) {
   if(prevProps.filter!=this.props.filter)
    {
      await this.setState(prevState => ({ ...prevState, page:1, totalRows: 0 }))
      this.reload()
    }
  } 

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder } = this.state
    let filter = this.props.filter ? this.props.filter : ''
    let { students, papers, total } = await (
        await request(`/api/examiner/exams/${this.props.exam.id}/students?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&schoolFilter=${filter}`)
    ).json()

    console.log('students')
    console.dir(students)

    await this.setState(prevState => ({ ...prevState, data: students, papers, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  downloadResults = async () => {
    await this.setState(prevState => ({ ...prevState, downloading: true}))
    let filter = this.props.filter ? this.props.filter : ''
    let r = await request(`/api/examiner/exams/${this.props.exam.id}/results?schoolFilter=${filter}`)

    let results = await r.text()

    var blob = new Blob([results], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'results.csv')
    await this.setState(prevState => ({ ...prevState, downloading: false}))
  }

  downloadCertificate = async (studentId:string) => {
    let r = await request(`/api/examiner/exams/${this.props.exam.id}/certificate/${studentId}`)
    const blob = await r.blob();
    FileSaver.saveAs(blob, `${this.props.exam.title}_SingleCertificate.pdf`);

  }

  openStartSittingDialog = async (student:Student) => {
    await this.setState(prevState => ({ ...prevState, startSittingDialog:student }))
  }
  
  handleStartSitting = async (student:Student) => {
    await request(`/api/examiner/exams/${this.props.exam.id}/startTestSitting/` + student.id, { method: 'PUT' })
    this.reload()
    await this.setState(prevState => ({ ...prevState, startSittingDialog:null }))
  }
  
  closeStartSittingDialog = async () => {
    await this.setState(prevState => ({ ...prevState, startSittingDialog:null }))
  }

}

function formatPercentage(s:any) {
    if(s === null || s === undefined || s === '') {
        return 'n/a'
    }
    s = s + ''
    if(s.length > 5) {
        s = s.substr(0, 5)
    }
    return s + '%'
}

function medalToColour(s:string) {
  if(s == "Gold") return "#ffd700"
  if(s == "Silver") return "#bec2cb"
  if(s == "Bronze") return "#cd7f32"
  return "black"
}


