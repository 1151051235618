
import React, { Fragment } from "react"
import { Answer } from "../../../../interfaces/examiner/Answer"
import { Score } from "../../../../interfaces/examiner/Score"
import { Question } from "../../../../interfaces/shared/Question"
import { QuestionPart } from "../../../../shared/QuestionSpec"
import SittingQuestionPartScore from "./SittingQuestionPartScore"


interface Props {
    question: Question
    answers: Answer[]
    scores: Score[]
}

interface State {
}

export default class SittingQuestionScore extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
        }
    }

    render() {

        let { question } = this.props

        if (question === null) {
            return <div className={"loading"}>Loading</div>
        }

        return (
            <Fragment>
                {question.questionSpec.parts.map(part => this.renderQuestionPart(part))
                }
            </Fragment>
        )
    }

    private renderQuestionPart(part: QuestionPart) {
        let scores = this.props.scores.filter(s => part.id === s.questionPartId || s.type === "PAPER")
        console.log("answers", this.props.answers)
        let answers: any = this.props.answers ? this.props.answers.map(a => a.answer.parts.filter(a2 => a2.questionPartId === part.id)[0]) : []
        let examPaperSittingIds = this.props.answers.map((a: any) => a.examPaperSittingId)
        if (answers.length > 0) {
            for (let i = 0; i < answers.length; i++) {
                answers[i] = {...answers[i], examPaperSittingId: examPaperSittingIds[i] }
            }
            answers = answers.filter((a:any) => a.questionPartId)
        }
        return <SittingQuestionPartScore part={part} answerParts={answers} scores={scores} />
    }


}






