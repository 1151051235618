
import { Button, createStyles, FormControl, IconButton, InputBase, InputLabel, makeStyles, MenuItem, Paper, Select, Slider, TextareaAutosize, TextField, Theme, Typography } from "@material-ui/core"
import { Cancel, Menu } from '@material-ui/icons'
import React, { Fragment } from "react"
import request, { getConfigSync } from "../../../../../api"
import { Content } from "../../../../../shared/QuestionSpec"

interface Props {
    subject:any
    content:Content
    onUpdateContent:(subject:any, newContent:Content)=>void
}

export default class ContentEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render()  {
        let { content } = this.props

        let textPart = content.text !== null ?
                this.renderTextEditor(content.text) :
                <Button onClick={this.addText}>+ Add Text</Button>

        let imagePart = content.image !== null ?
                this.renderImageEditor(content.image, content.imageSize, content.imageAlt) :
                <Button onClick={this.addImage}>+ Add Image</Button>

        return <Fragment>
            {textPart}
            <br/>
            <br/>
            {imagePart}
        </Fragment>

    }

    private renderTextEditor(content:string) {
        return <Fragment>
            <TextareaAutosize rowsMin={3} placeholder="Enter text here"  value={content} onChange={this.handleTextChange} className='fullWidth'/>
            <br/>
            <Button onClick={this.removeText}>- Remove Text</Button>
        </Fragment>
    }

    private renderImageEditor(image:string,imageSize:number|undefined,imageAlt:string|undefined) {
        return <Fragment>
            <TextField label="Image rollover text" value={imageAlt||''} onChange={this.handleAltTextChange} className='fullWidth'/>
            <br/>
            <br/>
            <Typography id="discrete-slider-Size" gutterBottom>
                Size
            </Typography>
            <Slider
                value={imageSize||300}
                aria-labelledby="discrete-slider-Size"
                valueLabelDisplay="auto"
                min={100}
                max={1000}
                onChange={this.onResize}
                style={{width: '300px'}}
            />
           <br/>
            <img src={getConfigSync().apiUrl + "/api/images/" + image} width={imageSize||300} title={imageAlt||''}/>
           <br/>
            <Button onClick={this.removeImage}>- Remove Image</Button>
        </Fragment>
    }

    addText = () => {
        
        let { content, subject, onUpdateContent } = this.props

        let newContent = { ...content, text: '' }
        onUpdateContent(subject, newContent)
    }

    addImage = () => {
        let { content, subject, onUpdateContent } = this.props

        let input = document.createElement('input')
        input.type = 'file'

        input.onchange = async (e:any) => { 
            var file = e.target.files[0]

            let uploadRes = await request('/api/examiner/images', {
                method: 'POST',
                body: file
            })

            let body = await uploadRes.text()

            // body should be the sha1 hash of the image

            if(body.length !== 40) {
                console.log('upload failed: ' + body)
                return
            }

            let newContent = { ...content, image: body }
            onUpdateContent(subject, newContent)
        }

        input.click()


        // let newContent = { ...content, image: '' }
        // onUpdateContent(subject, newContent)
    }

    removeText = () => {
        let { content, subject, onUpdateContent } = this.props

        let newContent = { ...content, text: null }
        onUpdateContent(subject, newContent)
    }

    removeImage = () => {
        let { content, subject, onUpdateContent } = this.props

        let newContent = { ...content, image: null }
        onUpdateContent(subject, newContent)
    }

    handleTextChange = (ev:any) => {

        let { content, subject, onUpdateContent } = this.props

        let newContent = { ...content, text: ev.target.value }
        onUpdateContent(subject, newContent)
    }

    handleAltTextChange = (ev:any) => {

        let { content, subject, onUpdateContent } = this.props

        let newContent = { ...content, imageAlt: ev.target.value }
        onUpdateContent(subject, newContent)
    }

    onResize = (ev:any, val:any) => {

        let { content, subject, onUpdateContent } = this.props

        let newContent = { ...content, imageSize: val }
        onUpdateContent(subject, newContent)
        console.log(newContent)
    }

}


