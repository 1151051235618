import { Button, Checkbox, FormControl, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import React, { Fragment } from "react"
import { Question } from "../../../../../interfaces/shared/Question"
import { Content, QuestionPart, QuestionPartListOrdering } from "../../../../../shared/QuestionSpec"
import uuid from "../../../../../uuid"
import ContentEditor from './ContentEditor'


interface Props {
    part: QuestionPartListOrdering
    onUpdate: (oldPart: QuestionPart, newPart: QuestionPart) => void
}

export default class ListOrderingEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let part = this.props.part

        return <Fragment>
            <div>
                <h3>Action Statement</h3>
                <ContentEditor subject={part} content={part.actionStatement} onUpdateContent={this.onUpdateActionStatement}/>
            </div>
            <h2>Answers</h2>
            <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left">Order</TableCell>
                        <TableCell align="left">Content</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {part.options.map((option, i) => (
                        <TableRow>
                            <TableCell component="th" scope="option">
                                {i > 0 && <Button onClick={() => this.moveRow(i, i - 1)}>▲</Button>}
                                <br />
                                {i < part.options.length - 1 && <Button onClick={() => this.moveRow(i, i + 1)}>▼</Button>}
                                <br />
                                <Button onClick={() => this.deleteRow(i)}><Delete /></Button>
                            </TableCell>
                            <TableCell align="left">
                                <ContentEditor subject={option} content={option.content} onUpdateContent={this.onUpdateContent} />
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Button color="primary" variant="outlined" onClick={this.addOption}>
                                + Add Item
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
        </Fragment>
    }

    onUpdateContent = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            options: part.options.map(option => {
                if (option === subject) {
                    return {
                        ...option,
                        content: newContent
                    }
                } else {
                    return option
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }

    addOption = () => {

        let part = this.props.part

        let newPart = {
            ...part,
            options: part.options.concat([
                {
                    id: uuid(),
                    content: { text: null, image: null }
                }
            ])
        }

        this.props.onUpdate(part, newPart)
    }

    moveRow(n: number, n2: number) {

        let part = this.props.part

        let newPart = { ...part }
        newPart.options.splice(n2, 0, newPart.options.splice(n, 1)[0])

        this.props.onUpdate(part, newPart)
    }

    deleteRow(n: number) {

        let part = this.props.part

        let newPart = { ...part }
        newPart.options.splice(n, 1)

        this.props.onUpdate(part, newPart)
    }

    onUpdateActionStatement = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            actionStatement: newContent
        }

        this.props.onUpdate(part, newPart)
    }
}

