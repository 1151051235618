
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table, TextField } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Teacher } from '../../../interfaces/examiner/Teacher';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import FileSaver from 'file-saver';

interface Props {
    onClickTeacher:(teacherId:string)=>void
}

interface State {
  data:Teacher[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc',
  searchQuery:string
  canUpload: boolean
  uploadMessage: string
}

export default class ExaminerTeacherList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      canUpload:true,
      uploadMessage:'',
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'timestamp',
      sortOrder:'desc',
      searchQuery:''
    }
  }

   columns: any[] = [
        // {
        //     name: 'Timestamp',
        //     selector: 'timestamp',
        //     sortable: true,
        //     cell: (teacher: Teacher) => new Date(teacher.timestamp).toLocaleDateString()
        // },
        {
            name: 'Name',
            selector: 'name',
            sortable: true
        },
        // {
        //     name: 'Email',
        //     selector: 'email',
        //     sortable: true
        // },
        {
            name: 'School Code',
            selector: 'schoolCode',
            sortable: true
        },
        {
            name: 'School',
            selector: 'school',
            sortable: true
        },
        {
            name: 'Phone Number',
            selector: 'phoneNumber',
            sortable: true
        },
        {
            name: 'Location',
            selector: 'location',
            sortable: true
        },
        {
            name: 'Default Student Password',
            selector: 'defaultPassword',
            sortable: false
        },
        // {
        //     name: 'Consent1',
        //     selector: 'consent1',
        //     sortable: true
        // },
        // {
        //     name: 'Consent2',
        //     selector: 'consent2',
        //     sortable: true
        // },
        // {
        //     name: 'Emailed',
        //     selector: 'emailed',
        //     sortable: true
        // }
    ]

  render() {

    let { data, totalRows, canUpload, uploadMessage } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            <Button disabled={!canUpload} variant="outlined" color="primary" onClick={this.handleImportCSV}>
                Import CSV
            </Button>
            <Button color="primary" variant="outlined" onClick={this.downloadTeachers}>
                    Download teacher list with consent to contact
            </Button>
            <Button color="primary" variant="outlined" onClick={this.downloadTeachersAll}>
                    Download teacher list (all)
            </Button>
            {uploadMessage ? <p> {uploadMessage} </p> : <br/>}
            <TextField margin="dense"
                    id="searchQuery" label="Search..."
                    fullWidth defaultValue="" 
                    onChange={(e) => this.handleFieldChange(e.target.value)} 
            />
            <br/>
            <DataTable
                dense
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
                highlightOnHover={true}
                pointerOnHover={true}
                onRowClicked={this.onRowClicked}
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder, searchQuery } = this.state

    let { teachers, total } = await (
        await request(`/api/examiner/teachers?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&searchQuery=${searchQuery}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: teachers, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  handleImportCSV = async () => {
    await this.setState(prevState => ({ ...prevState, canUpload: false, uploadMessage:'' }))

    let input = document.createElement('input')
    input.type = 'file'

    input.onchange = async (e:any) => { 
        var file = e.target.files[0]

        let uploadRes = await request('/api/examiner/teachers', {
            method: 'POST',
            body: file,
            headers: {
                'content-type': 'text/plain'
            }
        })
        let message = await uploadRes.text()
        await this.setState(prevState => ({ ...prevState, canUpload: true, uploadMessage: message }))
        this.reload()

    }

    input.click()



  }

  downloadTeachers = async () => {
    // await this.setState(prevState => ({ ...prevState, downloading: true}))
    let r = await request(`/api/examiner/teachers/downloadTeachers?examId=ALL&selection=CONTACT`)

    let results = await r.text()

    var blob = new Blob([results], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'TeachersCanContact.csv')
    // await this.setState(prevState => ({ ...prevState, downloading: false}))
  }

  downloadTeachersAll = async () => {
    // await this.setState(prevState => ({ ...prevState, downloading: true}))
    let r = await request(`/api/examiner/teachers/downloadTeachers?examId=ALL&selection=ALL`)

    let results = await r.text()

    var blob = new Blob([results], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'TeachersAll.csv')
    // await this.setState(prevState => ({ ...prevState, downloading: false}))
  }

  handleDeleteTeacher = async (teacher:Teacher) => {
    await request('/api/examiner/teachers/' + teacher.id, { method: 'DELETE' })
    this.reload()
  }

  onRowClicked = async (row:any) =>{
      this.props.onClickTeacher(row.id)
  }

  handleFieldChange = async (searchQuery:string) => {
    await this.setState(prevState => ({ ...prevState, searchQuery }))
    this.reload()
}

}
