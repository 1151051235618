import { Button, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Receipt } from '@material-ui/icons'
import { Exam } from '../../interfaces/examiner/Exam'
import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom'
import TeacherNav from './TeacherContainer';
import TeacherContainer from './TeacherContainer';
import { Dashboard } from '../../interfaces/teacher/Dashboard';
import request, { getConfigSync, isLoggedIn } from '../../api';
import TeacherDetailsDialog from './TeacherDetailsDialog';
// import TeacherExamList from './exams/TeacherExamList';

interface Props {
}

interface State {
  tempPassword:boolean
  name:string
  message: string
  defaultPassword: string
  redirect: boolean
  isAuthenticated:boolean
}

export default class TeacherDashboard extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      name: '',
      tempPassword: false,
      message: '',
      defaultPassword: '',
      redirect: false,
      isAuthenticated:true,
    }

    this.load()
  }

  render() {
      let { tempPassword ,name, message, defaultPassword, isAuthenticated } = this.state  
      
      if(!isLoggedIn('teacher')) {
        return <Redirect to='/login' />
      }

      if(this.state.redirect) {
        return <Redirect to="/teacher/updateDetails" push={true} />
      }
    return (
          <TeacherContainer heading="Teacher Dashboard">
          {
            tempPassword &&
            <TeacherDetailsDialog message={message} name={name} onSubmit={this.onSubmitDetails} />
          }
            <Fragment>
              <div>
                <h2>Welcome</h2>
                <p>Welcome to the {getConfigSync().competitionShortName} exam portal. From here, you are able to manage all {getConfigSync().competitionShortName} exams.</p>
                <p>If you have any questions, please don't hesitate to <a href={`mailto:${getConfigSync().contactEmail}`}>contact us</a>.</p>
                {!isAuthenticated && <p>This account has been restricted from using invoices. Please contact {getConfigSync().competitionShortName} for more information.</p>}
                <h2>Default Student Password</h2>
                <p> {`All of your students have the default password `} <strong>{defaultPassword}</strong></p>
                {(getConfigSync().competitionShortName === 'BPhO') && <Fragment>
                  <h2>Privacy Policy</h2>
                  <p> Please find our privacy policies at <a href="https://www.bpho.org.uk/terms/">https://www.bpho.org.uk/terms/</a></p>
                </Fragment>}
                <h2>Test Image</h2>
                <p>The student question portal loads images via the same method as the magnolia below. <br /> If you cannot see the picture, please check with your IT department as it is likely this is due to the school's filter settings.</p>
                <img src={`${getConfigSync().apiUrl}/api/images/cf91c64ca2566cb311432b300492c93055c67277`} width="200" title="Test image" alt="Test image" />  
                </div>
            </Fragment>

        </TeacherContainer>
    )
  }


  async load() {

    let res:Dashboard = await (await request('/api/teacher')).json()

    this.setState((prevState) => ({ ...prevState, tempPassword: res.tempPassword, name: res.name, defaultPassword: res.defaultPassword, isAuthenticated:res.isAuthenticated}))
  }

  onSubmitDetails = async (password1:string,password2:string) => {

    let message:string = await (await request('/api/teacher', {
        method: 'PUT',
        headers: {
            'content-type': 'application/json'
        },
        body: JSON.stringify({
            password1, password2
        })
    })).text()
    if (message == "ok"){
      let tempPassword = false
      let redirect = true
      this.setState((prevState) => ({ ...prevState, tempPassword, redirect }))
    }
    else {
        this.setState((prevState) => ({ ...prevState, message }))
    }
}
}
