
import { assert } from "console"
import uuid from "../uuid"


export enum QuestionPartType {
    Stem = 'Stem',
    Video = 'Video',
    PlotFunction = 'PlotFunction',
    RadioButtons = 'RadioButtons',
    Checkboxes = 'Checkboxes',
    ListOrdering = 'ListOrdering',
    ListDivideIntoGroups = 'ListDivideIntoGroups',
    MultipleTrueFalse = 'MultipleTrueFalse',
    OneWordAnswer = 'OneWordAnswer',
    DragAndDrop = 'DragAndDrop'
}

export default interface QuestionSpec {
    version:number
    parts:QuestionPart[]
}


export interface QuestionPart {
    type:QuestionPartType
    id:string
    marks:number
}

export interface QuestionPartStem extends QuestionPart {
    content: Content
}

export interface QuestionPartVideo extends QuestionPart {
    content: Content
    youtubeLink:string
}

export interface QuestionPartPlotFunction extends QuestionPart {
    content:Content
    plot:boolean
    functionType:"LINEAR"|"LOGISTIC"|"MM"
    linearVariables:{
        id:string,
        name:Content,
        defaultValue:number
        coefficient:number
    }[]
    logisticVariables:{
        L:number
        k:number
        x0:number
        name:Content,
        defaultValue:number
        id:'LOGVARS'
    }
    noise:number
    y:Content
    constanty:number
    constantx:number
}
export interface QuestionPartRadioButtons extends QuestionPart {
    actionStatement:Content
    options:{
        id:string,
        content:Content,
        _isCorrect:boolean
    }[]
}

export interface QuestionPartCheckboxes extends QuestionPart {
    actionStatement:Content
    options:{
        id:string,
        content:Content,
        _isCorrect:boolean
    }[]
}

export interface QuestionPartListOrdering extends QuestionPart{
    actionStatement:Content
    options:{
        id:string,
        content:Content
    }[]
}

export interface QuestionPartListDivideIntoGroups extends QuestionPart{
    actionStatement:Content
    groups:{
        id: string,
        heading:Content
    }[]
    options:{
        id:string,
        content:Content,
        _correctGroupId:string
    }[]
}

export interface QuestionPartMultipleTrueFalse extends QuestionPart {
    actionStatement:Content
    questions:{
        id:string
        content:Content,
        _answer:boolean
    }[]
}

export interface QuestionPartOneWordAnswer extends QuestionPart{
    actionStatement:Content
    content: Content,
    _correctAnswers:{
        text:string,
        ignoreCase:boolean,
        ignorePunctuation:boolean,
        ignoreSpaces:boolean,
        numericalAnswer:boolean
    }[]
}

export interface QuestionPartDragAndDrop extends QuestionPart{
    actionStatement:Content
    options:{
        content:Content,
        _isCorrect:boolean
    }[]
}

export interface Content {
    text:string|null
    image:string|null
    imageSize?:number|undefined
    imageAlt?:string|undefined
}

export function newContent() {
    return { text: null, image: null }
}

export function newQuestion():QuestionSpec {

    return {
        version: 1,
        parts: []
    }

}

export function newQuestionPart(type:QuestionPartType):QuestionPart {

    switch(type) {

        case QuestionPartType.Stem:
            return { id: uuid(), marks: 0, type: QuestionPartType.Stem, content: newContent() } as QuestionPartStem

        case QuestionPartType.Video:
            return { id: uuid(), marks: 0, type: QuestionPartType.Video, content: newContent(), youtubeLink: '' as string } as QuestionPartStem

        case QuestionPartType.PlotFunction:
            return { id: uuid(), marks: 0, type: QuestionPartType.PlotFunction, content: newContent(), functionType:'LINEAR', plot:false, linearVariables:[] as any, logisticVariables:{L:1,k:1,x0:1,defaultValue:1, name:newContent(),id:'LOGVARS'}, noise:1,constanty:0,constantx:0, y:newContent()} as QuestionPartPlotFunction

        case QuestionPartType.Checkboxes:
            return { id: uuid(), marks: 1, type: QuestionPartType.Checkboxes, actionStatement: newContent(), options: [] as any } as QuestionPartCheckboxes

        case QuestionPartType.RadioButtons:
            return { id: uuid(), marks: 1, type: QuestionPartType.RadioButtons, actionStatement: newContent(), options: [] as any } as QuestionPartRadioButtons

        case QuestionPartType.ListOrdering:
            return { id: uuid(), marks: 1, type: QuestionPartType.ListOrdering, actionStatement: newContent(), options: [] as any } as QuestionPartCheckboxes

        case QuestionPartType.ListDivideIntoGroups:
            return { id: uuid(), marks: 1, type: QuestionPartType.ListDivideIntoGroups, actionStatement: newContent(), groups: [] as any, options: [] as any } as QuestionPartListDivideIntoGroups

        case QuestionPartType.MultipleTrueFalse:
            return { id: uuid(), marks: 1, type: QuestionPartType.MultipleTrueFalse, actionStatement: newContent(), questions: [] as any } as QuestionPartMultipleTrueFalse
            
        case QuestionPartType.OneWordAnswer:
            return { id: uuid(), marks: 1, type: QuestionPartType.OneWordAnswer, actionStatement: newContent(), content: newContent(), _correctAnswers: [] as any } as QuestionPartOneWordAnswer

        case QuestionPartType.DragAndDrop:
            return { id: uuid(), marks: 1, type: QuestionPartType.DragAndDrop, actionStatement: newContent(), options: [] as any } as QuestionPartDragAndDrop

        default:
            throw new Error('unknown question part type ' + type)
    }
}