
import React, { Fragment } from "react"
import { Question } from "../../interfaces/shared/Question"

import { AnswerPart, AnswerSpec } from "../../shared/AnswerSpec"
import { QuestionPart } from "../../shared/QuestionSpec"
import SittingQuestionPart from "./SittingQuestionPart"
import { Grid } from "@material-ui/core"

interface Props {
    question: Question
    answer: AnswerSpec | null
    studentCode: string
    onUpdateAnswer:(newAnswer:AnswerSpec)=>void
}

interface State {
}

export default class SittingQuestion extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
        }
    }

    render() {

        let { question } = this.props

        if (question === null) {
            return <div>Loading...</div>
        }

        return (
            <Grid item>
                { question.questionSpec.parts.map(part => this.renderQuestionPart(part))
                }
            </Grid>
        )
    }

    private renderQuestionPart(part:QuestionPart) {
        let answer = this.props.answer ?
            this.props.answer.parts.filter(p => p.questionPartId === part.id)[0] || null
                : null
        return  <SittingQuestionPart part={part} answerPart={answer} studentCode={this.props.studentCode} onUpdate={this.handleUpdatePartAnswer} />
    }

    handleUpdatePartAnswer = (answerPart:AnswerPart) => {

        let newAnswer:AnswerSpec = this.props.answer!

        if(!newAnswer) {
            newAnswer = {version: 2, parts: [], flagged: false, everFlagged: false, timeOnQuestion: 0, answered: true }
        }

        newAnswer.answered = true

        let found = false

        for(let i = 0; i < newAnswer.parts.length; ++ i) {
            let part = newAnswer.parts[i]
            if(part.questionPartId === answerPart.questionPartId) {
                found = true
                newAnswer.parts[i] = answerPart
                break
            }
        }

        if(!found) {
            newAnswer.parts.push(answerPart)
        }

        this.props.onUpdateAnswer(newAnswer)
    }


}






