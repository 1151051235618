
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem } from '@material-ui/core'

interface Props {
    onAddStudents:(n:number)=>void,
    onClose:()=>void
    maxStudent:number
}

interface State {
    n:number
    canPressAdd: boolean
}

export default class AddStudentsDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            n: Math.min(5, this.props.maxStudent) ,
            canPressAdd: true
        }
    }

    render() {
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add students</DialogTitle>
                <DialogContent>
                    <p>Please select the number of students to enrol in this exam. </p>
                    <p> You can enrol up to {this.props.maxStudent} more {this.props.maxStudent === 1 ? "student" : "students"}.</p>
                    <TextField
                        label="Students"
                        type="number"
                        value = {this.state.n}
                        onChange = {this.onChangeNumber}
                        InputProps={{ inputProps: { min: 0, max: this.props.maxStudent } }}
                    />
                </DialogContent>
                <DialogActions>
                <Button disabled={(!this.state.canPressAdd) || (this.state.n>this.props.maxStudent)} onClick={this.onAddStudents} color="secondary">
                    Add Students
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onChangeNumber = (event: any) => {

        let newNumber = parseInt(event.target.value)

        this.setState({ n: newNumber})
    }

    onClose = () => {
        this.props.onClose()
    }

    onAddStudents = () => {
        this.setState(prevState => ({ ...prevState, canPressAdd: false }))
        this.props.onAddStudents(this.state.n)
    }
    
}