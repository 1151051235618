
import React, { Fragment } from "react";
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, Fade } from '@material-ui/core'

interface Props {
    onSubmit:(oldPassword:string,newPassword:string)=>void
    onClose:() => void
    message:string|null
}

interface State {
    oldPassword:string
    password1:string
    password2:string
}

export default class TeacherChangePasswordDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            oldPassword:'',
            password1:'',
            password2:'',
        }
    }

    render() {

        let { oldPassword, password1, password2 } = this.state

        let passwordsSame = (password1 == password2)
        let canPressAdd = oldPassword && password1 && password2 && passwordsSame && (password1.length>=12)

        return (
            <div>
            <Dialog open={true} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Change Your Password</DialogTitle>
                <DialogContent>
                    {
                        this.props.message &&
                        <Fragment>
                            <div style={{color: 'red'}}>{this.props.message}</div>
                            <br/>
                        </Fragment>
                    }
                    <TextField
                        required
                        type="password"
                        label="Current Password"
                        value = {oldPassword}
                        onChange = {this.onChangeOldPassword}
                    />
                     <br/>
                    <TextField
                        required
                        type="password"
                        label="New Password"
                        value = {password1}
                        onChange = {this.onChangePassword1}
                        helperText="Passwords must be >=12 characters"
                    />
                     <br/>
                    <TextField
                        required
                        type="password"
                        label="Retype New Password"
                        value = {password2}
                        onChange = {this.onChangePassword2}
                    />
                    {
                        <Fade in={!passwordsSame && (password2 != '')}>
                                <div style={{color: 'red'}}>
                                    Passwords are not the same.
                                </div>
                        </Fade>
                    }
                </DialogContent>
                <DialogActions>
                <Button disabled={!canPressAdd} onClick={this.onSubmit} color="secondary">
                    Change Password
                </Button>
                <Button  onClick={() => this.props.onClose()} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }
    onChangePassword1 = (e: any) => {
        this.setState((prevState) => ({ ...prevState, password1: e.target.value }))
    }

    onChangePassword2 = (e: any) => {
        this.setState((prevState) => ({ ...prevState, password2: e.target.value }))
    }

    onChangeOldPassword = (e: any) => {
        this.setState((prevState) => ({ ...prevState, oldPassword: e.target.value }))
    }
    

    onSubmit = () => {
        this.props.onSubmit(this.state.oldPassword,this.state.password1)
    }

}

