
import React, { Fragment } from "react";

interface Props {
    started:string
    finished?:string
}

interface State {
    interval:any
}

export default class StudentTimer extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            interval: setInterval(() =>{
                this.forceUpdate()
            },1000)
        }
    }

    componentWillUnmount(){
        clearInterval(this.state.interval)
    }
    render() {

        let { started, finished } = this.props
        let endTime = new Date()
        let startTime = new Date(started)
        finished ? endTime = new Date(finished!) :  endTime = new Date()
        let delta =  (endTime.getTime()- startTime.getTime())/1000
        let minutes = Math.floor(delta / 60)
        delta -=  minutes * 60   
        let seconds = Math.floor(delta % 60)

        return (
            `${minutes.toString().padStart(2, "0")}:${seconds.toString().padStart(2, "0")}`
        );
    }

}