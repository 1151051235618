
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { ExamPaperSitting } from '../../../interfaces/teacher/ExamPaperSitting';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import StudentTimer from '../../student/exams/StudentTimer';

interface Props {
}

interface State {
  data:ExamPaperSitting[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc'
  onlyFinished: boolean
}

export default class TeacherSittingList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'finished',
      sortOrder:'desc',
      onlyFinished: false
      
    }
  }

   columns: any[] = [
        {
            name: 'Username',
            selector: 'studentUsername',
            sortable: false
        },
        {
            name: 'Name',
            selector: 'studentName',
            sortable: false
        },
        {
            name: 'Paper',
            selector: 'paperTitle',
            sortable: false,
            wrap: true,
        },
        {
          name: 'Started',
          selector: 'started',
          sortable: true,
          cell: (sitting: ExamPaperSitting) => new Date(sitting.started).toLocaleDateString()
        },
        {
          name: 'Deadline',
          selector: 'deadline',
          sortable: true,
          cell: (sitting: ExamPaperSitting) => new Date(sitting.deadline).toLocaleTimeString()
        },
        {
          name: 'Finished',
          selector: 'finished',
          sortable: true,
          cell: (sitting: ExamPaperSitting) => sitting.finished ? new Date(sitting.finished).toLocaleTimeString() : ''
        },
        {
            name: 'Time spent',
            sortable: false,
            cell: (sitting: ExamPaperSitting) => sitting.finished ?
            <StudentTimer started={sitting.started} finished={sitting.finished}/>
            :
            <StudentTimer started={sitting.started}/>
        },
        {
          name: 'Invigilation',
          sortable: false,
          cell: (sitting: ExamPaperSitting) => sitting.finished ?
          <Fragment>
               <Button variant="outlined" color="secondary" onClick={() => this.unEndExam(sitting.id)}>Reopen Exam</Button>
          </Fragment>
          :
          <Fragment>
               <Button variant="outlined" color="secondary" onClick={() => this.endExam(sitting.id)}>End Exam</Button>
          </Fragment>
      }
    ]

  render() {

    let { data, totalRows, rowsPerPage, page } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    let conditionalRowStyles = [
      {
        when: (row: { deadline: string; finished:string; }) => this.isLive(row.finished),
        style: {
          backgroundColor: 'darkgreen',
          color: 'white',
          '&:hover': {
            cursor: 'cell',
          },
        },
      },
      {
        when: (row: { deadline: string; finished:string; }) => this.isPastDeadline(row.deadline,row.finished),
        style: {
          backgroundColor: '#8b008b',
          color: 'white',
          '&:hover': {
            cursor: 'cell',
          },
        },
      }
    ];

    return (
        <Fragment>
            <Grid container spacing={1}>
              <Grid item xs={3}>
                  <Button variant="outlined" color="secondary" onClick={() => this.endExams()}>End All Open Exams</Button>
              </Grid>
              <Grid item xs={3}>
                  <FormControlLabel
                             control={
                                 <Checkbox checked={this.state.onlyFinished} onClick={() => this.handleToggleOnlyFinished()} />}
                             label="View only unfinished papers"
                  />
              </Grid>
            </Grid>
                  <br />
                  <br />
            <DataTable
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
                conditionalRowStyles={conditionalRowStyles}
            />
      </Fragment>
    )
  }

  interval:any
  componentDidMount() {
      this.reload()
      this.interval = setInterval(() => this.reload(), 1000*60)
  }

  componentWillUnmount(){
    clearInterval(this.interval)
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder, onlyFinished } = this.state

    let { sittings, total } = await (
        await request(`/api/teacher/sittings?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&onlyFinished=${onlyFinished}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: sittings, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  handleToggleOnlyFinished = async () => {
    await this.setState(prevState => ({ ...prevState, onlyFinished: !this.state.onlyFinished}))
    this.reload()
}

  async endExam(sittingId:string) {

      await request(`/api/teacher/sittings/${sittingId}/close`,{
        method: 'POST'
      })

      this.reload()
  }

  async endExams() {

    await request(`/api/teacher/sittings/closeAll`,{
      method: 'POST'
    })

    this.reload()
  }

  async unEndExam(sittingId:string) {

    await request(`/api/teacher/sittings/${sittingId}/unClose`,{
      method: 'POST'
    })

    await this.reload()
}

isLive(finished:string) {
  if (finished){
    return(false)
  }
  else{
    return(true)
  }
} 

isPastDeadline(deadline:string, finished:string) {
    if (finished){
      return(false)
    }
    if(new Date().getTime()>new Date(deadline).getTime()){
      return(true)
    }
    else{
      return(false)
    }
  }

}