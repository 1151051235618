import React from 'react'
import { Link } from 'react-router-dom'

export default function Home() {
  return (
    <div>
      <Link to="/login">Examiner Login</Link>
      <br/>
      <Link to="/examiner">Examiner Dashboard</Link>
      <br/>
      <br/>
      <Link to="/login">Teacher Login</Link>
      <br/>
      <Link to="/teacher">Teacher Dashboard</Link>
      <br/>
      <br/>
      <Link to="/login">Student Login</Link>
      <br/>
      <Link to="/student">Student Home</Link>
      <br/>
      <br/>
      <form method="POST" action="/exportTestData">
          <button type="submit">Export test data from DB to JSON</button>
       </form>
    </div>
  )
}
