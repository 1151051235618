
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle } from '@material-ui/core'
import { Paper } from "../../../interfaces/shared/Paper";
import ExaminerPaperList from "../papers/ExaminerPaperList";
import { Question } from "../../../interfaces/shared/Question";

interface Props {
    question:Question
    onAssign:(question:Question, paper:Paper) => void
    onClose:() => void
}

interface State {
}

export default class AssignPaperDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)
    }

    render() {

        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Assign Question to Paper</DialogTitle>
                <DialogContent>
                    <p>The question <i>{this.props.question.description}</i> will be added to the selected paper.</p>
                    <ExaminerPaperList onSelect={this.onSelectPaper} readOnly />
                </DialogContent>
                <DialogActions>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    onSelectPaper = (paper:Paper) => {
        this.props.onAssign(this.props.question, paper)
    }
    
}

