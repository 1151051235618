
import { Button, CircularProgress, debounce, Link, Paper as MaterialPaper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { Fragment } from "react";
import ExaminerContainer from "../ExaminerContainer";
import { Paper } from '../../../interfaces/shared/Paper'
import SavingIndicator from "../../../components/SavingIndicator";
import { Question } from "../../../interfaces/shared/Question";
import { Cancel, Delete } from "@material-ui/icons";
import request, { isLoggedIn } from "../../../api";
import { Redirect } from "react-router-dom";
import 'chart.js';
import { VictoryChart, VictoryBoxPlot, VictoryHistogram, VictoryAxis, VictoryLabel } from "victory";
import { Score } from "../../../interfaces/examiner/Score";
import ScoreQuestionDialog from "../questions/ScoreQuestionDialog";
import { Answer } from "../../../interfaces/examiner/Answer";
const reactChartkick = require('react-chartkick');


interface Props {
    id: string
}

interface State {
    scores: Score[] | null,
    paper: Paper | null,
    showQuestionScoreDialog: Question|null,
}

export default class ExaminerQuestionScoresPage extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            scores: null,
            paper: null,
            showQuestionScoreDialog:null,
        }

        this.loadPaper()
    }

    render() {

        let { scores, paper } = this.state

        if (!isLoggedIn('examiner')) {
            return <Redirect to='/login' />
        }


        if (!scores || !paper) {
            return <ExaminerContainer heading={"Loading..."}>Loading...</ExaminerContainer>
        }

        if (scores.length === 0) {
            return <ExaminerContainer heading={paper.title}>No results yet...</ExaminerContainer>
        }

        let totalScores = scores.filter((s: Score) => s.type === "PAPER")
        let totals = new Map()
        for (let t of totalScores) { totals.set(t.studentId, t) }
        let paperScoreArray = scores!.filter((s: Score) => s.type === "PAPER")
        let histDataAll = arrayDataFromScore(paperScoreArray)
        return (
            <ExaminerContainer heading={paper.title}>
                {this.state.showQuestionScoreDialog && <ScoreQuestionDialog onClose={this.handleCloseScoreQuestionDialog} question={this.state.showQuestionScoreDialog}/>}
                <h2>Question level analysis</h2>
                <h3>Sittings: {(scores.filter((s: any) => !s.questionId && !s.questionPartId)).length}</h3>
                <h3>Mean score: {formatPercentage(avgScore((scores.filter((s: any) => !s.questionId && !s.questionPartId))))}</h3>
                <div style={{ display: "flex" }}>
                    <div style={{ width: "30%", margin: "auto" }}>
                        <VictoryChart domainPadding={30} padding={{ left: 80, top: 50, bottom: 50, right: 80 }} >
                            <VictoryHistogram
                                cornerRadius={3}
                                data={histDataAll}
                            />
                            <VictoryAxis label="Score (%)" />
                            <VictoryAxis
                                axisLabelComponent={<VictoryLabel dy={-24} />}
                                dependentAxis
                                label="Students" />
                        </VictoryChart>
                    </div>

                    <div style={{ width: "60%", margin: "auto" }}>
                        <VictoryChart domainPadding={10} height={250} width={750} >
                            <VictoryBoxPlot
                                boxWidth={10}
                                data={boxPlotData(scores, paper)}
                            />
                            <VictoryAxis label="Question Number" />
                            <VictoryAxis
                                dependentAxis
                                label="Score (%)" />
                        </VictoryChart>
                    </div>
                </div>
                <TableContainer component={MaterialPaper}>
                    <Table aria-label="simple table">
                        <TableBody>
                            <TableRow>
                                <TableCell component="th" scope="option">
                                    Number
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Description
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Attempted
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Average
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Pearson Correlation
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Pie
                            </TableCell>
                                <TableCell component="th" scope="option">
                                    Histogram
                            </TableCell>
                            </TableRow>
                            {paper.questions.map((question: Question, i: number) => {
                                console.time('questionScoreArray')
                                let questionScoreArray = scores!.filter((s: any) => s.questionId === question.id && !s.questionPartId)
                                if (questionScoreArray.length === 0) return
                                console.timeEnd('questionScoreArray')
                                console.time('totalScores')
                                console.timeEnd('totalScores')
                                console.time('description')
                                console.log(question.description, questionScoreArray)
                                console.timeEnd('description')
                                console.time('avg')
                                const avg = formatPercentage(avgScore(questionScoreArray))
                                console.timeEnd('avg')
                                console.time('correct')
                                const correct = totalRight(questionScoreArray);
                                console.timeEnd('correct')
                                console.time('attempted')
                                const attempted = questionScoreArray.length;
                                console.timeEnd('attempted')
                                console.time('corScore')
                                let corScore = corrScore(questionScoreArray, totals)
                                console.timeEnd('corScore')
                                console.time('allData')
                                let allData = arrayDataFromScore(questionScoreArray)
                                console.timeEnd('allData')
                                console.log(allData)
                                return (
                                    <TableRow>
                                        <TableCell component="th" scope="option">
                                            {(i + 1).toString()}
                                        </TableCell>
                                        <TableCell component="th" scope="option">
                                            <Link href={`/examiner/questions/${question.id}/scores`}>{question.description}</Link>
                                            <Button onClick={() => this.handleScoreQuestionDialog(question)}>View</Button>
                                        </TableCell>
                                        <TableCell component="th" scope="option">
                                            {attempted}
                                        </TableCell>
                                        <TableCell component="th" scope="option">
                                            {avg}
                                        </TableCell>
                                        <TableCell component="th" scope="option">
                                            {corScore}
                                        </TableCell>
                                        <TableCell component="th" scope="option">
                                            <reactChartkick.PieChart data={[["Correct", correct], ["Incorrect", attempted - correct]]} height="150px" />
                                        </TableCell>
                                        <TableCell component="th" scope="option">
                                            <VictoryChart domainPadding={10}>
                                                <VictoryHistogram
                                                    cornerRadius={3}
                                                    data={allData}
                                                />
                                            </VictoryChart>
                                        </TableCell>
                                    </TableRow>
                                )
                            }
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
            </ExaminerContainer>
        )

    }


    private async loadPaper() {

        let { scores, paper } = await (await request(`/api/examiner/papers/${this.props.id}/getScores`)).json()
        this.setState(prevState => ({ ...prevState, scores, paper }))
    }


    handleScoreQuestionDialog = async (question:Question) => {
        this.setState(prevState => ({ ...prevState, showQuestionScoreDialog: question}))
    }

    handleCloseScoreQuestionDialog = () => {
        this.setState(prevState => ({ ...prevState, showQuestionScoreDialog: null }))
    }

}

function avgScore(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        total = total + parseFloat(score[i].score);
    }
    var avg = total / i
    return avg;
}

function corrScore(score: any, totals: any) {
    var questionScore = [];
    var answerScore = [];
    for (var i = 0; i < score.length; i++) {
        questionScore.push(parseFloat(score[i].score));
        answerScore.push(parseFloat(totals.get(score[i].studentId).score))
    }
    var corr = pcorr(questionScore, answerScore)
    return corr.toFixed(2);
}

function arrayDataFromScore(score: any) {
    var questionScore = [];
    for (var i = 0; i < score.length; i++) {
        questionScore.push({ x: parseFloat(score[i].score) });
    }
    return questionScore;
}

function totalRight(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        if (score[i].score > 0) total = total + 1;
    }
    return total;
}

function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    s = s + ''
    if (s.length > 5) {
        s = s.substr(0, 5)
    }
    return s + '%'
}

function pcorr(x: number[], y: number[]) {
    let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        sumY2 = 0;
    const minLength = x.length = y.length = Math.min(x.length, y.length),
        reduce = (xi: number, idx: number) => {
            const yi = y[idx];
            sumX += xi;
            sumY += yi;
            sumXY += xi * yi;
            sumX2 += xi * xi;
            sumY2 += yi * yi;
        }
    x.forEach(reduce);
    return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
};

function boxPlotData(scores1: any, paper: any) {
    let data = []
    for (var i = 0; i < paper.questions.length; i++) {
        let desc = paper.questions[i].description
        let score = scores1.filter((s: any) => s.questionId === paper.questions[i].id && !s.questionPartId)
        let scores = []
        for (var j = 0; j < score.length; j++) {
            scores.push(parseFloat(score[j].score));
        }
        if (scores.length > 0) {
            data.push(
                { x: (i + 1).toString(), y: scores }
            )
        }
    }
    console.log(data)
    return data
}

