
import { Checkbox, Grid } from "@material-ui/core"
import React from "react"
import Content from "../../../../components/questions/Content"
import { Score } from "../../../../interfaces/examiner/Score"
import { AnswerPart, AnswerPartCheckboxes } from "../../../../shared/AnswerSpec"
import { QuestionPartCheckboxes } from "../../../../shared/QuestionSpec"

interface Props {
    part:QuestionPartCheckboxes
    answers:AnswerPartCheckboxes[]|null
    scores:Score[]
}

export default function CheckboxesScore(props:Props) {

    let { part,scores, answers} = props

    let totalScores = scores.filter((s: Score) => s.type === "PAPER")
    let totals = new Map()  
    for (let t of totalScores) { totals.set(t.examPaperSittingId, t) }

    return <div>
         <Content content={part.actionStatement} />
         <p>Avg score: {formatPercentage(avgScore(scores.filter((s: Score) => s.questionPartId === part.id)))}</p>
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <table>
         <tr>
                        <td>
                                    <Checkbox />

                        </td>
                        <td>
                                    Option
                        </td>
                        <td>
                                # Ticked 
                        </td>
                        <td>
                                # Not Ticked
                        </td>
                        <td>
                                Corr
                        </td>
                    </tr>
         {
             part.options.map((option) => {
                let {sum, all} = sumAnswers(answers, option)
                 return(
                    <tr>
                        <td>
                                    <Checkbox checked = {option._isCorrect}  />

                        </td>
                        <td>
                                    <Content content={option.content} />
                        </td>
                        <td>
                                {sum}
                        </td>
                        <td>
                                {all-sum} 
                        </td>
                        <td>
                                {answers && answers.length > 0 ? corrScore(option, answers, totals) : "na"}
                        </td>
                    </tr>
                 )
             })
         }
         </table>
    </div>

   
}

function avgScore(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        total = total + parseFloat(score[i].score);
    }
    var avg = total / i
    return avg;
}

function sumAnswers(answers:any, option:any){
    var sum = 0;
    var all = 0;
    for (var i = 0; i < answers.length; i++) {
        if (answers[i] && answers[i].selectedOptionIds){
        sum = sum + answers[i].selectedOptionIds.filter((opt:any) => opt === option.id).length
        all = all + 1
        }
    }
  return {sum, all}
}



function pcorr(x: number[], y: number[]) {
    let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        sumY2 = 0;
    const minLength = x.length = y.length = Math.min(x.length, y.length),
        reduce = (xi: number, idx: number) => {
            const yi = y[idx];
            sumX += xi;
            sumY += yi;
            sumXY += xi * yi;
            sumX2 += xi * xi;
            sumY2 += yi * yi;
        }
    x.forEach(reduce);
    return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
};

function corrScore(option: any, answers: any, scores: any) {
    var wrongRight = [];
    var score = [];
    for (var i = 0; i < answers.length; i++) {
        let id = answers[i].examPaperSittingId
        if (scores.get(id) && answers[i].selectedOptionIds) {
            wrongRight.push(answers[i].selectedOptionIds.filter((opt:any) => opt === option.id).length>0 === option._isCorrect ? 1 : 0)
            score.push(parseFloat(scores.get(id).score));
        }
    }
    console.log(wrongRight, score)
    var corr = pcorr(wrongRight, score)
    return corr.toFixed(2);
}

function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    let s2 = Math.round(s * 100) / 100
    return s2 + '%'
}
