

import { Button, Checkbox, FormControlLabel, Grid } from '@material-ui/core';
import { Sitting } from '../../../interfaces/student/Sitting'
import React from 'react';
import { Link, Redirect } from 'react-router-dom'
import { QuestionPart } from '../../../shared/QuestionSpec';
import SittingQuestion from '../../../components/questions/SittingQuestion';
import { AnswerSpec } from '../../../shared/AnswerSpec';
import { ArrowBack, ArrowForward, Check } from '@material-ui/icons';
import request, { isLoggedIn } from '../../../api';
import StudentSittingContainer from './StudentSittingContainer';
import { studentPoll } from '../../../poll'

interface Props {
  //siitingId
  id: string
}

interface State {
    sitting:Sitting|null
    numSavesPending:number
    finished:boolean
    dangerAccept:boolean
    timeFrom: Date
}

export default class StudentSittingPage extends React.Component<Props, State> {

   constructor(props:Props) {
 
    super(props)

    this.state = {
      sitting: null,
      numSavesPending: 0,
      finished: false,
      dangerAccept: false,
      timeFrom: new Date()
    }
 // window.addEventListener('unload', () => {
  //  send timestamp!
  // })
    studentPoll()
  }

  render() {

    let { finished, sitting } = this.state
    
    if(!isLoggedIn('student')) {
        return <Redirect to='/login' />
    }


    if(!sitting) {
        return <div>Loading...</div>
    }

    if(finished) {
        return <Redirect to="/student" />
    }

    return (
        <StudentSittingContainer studentName={sitting.studentName} handleQuestionChange = {this.handleQuestionChange} currentQuestionId = {sitting.currentQuestion.id} questions = {sitting.questions} started = {sitting.started} >
            <Grid container justify="flex-end" direction="column" style={{paddingLeft: '16px', paddingRight: '16px'}}>
              <Grid container justify="flex-end">
                { sitting.currentAnswer ? (sitting.currentAnswer!.flagged ? <Button variant="outlined" color="primary"  onClick={this.handleFlag}>Unflag</Button>: <Button variant="outlined" color="primary" onClick={this.handleFlag}>Flag</Button>) : <Button variant="outlined" color="primary"  onClick={this.handleFlag}>Flag</Button>}
              </Grid>
                <SittingQuestion studentCode={sitting.username} question={sitting.currentQuestion} answer={sitting.currentAnswer} onUpdateAnswer={this.onUpdateAnswer} />
              <Grid container justify="center">
                { sitting.currentQuestionNumber > 1 &&
                <Grid item xs={2}>
                    <Button onClick={() => this.handleQuestionChange(sitting!.questions[sitting!.currentQuestionNumber-2].id)}><ArrowBack/> Back</Button>
                </Grid>
                }

                { sitting.currentQuestionNumber < sitting.totalQuestions &&
                <Grid item xs={2}>
                    <Button onClick={() => this.handleQuestionChange(sitting!.questions[sitting!.currentQuestionNumber].id)}>Next Question <ArrowForward/></Button>
                </Grid>
                }
              </Grid>

                { sitting.currentQuestionNumber === sitting.totalQuestions &&        
                <Grid container justify="center" spacing={1}>
                  <Grid item xs={4}>
                    <FormControlLabel
                              control={
                                <Checkbox onChange = {() => this.setState((prevState) => ({...prevState, dangerAccept: !prevState.dangerAccept}))} />}
                              label="I have checked my answers and accept my submission is final"
                    />
                    
                  </Grid>
                  <Grid item xs={4}>
                      <Button disabled={!this.state.dangerAccept} onClick={this.onClickSubmit}>Submit Paper <Check/></Button>
                  </Grid>
                </Grid>
                }
             
            </Grid>
        </StudentSittingContainer>
    )
  }

  componentDidMount() {

    this.reloadSitting()

  }

  private async reloadSitting() {

    let sitting = await (await request('/api/student/sittings/' + this.props.id)).json()
    this.setState(prevState => ({ ...prevState, sitting, finished: sitting.finished }))
    window.scrollTo(0, 0)
  }

  onUpdateAnswer = async (newAnswer:AnswerSpec) => {
    let sitting = this.state.sitting
    newAnswer.timeOnQuestion! += (new Date().getTime() - this.state.timeFrom.getTime())/1000
    let timeFrom = new Date()
    sitting!.currentAnswer = newAnswer
    for (let question of sitting!.questions){
      if (question.id == sitting!.currentQuestion.id){
        question.answer = newAnswer
      }
    }
    this.setState((prevState) => ({ ...prevState, sitting, numSavesPending:prevState.numSavesPending+1, timeFrom }))

    let res = await (await request('/api/student/sittings/' + this.props.id + '/questions/' + this.state.sitting!.currentQuestion.id + '/answer', {
        method: 'POST',
        body: JSON.stringify(newAnswer),
        headers: {
            'content-type': 'application/json'
        }
    }
    )).text()

    if(res === 'ok') {
        this.setState((prevState) => ({ ...prevState, sitting, numSavesPending:prevState.numSavesPending-1 }))
    } else {
        console.error('could not save: ' + res)
    }
  }

  onClickSubmit = async () => {
    let newAnswer:AnswerSpec = this.state.sitting!.currentAnswer!
    if(!newAnswer) {
        newAnswer = { version: 2, parts: [], flagged: false, everFlagged: false, timeOnQuestion: 0, answered: false }
    }
    this.onUpdateAnswer(newAnswer)
    let res = await (await request('/api/student/sittings/' + this.props.id + '/submit', { method: 'POST' }))
    this.setState((prevState) => ({ ...prevState, finished: true }))
  }

  handleFlag = async () => {
    let newAnswer:AnswerSpec = this.state.sitting!.currentAnswer!

    if(!newAnswer) {
        newAnswer = { version: 2, parts: [], flagged: false, everFlagged: false, timeOnQuestion: 0, answered: false }
    }

    newAnswer.flagged = !newAnswer.flagged
    newAnswer.everFlagged = true

    this.onUpdateAnswer(newAnswer)
  }

  handleQuestionChange = async (id:string) => {
    let newAnswer:AnswerSpec = this.state.sitting!.currentAnswer!
    if(!newAnswer) {
        newAnswer = { version: 2, parts: [], flagged: false, everFlagged: false, timeOnQuestion: 0, answered: false }
    }
    this.onUpdateAnswer(newAnswer)
    console.log(id)
    let res = await (await request('/api/student/sittings/' + this.props.id + '/changeQuestion',
    {
      method: 'POST',
      headers: { 'content-type': 'application/json' }, body: JSON.stringify({ questionId: id }) 
   }))
    this.reloadSitting()
  }
  
}


