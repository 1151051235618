

import { Button, debounce, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Paper as MaterialPaper, Link, Paper, Box, MenuItem, Select, InputLabel, FormControlLabel, Checkbox } from '@material-ui/core';
import { Teacher } from '../../interfaces/examiner/Teacher';
import React, { Fragment, useMemo } from 'react';
import TeacherContainer from './TeacherContainer';
import countryList from 'react-select-country-list'
import '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SavingIndicator from '../../components/SavingIndicator';
import { Cancel } from '@material-ui/icons';
import request, { isLoggedIn, getConfigSync } from '../../api';
import { Redirect } from 'react-router-dom';
import TeacherChangePasswordDialog from './TeacherChangePasswordDialog';

interface Props {
}

interface State {
  teacher: Teacher | null,
  saving: boolean,
  changePasswordDialog: boolean
  message: string|null
  passwordChanged: boolean
}

export default class TeacherTeacherPage extends React.Component<Props, State> {

  constructor(props: Props) {

    super(props)

    this.state = {
      teacher: null,
      saving: false,
      changePasswordDialog: false,
      message: null,
      passwordChanged:false
    }
  }

  render() {

    let { teacher, saving } = this.state

    if (!isLoggedIn('teacher')) {
      return <Redirect to='/login' />
    }


    if (!teacher) {
      return <TeacherContainer heading={"Loading..."}>Loading...</TeacherContainer>
    }
    const options = countryList().getData()
    return (
      <TeacherContainer heading={teacher.name}>
        {this.state.changePasswordDialog && <TeacherChangePasswordDialog message={this.state.message} onClose={this.onClose} onSubmit={this.onPasswordChange} />}
        <SavingIndicator saving={saving} />
        <h3>Personal Details</h3>
        <Button onClick={this.openChangePassword} variant="outlined">Change Password</Button>
        <br />
        {this.state.passwordChanged && <p>Password updated!</p>}
        <br />
        <TextField margin="dense"
          id="name" label="Name"
          fullWidth defaultValue={teacher.name}
          onChange={(e) => this.handleFieldChange('name', e)} />
        <TextField disabled margin="dense"
          id="username" label="Username"
          fullWidth defaultValue={teacher.schoolCode}
        />
        <TextField disabled margin="dense"
          id="email" label="Primary Contact Email"
          fullWidth defaultValue={teacher.email}
          helperText={`Contact ${getConfigSync().competitionShortName} to change`}
        />
        <TextField disabled margin="dense"
          id="school" label="School"
          fullWidth defaultValue={teacher.school}
        />
        <TextField disabled margin="dense"
          id="urn" label="URN"
          fullWidth defaultValue={teacher.urn || 'No URN assigned'}
          helperText={`Contact ${getConfigSync().competitionShortName} to change`}
        />
        <TextField margin="dense"
          id="phoneNumber" label="Telephone Number"
          fullWidth defaultValue={teacher.phoneNumber}
          onChange={(e) => this.handleFieldChange('phoneNumber', e)} />
        <FormControlLabel
          control={
            <Checkbox
              onChange={this.toggleConsent}
              checked={teacher.consent2 == "accept"}
              color="primary"
            />}
          label={`Please keep me informed about ${getConfigSync().competitionShortName} competitions`}
        />
        <h3>School Postal Address and Billing Address</h3>
        <TextField margin="dense"
          id="line1" label="Line 1"
          fullWidth defaultValue={teacher.addressLine1}
          onChange={(e) => this.handleFieldChange('addressLine1', e)} />
        <TextField margin="dense"
          id="line2" label="Line 2"
          fullWidth defaultValue={teacher.addressLine2}
          onChange={(e) => this.handleFieldChange('addressLine2', e)} />
        <TextField margin="dense"
          id="addressCity" label="City"
          fullWidth defaultValue={teacher.addressCity}
          onChange={(e) => this.handleFieldChange('addressCity', e)} />
        <TextField margin="dense"
          id="addressState" label="County"
          fullWidth defaultValue={teacher.addressState}
          onChange={(e) => this.handleFieldChange('addressState', e)} />
        <TextField margin="dense"
          id="addressPostcode" label="Postcode"
          fullWidth defaultValue={teacher.addressPostcode}
          onChange={(e) => this.handleFieldChange('addressPostcode', e)} />
        <br /><br />
        <InputLabel id="addressCountry-label">Country</InputLabel>
        <Select margin="dense"
          id="addressCountry" label="Country"
          fullWidth defaultValue={teacher.addressCountry}
          onChange={(e) => this.handleFieldChange('addressCountry', e)}>
          <MenuItem
            value={"GB"}>
            {"United Kingdom"}
          </MenuItem>
          {options.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o.value}>
                {o.label}
              </MenuItem>
            );
          })}
        </Select>
        <div>

        </div>
      </TeacherContainer>
    )
  }

  componentDidMount() {

    this.reloadTeacher()

  }

  private async reloadTeacher() {

    let teacher = await (await request('/api/teacher/updateDetails')).json()

    this.setState(prevState => ({ ...prevState, teacher }))
  }

  async saveWithDelay() {
    await this.setState(prevState => ({ ...prevState, saving: true }))
    this.saveDebounced()
  }

  saveDebounced = debounce(async () => this.save(), 1000)

  async save() {
    await request('/api/teacher/updateDetails', {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(this.state.teacher)
    })
  }

  handleFieldChange = async (field: string, e: any) => {
    let teacher = this.state.teacher as Teacher
    let newTeacher: Teacher = { ...teacher, [field]: e.target.value }

    await this.setState(prevState => ({ ...prevState, teacher: newTeacher }))
    this.save()
  }

  toggleConsent = async (event: { target: { checked: boolean; }; }) => {
    let teacher = this.state.teacher as Teacher
    let consent = ""
    if (event.target.checked) { consent = "accept" }
    let newTeacher: Teacher = { ...teacher, consent2: consent }
    await this.setState(prevState => ({ ...prevState, teacher: newTeacher }))
    this.save()
  }

  openChangePassword = () => {
    this.setState(prevState => ({ ...prevState, changePasswordDialog: true }))
  }

  onClose = () => {
    this.setState(prevState => ({ ...prevState, changePasswordDialog: false, message:null }))
  }

  onPasswordChange = async (oldPassword: string, newPassword: string) => {

    let message: string = await (await request('/api/teacher/updateDetails/changePassword', {
      method: 'PUT',
      headers: {
        'content-type': 'application/json'
      },
      body: JSON.stringify({
        oldPassword, newPassword
      })
    })).text()
    if (message === "ok") {
      this.setState(prevState => ({ ...prevState, changePasswordDialog: false, message:null, passwordChanged: true }))
    }
    else {
      this.setState((prevState) => ({ ...prevState, message }))
    }

  }


}
