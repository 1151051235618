
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table, TextField } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Examiner } from '../../../interfaces/examiner/Examiner';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import EditExaminerDialog from './EditExaminerDialog';
import NewExaminerDialog from './NewExaminerDialog';

interface Props {
  isSuperuser:boolean
}

interface State {
  data:Examiner[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc',
  searchQuery:string
  editExaminerDialog: Examiner | null
  newExaminerDialog: boolean
  userId:string
}

export default class ExaminerExaminerList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'createdAt',
      sortOrder:'desc',
      searchQuery:'',
      editExaminerDialog: null,
      newExaminerDialog: false,
      userId: ''
    }
  }

   columns: any[] = [
        // {
        //     name: 'Timestamp',
        //     selector: 'timestamp',
        //     sortable: true,
        //     cell: (examiner: Examiner) => new Date(examiner.timestamp).toLocaleDateString()
        // },
        {
            name: 'Username',
            selector: 'username',
            sortable: true
        },
        {
            name: 'Edit',
            cell: (examiner: Examiner) =>
            <Fragment>
            {(this.props.isSuperuser || this.state.userId == examiner.id) && <Edit style={{ cursor: 'pointer' }} onClick={() => this.handleEditExaminer(examiner)} />}
            </Fragment>
        },
    ]

  render() {

    let { data, totalRows, rowsPerPage, page, searchQuery } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            {
                this.state.editExaminerDialog &&
                <EditExaminerDialog isSuperuser = {this.props.isSuperuser} examiner={this.state.editExaminerDialog} onEdit={this.editExaminer} onClose={this.handleCloseEditExaminerDialog} />
            }
            {
                this.state.newExaminerDialog &&
                <NewExaminerDialog onCreate={this.createExaminer} onClose={this.handleCloseNewExaminerDialog} />
            }
            <Button variant="outlined" color="primary" onClick={this.handleCreateExaminer}>
                New Examiner
            </Button>
            <br/>
            <TextField margin="dense"
                    id="searchQuery" label="Search..."
                    fullWidth defaultValue="" 
                    onChange={(e) => this.handleFieldChange(e.target.value)} 
            />
            <br/>
            <DataTable
                dense
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
                highlightOnHover={true}
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder, searchQuery } = this.state

    let { examiners, total, reqId } = await (
        await request(`/api/examiner/examiners?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&searchQuery=${searchQuery}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: examiners, totalRows: total, userId: reqId }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  handleFieldChange = async (searchQuery:string) => {
    await this.setState(prevState => ({ ...prevState, searchQuery }))
    this.reload()
}

handleEditExaminer = (examiner:Examiner) => {
  this.setState(prevState => ({ ...prevState, editExaminerDialog: examiner }))
}

editExaminer = async (examiner:Examiner) => {
  await request('/api/examiner/examiners/' + examiner.id, {
    method: 'PUT',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(examiner)
  })
  await this.setState(prevState => ({ ...prevState, saving: false }))
  this.setState(prevState => ({ ...prevState, examiner: examiner, editExaminerDialog: null }))
  this.reload()
}

handleCloseEditExaminerDialog = () => {
  this.setState(prevState => ({ ...prevState, editExaminerDialog: null }))
}

handleCreateExaminer = () => {
  this.setState(prevState => ({ ...prevState, newExaminerDialog: true }))
}

createExaminer = async (examiner:Examiner) => {
  console.log(examiner)
  await request(`/api/examiner/examiners`, {
    method: 'POST',
    body: JSON.stringify(examiner),
    headers: { "content-type": "application/json" }
  })
  this.setState(prevState => ({ ...prevState, newExaminerDialog: false }))
  this.reload()
}

handleCloseNewExaminerDialog = () => {
  this.setState(prevState => ({ ...prevState, newExaminerDialog: false }))
}

}
