
import React, { Fragment } from "react";
import request from '../../../api';
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, InputLabel } from '@material-ui/core'
import { Exam } from "../../../interfaces/teacher/Exam";
import { loadStripe } from "@stripe/stripe-js";
import { getConfig } from "../../../api"

interface Props {
    onClose:()=>void
    onPurchase:()=>void
    exam:Exam
}

interface State {
    n:number
    canPressAdd: boolean
    paymentType: string
    message:string|null
}

export default class PurchaseExamDialog extends React.Component<Props, State> {
    constructor(props:Props) {

        super(props)

        this.state = {
            n:5,
            canPressAdd: true,
            paymentType: "STRIPE",
            message:null,

        }
    }

    async loadStripe() {
        let config = await getConfig()
        return await loadStripe(config.pk)
    }

    render() {
        
        let exam = this.props.exam
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Purchase Exam Access</DialogTitle>
                
                {
                    exam.pricingModel === "FREE" &&
                    <Fragment>
                    <DialogContent>
                    {
                        this.state.message &&
                        <Fragment>
                            <div style={{color: 'red'}}>{this.state.message}</div>
                            <br/>
                        </Fragment>
                    }
                        <p>You may enter unlimited students for free!</p>
                        <br />
                    </DialogContent>
                    <DialogActions>
                        <Button disabled={!this.state.canPressAdd} onClick={() => this.onPurchaseFree(999)} color="secondary">
                            Get Access
                        </Button>
                        <Button onClick={this.onClose} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                    </Fragment>
                    
                }
                {
                    exam.pricingModel === "FLAT_FEE" &&
                    <Fragment>
                    <DialogContent>
                    {
                        this.state.message &&
                        <Fragment>
                            <div style={{color: 'red'}}>{this.state.message}</div>
                            <br/>
                        </Fragment>
                    }
                        <p>The entry price for unlimited students for this exam is a flat fee of £{exam.flatFeePrice}.00. {exam.numFreePlaces>0 && `You may enter up to ${exam.numFreePlaces} students for free.`}</p>
                        <br />
                     <InputLabel id="PaymentLabel">Payment Method</InputLabel>
                   <Select
                        required
                        label="Payment Type"
                        value = {this.state.paymentType}
                        onChange = {(e) => this.handleFieldChange('paymentType', e)}
                    >
                        <MenuItem
                            key={"STRIPE"}
                            value={"STRIPE"}>
                              {"Card"}
                        </MenuItem>
                        {exam.invoicesAllowed && <MenuItem
                            key={"MANUAL"}
                            value={"MANUAL"}>
                              {"Invoice"}
                        </MenuItem>}
                    </Select>
                   
                    </DialogContent>
                    <DialogActions>
                        {
                            exam.numFreePlaces>0 && exam.numPlacesAvailable === 0 &&
                        <Button disabled={!this.state.canPressAdd} onClick={() => this.onPurchaseFree(exam.numFreePlaces)} color="secondary">
                            Free Places Only
                        </Button>
                        }
                        <Button disabled={!this.state.canPressAdd} onClick={() => this.onPurchaseFree(999)} color="secondary">
                            Purchase Access
                        </Button>
                        <Button onClick={this.onClose} color="secondary">
                            Cancel
                        </Button>
                    </DialogActions>
                    </Fragment>
                    
                }
                {
                    this.props.exam.pricingModel === "PER_STUDENT" &&
                    <Fragment>
                    <DialogContent>
                    {
                        this.state.message &&
                        <Fragment>
                            <div style={{color: 'red'}}>{this.state.message}</div>
                            <br/>
                        </Fragment>
                    }
                        <p>You currently have {exam.numPlacesAvailable - exam.numPlacesUsed} places for this exam.</p>
                        <p>The entry price for this exam is £{exam.perStudentPrice}.00 per student{exam.perStudentMaxPrice>0 && ` up to a maximum of £${exam.perStudentMaxPrice}.00`}. {exam.numFreePlaces>0 && `You may enter up to ${exam.numFreePlaces} students for free.`}</p>
                        <p>Please select the number of students to enrol in this exam.</p>
                        <TextField
                            label="Students"
                            type="number"
                            value = {this.state.n}
                            onChange = {this.onChangeNumber}
                            InputProps={{ inputProps: { min: 0, max: 100 } }}
                        />
                         <br />
                         <br />
                     <InputLabel id="PaymentLabel">Payment Method</InputLabel>
                   <Select
                        required
                        label="Payment Type"
                        value = {this.state.paymentType}
                        onChange = {(e) => this.handleFieldChange('paymentType', e)}
                    >
                        <MenuItem
                            key={"STRIPE"}
                            value={"STRIPE"}>
                              {"Card"}
                        </MenuItem>
                        {exam.invoicesAllowed && <MenuItem
                            key={"MANUAL"}
                            value={"MANUAL"}>
                              {"Invoice"}
                        </MenuItem>}
                    </Select>
                   
                
                <br />
                <br />
                <TextField
                            label="Subtotal"
                            disabled={true}
                            value = {`£`+subTotal(exam, this.state.n)+`.00`}
                        />
                </DialogContent>
                <DialogActions>
                <Button disabled={!this.state.canPressAdd} onClick={this.onPurchase} color="secondary">
                    Purchase
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
                </Fragment>
                }
            </Dialog>
            </div>
        );
    }

    handleFieldChange = (field:string, event: any) => {

        let newPayment = event.target.value
        this.setState(prevState => ({ ...prevState, [field]: newPayment }))
    }

    onChangeNumber = (event: any) => {

        let newNumber = parseInt(event.target.value)
        this.setState(prevState => ({ ...prevState, n: newNumber }))
    }

    onClose = () => {
        this.props.onClose()
    }

    onPurchase = async () => {
        this.setState(prevState => ({ ...prevState, canPressAdd: false }))
        const stripe = await this.loadStripe();
        let res = await request(`/api/teacher/exams/${this.props.exam.id}/purchase`, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({numStudents: this.state.n, paymentMethod: this.state.paymentType})
        })
        let res2 = res.clone()
        try{
            let {sessionId, free} = await res.json()
            if (free){
                this.props.onPurchase()
                return
            }
            const result = await stripe!.redirectToCheckout({

                sessionId
        
            });
            if (result.error) {

                // If `redirectToCheckout` fails due to a browser or network
        
                // error, display the localized error message to your customer
        
                // using `result.error.message`.
                this.setState((prevState) => ({ ...prevState, message: result.error.message! }))
            }
        }catch{
            let message = await res2.text()
            console.log(message)
            this.setState((prevState) => ({ ...prevState, message: message }))
        }finally{
            this.setState(prevState => ({ ...prevState, canPressAdd: true }))
        }
      
    }

    onPurchaseFree = async (n:number) => {
        this.setState(prevState => ({ ...prevState, n: n,canPressAdd: false }))
        const stripe = await this.loadStripe();
        let res = await request(`/api/teacher/exams/${this.props.exam.id}/purchase`, {
            method: 'POST',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify({numStudents: this.state.n, paymentMethod: this.state.paymentType})
        })
        let res2 = res.clone()
        try{
            let {sessionId, free} = await res.json()
            if (free){
                this.props.onPurchase()
                return
            }
            const result = await stripe!.redirectToCheckout({

                sessionId
        
            });


            if (result.error) {

                // If `redirectToCheckout` fails due to a browser or network
        
                // error, display the localized error message to your customer
        
                // using `result.error.message`.
                this.setState((prevState) => ({ ...prevState, message: result.error.message! }))
            }

        }catch{
            let message = await res2.text()
            console.log(message)
            this.setState((prevState) => ({ ...prevState, message: message }))
        }finally{
            this.setState(prevState => ({ ...prevState, canPressAdd: true }))
        }
      
    }
    
}

function subTotal(exam: Exam, numStudents: number) {
    let purchased = exam.numPlacesAvailable>0 ? true : false
    if (!purchased){
        if(numStudents < exam.numFreePlaces){
          return 0
        }
        numStudents -= exam.numFreePlaces
      }
      
    if(exam.pricingModel === "FLAT_FEE"){
        if(purchased){
          return "already purchased"
        }
        return exam.flatFeePrice
      }

    if(exam.pricingModel === "PER_STUDENT"){
    let additionalCost = numStudents*exam.perStudentPrice
    let perStudentMaxPrice = exam.perStudentMaxPrice
    if (perStudentMaxPrice === 0) perStudentMaxPrice = 999999999
    if (purchased){
        let paid = Math.min((exam.numPlacesAvailable - exam.numFreePlaces)*exam.perStudentPrice,exam.perStudentMaxPrice)
        let newTotal = paid + additionalCost
        if (newTotal > perStudentMaxPrice) return perStudentMaxPrice - paid
        else return additionalCost
        }
    else return Math.min(additionalCost, perStudentMaxPrice)
    }
}