
import { TextField } from "@material-ui/core"
import React from "react"
import { AnswerPart, AnswerPartOneWordAnswer } from "../../shared/AnswerSpec"
import { QuestionPartOneWordAnswer } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartOneWordAnswer
    answer:AnswerPartOneWordAnswer|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function OneWordAnswer(props:Props) {

    let { part, answer, onUpdate } = props

    return <div>
         <Content content={part.actionStatement} />
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <br/>
         <TextField value={answer ? answer.answer : ''} onChange={handleChange} />
    </div>

    function handleChange(e:any) {
        let text = e.target.value
        let truncatedText = text.substring(0,120)
        let newAnswer:AnswerPartOneWordAnswer =  {
            questionPartId: part.id,
            answer: truncatedText
        }

        onUpdate(newAnswer)
    }
}



