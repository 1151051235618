

import { Exam } from '../../../interfaces/teacher/Exam'
import React from 'react';
import { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import ExaminerContainer from '../ExaminerContainer';
import ExaminerPaymentsList from './ExaminerPaymentsList';

interface Props {
}

interface State {
}

export default class ExaminerPaymentsPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
    }
  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    return (
          <ExaminerContainer heading="Payments">
            <ExaminerPaymentsList/>
          </ExaminerContainer>
    )
  }

}

