import { Box, Paper } from "@material-ui/core";
import React from "react";
import { AnswerPart, AnswerPartCheckboxes, AnswerPartDragAndDrop, AnswerPartListDivideIntoGroups, AnswerPartListOrdering, AnswerPartMultipleTrueFalse, AnswerPartOneWordAnswer, AnswerPartRadioButtons } from "../../shared/AnswerSpec";
import { QuestionPart, QuestionPartCheckboxes, QuestionPartDragAndDrop, QuestionPartPlotFunction, QuestionPartListDivideIntoGroups, QuestionPartListOrdering, QuestionPartMultipleTrueFalse, QuestionPartOneWordAnswer, QuestionPartRadioButtons, QuestionPartStem, QuestionPartType, QuestionPartVideo } from "../../shared/QuestionSpec";
import Checkboxes from "./Checkboxes";
import DragAndDrop from "./DragAndDrop";
import ListDivideIntoGroups from "./ListDivideIntoGroups";
import ListOrdering from "./ListOrdering";
import MultipleTrueFalse from "./MultipleTrueFalse";
import OneWordAnswer from "./OneWordAnswer";
import RadioButtons from "./RadioButtons";
import Stem from './Stem'
import Video from "./Video";
import PlotFunction from "./PlotFunction";


interface Props {
    part: QuestionPart
    answerPart: AnswerPart|null
    studentCode: string
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

interface State {
}

export default class SittingQuestionPart extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
        }
    }

    render() {
        let { part, answerPart, onUpdate } = this.props

        var element

        switch(part.type) {

            case QuestionPartType.Stem:
                element = <Stem part={part as QuestionPartStem} />
                break

            case QuestionPartType.Video:
                element = <Video part={part as QuestionPartVideo} />
                break
                
            case QuestionPartType.PlotFunction:
                element = <PlotFunction part={part as QuestionPartPlotFunction} />
                break

            case QuestionPartType.Checkboxes:
                element = <Checkboxes part={part as QuestionPartCheckboxes} answer={answerPart as AnswerPartCheckboxes} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.DragAndDrop:
                element = <DragAndDrop part={part as QuestionPartDragAndDrop}  answer={answerPart as AnswerPartDragAndDrop} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.ListDivideIntoGroups:
                element = <ListDivideIntoGroups part={part as QuestionPartListDivideIntoGroups}  answer={answerPart as AnswerPartListDivideIntoGroups} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.ListOrdering:
                element = <ListOrdering part={part as QuestionPartListOrdering} answer={answerPart as AnswerPartListOrdering} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.MultipleTrueFalse:
                element = <MultipleTrueFalse part={part as QuestionPartMultipleTrueFalse} answer={answerPart as AnswerPartMultipleTrueFalse}  onUpdate={this.onUpdate} />
                break

            case QuestionPartType.OneWordAnswer:
                element = <OneWordAnswer part={part as QuestionPartOneWordAnswer} answer={answerPart as AnswerPartOneWordAnswer}  onUpdate={this.onUpdate} />
                break

            case QuestionPartType.RadioButtons:
                element = <RadioButtons part={part as QuestionPartRadioButtons} answer={answerPart as AnswerPartRadioButtons}  onUpdate={this.onUpdate} />
                break

            default:
                throw new Error('unknown question part type: ' + part.type)
        }

        return (
            <Box m={1}>
                <Paper>
                    <Box p={1.5}>
                        {element}
                        {/* <br/> */}
                        {/* <Grid container justify="flex-end">
                                <Button variant="outlined" color="secondary" onClick={this.onClickRemove}>Remove</Button>
                        </Grid> */}
                        <div id={`${part.id}watermark`} style={{color: "darkgrey", opacity: 0.2, fontStyle: "italic", userSelect: "none"}}>
                            {this.props.studentCode}
                        </div>
                    </Box>
                </Paper>
            </Box>
        )
    }

    onUpdate = (newPart:AnswerPart) => {
        this.props.onUpdate(newPart)
    }

}


