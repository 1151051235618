
import { Checkbox, Grid } from "@material-ui/core"
import React from "react"
import Content from "../../../../components/questions/Content"
import { Score } from "../../../../interfaces/examiner/Score"
import { AnswerPart, AnswerPartListDivideIntoGroups, AnswerPartListOrdering } from "../../../../shared/AnswerSpec"
import { QuestionPartListDivideIntoGroups, QuestionPartListOrdering } from "../../../../shared/QuestionSpec"

interface Props {
    part:QuestionPartListOrdering
    answers:AnswerPartListOrdering[]|null
    scores:Score[]
}

export default function ListDivideIntoGroupsScore(props:Props) {

    let { part,scores, answers} = props

    let totalScores = scores.filter((s: Score) => s.type === "PAPER")
    let totals = new Map()  
    for (let t of totalScores) { totals.set(t.examPaperSittingId, t) }
    let correctOrder = part.options.reduce(function(acc:any, obj: any){acc.push(obj.id); return acc;}, []).join()
    let map =  answers!.reduce(function(acc: any, obj: any) {
        let key = obj.ordering.join()
        acc[key] = (acc[key] || 0) + 1;
        return acc;
      }, {})
    let sortable = [];
    for (var key in map) {
        sortable.push([key, map[key],key.split(',')]);
    }
    sortable.sort(function(a, b) {
        return  b[1] - a[1];
    })
    let numberOfLists = sortable.length
   let notNeeded = sortable.splice(5)
   sortable.some(l => l[0] === correctOrder) ? console.log('contains true answer already') : sortable.push(notNeeded.filter(l=>l[0]===correctOrder)[0])
   sortable.forEach(answer => {
    let contentText: (string)[] = []
    answer[2].forEach((id: string) => {
        let questionContentText = part.options.filter(o => o.id == id)[0].content.text 
        contentText.push(questionContentText || 'no text')
    })
    answer[3] = contentText
    })
    console.log(sortable)
    return <div>
         <Content content={part.actionStatement} />
         <p>Avg score: {avgScore(scores.filter((s: Score) => s.questionPartId === part.id))}</p>
         <p>Number of list orders/Theoretical Max: {numberOfLists}/{factorial(part.options.length)} </p>
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <table>
         <tr>
                        <td>
                                    List
                        </td>
                        <td>
                               Count
                        </td>
                        <td>
                                %
                        </td>
                        <td>
                                Corr
                        </td>
                        <td>
                                Marks Given
                        </td>
                    </tr>
         {
             sortable.map((list) => {
                return(
                    <tr>
                        <td>
                                    {(correctOrder === list[0]) ? <strong>{list[3].join()}</strong> : list[3].join()}

                        </td>
                        <td>
                                {list[1]}
                        </td>
                        <td>
                                {formatPercentage(list[1]/answers!.length*100)}
                        </td>
                        <td>
                                {answers && answers.length > 0 ? corrScore(list[0], answers, totals) : "na"}
                        </td>
                        <td>
                                {answers && answers.length > 0 ?
                                getPartMark(list[0],answers,scores,part.id)
                                : "na"}
                        </td>  
                    </tr>
                 )
             })
         }
         </table>
    </div>

   
}

function avgScore(score: any) {
    var total = 0;
    for (var i = 0; i < score.length; i++) {
        total = total + parseFloat(score[i].score);
    }
    var avg = total / i
    return formatPercentage(avg);
}


function formatPercentage(s: any) {
    if (s === null || s === undefined || s === '') {
        return 'n/a'
    }
    let s2 = Math.round(s * 100) / 100
    return s2 + '%'
}

function pcorr(x: number[], y: number[]) {
    let sumX = 0,
        sumY = 0,
        sumXY = 0,
        sumX2 = 0,
        sumY2 = 0;
    const minLength = x.length = y.length = Math.min(x.length, y.length),
        reduce = (xi: number, idx: number) => {
            const yi = y[idx];
            sumX += xi;
            sumY += yi;
            sumXY += xi * yi;
            sumX2 += xi * xi;
            sumY2 += yi * yi;
        }
    x.forEach(reduce);
    return (minLength * sumXY - sumX * sumY) / Math.sqrt((minLength * sumX2 - sumX * sumX) * (minLength * sumY2 - sumY * sumY));
};

function corrScore(option: any, answers: any, scores: any) {
    var wrongRight = [];
    var score = [];
    for (var i = 0; i < answers.length; i++) {
        let id = answers[i].examPaperSittingId
        if (scores.get(id) && answers[i].ordering) {
            wrongRight.push(answers[i].ordering.join() === option ? 1 : 0)
            score.push(parseFloat(scores.get(id).score));
        }
    }
    console.log(wrongRight, score)
    var corr = pcorr(wrongRight, score)
    return corr.toFixed(2);
}

function factorial(x:number):number{
    return (x > 1) ? x * factorial(x-1) : 1
  }

  function getPartMark(option: any, answers: any, scores: Score[], qId:string) {
    let id = answers.filter((a:any)=> a.ordering.join()===option)[0].examPaperSittingId
    return formatPercentage(scores.filter(s=>s.examPaperSittingId===id && s.type==='PART' && s.questionPartId === qId )[0].score);
}

 