

import { Exam } from '../../../interfaces/examiner/Exam'
import React from 'react';
import ExaminerContainer from '../ExaminerContainer';
import ExaminerExamList from './ExaminerExamList';
import { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';

interface Props {
}

interface State {
  exams:Exam[]|null
  isSuperuser:boolean
}

export default class ExaminerExamsPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      exams: null,
      isSuperuser: false,
    }
  }

  async componentDidMount(){
    if(isLoggedIn('superuser')) {
      await this.setState(prevState => ({ ...prevState, isSuperuser: true }))
    }
  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }

    return (
          <ExaminerContainer heading="Exams">
            <ExaminerExamList isSuperuser = {this.state.isSuperuser}/>
        </ExaminerContainer>
    )
  }

}

