
import React, { Fragment } from "react";
import request from '../../../api';
import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem, InputLabel } from '@material-ui/core'
import { Exam } from "../../../interfaces/examiner/Exam";
import { loadStripe } from "@stripe/stripe-js";
import { Payment } from "../../../interfaces/teacher/Payment,";
import { Student } from "../../../interfaces/examiner/Student";

interface Props {
    onClose:()=>void
    onStart:(student:Student)=>void
    student:Student
}

interface State {
}

export default class StartSittingDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
        }
    }

    render() {
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Open Test Sitting For Student</DialogTitle>
                    <DialogContent>
                        <p>Are you sure you wish to open sittings for all papers for this student: {this.props.student.username}?</p>
                        <p>It will delete ALL exam data for this student.</p>             
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={this.onStart} color="secondary">
                            Yes
                        </Button>
                        <Button onClick={this.onClose} color="secondary">
                            No
                        </Button>
                    </DialogActions>                    
            </Dialog>
            </div>
        );
    }

    onStart= () => {
        this.props.onStart(this.props.student)
    }

    onClose = () => {
        this.props.onClose()
    }

}