
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem } from '@material-ui/core'
import { Exam } from "../../../interfaces/examiner/Exam";

interface Props {
    onEnrolTeacher:()=>void,
    onClose:()=>void,
    exam:Exam
}

interface State {
    canPressAdd: boolean
}

export default class EnrolTeacherDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            canPressAdd: true
        }
    }

    render() {
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Add exam</DialogTitle>
                <DialogContent>
                    <p>Would you like to add {this.props.exam.title} to this teacher?</p>
                </DialogContent>
                <DialogActions>
                <Button disabled={(!this.state.canPressAdd)} onClick={this.onEnrolTeacher} color="secondary">
                   Yes
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    onEnrolTeacher = () => {
        this.setState(prevState => ({ ...prevState, canPressAdd: false }))
        this.props.onEnrolTeacher()
    }
    
}