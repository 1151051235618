
import { Checkbox, Grid } from "@material-ui/core"
import React from "react"
import { AnswerPart, AnswerPartCheckboxes } from "../../shared/AnswerSpec"
import { QuestionPartCheckboxes } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartCheckboxes
    answer:AnswerPartCheckboxes|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function Checkboxes(props:Props) {

    let { part } = props

    return <div>
         <Content content={part.actionStatement} />
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <table>
         {
             part.options.map((option) => {
                let selected:boolean = props.answer !== null && props.answer.selectedOptionIds.indexOf(option.id) !== -1
                 return(
                    <tr>
                        <td>
                                    <Checkbox checked={selected} onClick={() => handleClickOption(option.id)} />

                        </td>
                        <td>
                                    <Content content={option.content} />
                        </td>
                    </tr>
                 )
             })
         }
         </table>
    </div>

    function handleClickOption(optionId:string) {
        console.log("hello")
        let ids = props.answer ? props.answer.selectedOptionIds.slice(0) : []

        if(ids.indexOf(optionId) !== -1) {
            ids = ids.filter(id => id !== optionId)
        } else {
            ids.push(optionId)
        }

        let answer:AnswerPartCheckboxes = {
            questionPartId: props.part.id,
            selectedOptionIds: ids
        }

        props.onUpdate(answer)

    }
}

