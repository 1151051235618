
import { Button, Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core"
import React, { useEffect, useState } from "react"
import { AnswerPart, AnswerPartMultipleTrueFalse } from "../../shared/AnswerSpec"
import { Content as ContentType } from "../../shared/QuestionSpec"
import { QuestionPartPlotFunction, QuestionPartMultipleTrueFalse } from "../../shared/QuestionSpec"
import Content from './Content'
import { VictoryAxis, VictoryChart, VictoryLabel, VictoryLine, VictoryScatter, VictoryTheme } from "victory"
import ReactDOM from "react-dom"
import uuid from "../../uuid"

interface Props {
    part: QuestionPartPlotFunction
}

interface variable {
    id: string
    name: ContentType
    value: number
}

interface linearVariable extends variable {
    coefficient: number
}

interface logisticVariables extends variable {
    L: number
    k: number
    x0: number
}

interface result {
    variables: CompoundType[]
    y: number
}

type CompoundType = (linearVariable | logisticVariables)



export default function PlotFunction(props: Props) {

    let { part } = props
    let [results, setResults] = useState<result[]>([]);
    let [studentVariables, setStudentVariables] = useState<(CompoundType[])>(() => {
        if (part.functionType === 'LINEAR') {
            return (part.linearVariables.map(
                (variable) => (
                    {
                        id: variable.id,
                        value: variable.defaultValue,
                        coefficient: variable.coefficient,
                        name: variable.name
                    }
                )
            ))
        }
        if (part.functionType === 'LOGISTIC' || part.functionType === 'MM') {
            return ([
                {
                    L: part.logisticVariables.L,
                    k:  part.logisticVariables.k,
                    x0: part.logisticVariables.x0,
                    name: part.logisticVariables.name,
                    id: part.logisticVariables.id,
                    value: part.logisticVariables.defaultValue
                }
            ])
        }
        return (part.linearVariables.map(
            (variable) => (
                {
                    id: variable.id,
                    value: variable.defaultValue,
                    coefficient: variable.coefficient,
                    name: variable.name
                }
            )
        ))
    }
    );

    let plotId = uuid()
    let [plotVar, setPlotVar] = useState<(string)>(studentVariables[0].id);
    let plotData: ({ x: number, y: number }[]) = []

    useEffect(() => {
        plotData = results.length > 0 ? results.map((r) => ({ x: r.variables.filter((v:(CompoundType)) => v.id === plotVar)[0].value, y: r.y })) : [];
        console.log(plotData)
        let minY = plotData.length > 0 ? plotData.reduce((min, p) => p.y < min ? p.y : min, plotData[0].y) : 0
        let maxY = plotData.length > 0 ? plotData.reduce((min, p) => p.y > min ? p.y : min, plotData[0].y) : 10
        let minX = plotData.length > 0 ? plotData.reduce((min, p) => p.x < min ? p.x : min, plotData[0].x) : 0
        let maxX = plotData.length > 0 ? plotData.reduce((min, p) => p.x > min ? p.x : min, plotData[0].x) : 10

        const chart = <VictoryChart domainPadding={10}
            theme={VictoryTheme.material}
            domain={{ x: [minX - 5, maxX + 5], y: [minY - 5, maxY + 5] }}
        >
            <VictoryScatter
                style={{ data: { fill: "#c43a32" } }}
                size={7}
                data={plotData}
            />
            <VictoryAxis
                axisLabelComponent={<VictoryLabel dy={24} />}
                label={studentVariables.filter(s => s.id === plotVar)[0].name.text!} />
            <VictoryAxis
                axisLabelComponent={<VictoryLabel dy={-24} />}
                dependentAxis
                label={part.y.text!} />
        </VictoryChart>

        part.plot && ReactDOM.unmountComponentAtNode(document.getElementById(plotId)!); //delete old
        part.plot && ReactDOM.render(chart, document.getElementById(plotId)); //set new

    }, [plotVar, results])

    return <div>
        <div>
            <Content content={part.content} />
        </div>
        <div>
            <h3> Experimental Set-up</h3>
            <table>
                <tr>
                    <td>
                        Variable Name

                    </td>
                    <td>
                        Variable Value
                    </td>
                </tr>
                {
                    studentVariables.map((variable) => {
                        return <tr>
                            <td>
                                <Content content={variable.name} />
                            </td>
                            <td>
                                <TextField type='number' value={variable.value} onChange={(e) => handleChange(variable, e)} />
                            </td>
                        </tr>
                    })
                }
            </table>
        </div>
        <div>
            <Button style={{ margin: 2 }} color="primary" variant="outlined" onClick={() => runExperiment()}>
                Run Experiment
            </Button>
            <Button style={{ margin: 2 }} color="primary" variant="outlined" onClick={() => clearResults()} disabled={results.length === 0}>
                Clear Results
            </Button>
            <h3>Results</h3>
            {
                results.length > 0 ? <table>
                    <tr>
                        <td>
                            ID
                        </td>
                        {results[0].variables.map(v => <td>
                            <Content content={v.name} />
                        </td>)}
                        <td>
                            <Content content={part.y} />
                        </td>
                    </tr>
                    {results.map((r, i) => <tr>
                        <td>{i + 1}</td>
                        {r.variables.map(v => <td>
                            {v.value}
                        </td>)}
                        <td>{r.y}</td>
                    </tr>)}
                </table> : ' No results yet'}
        </div>
        {part.plot && <div>
            <h3>
                Graph
            </h3>
            <InputLabel id="label">Independent Variable to Plot</InputLabel>
            <Select
                required
                label="Independent Variable to Plot"
                value={plotVar}
                onChange={(e) => handlePlotVarChange(e)}
            >
                {studentVariables.map(sv =>
                    <MenuItem
                        key={sv.id}
                        value={sv.id}>
                        {sv.name.text}
                    </MenuItem>)
                }
            </Select>
            <div id={plotId} style={{ width: "45%" }}>
            </div>
        </div>}
    </div>

    function handleChange(subject: any, e: any) {
        let newVariables = studentVariables.map(variable => {
            if (variable.id === subject.id) {
                return {
                    ...variable,
                    value: parseInt(e.target.value)
                }
            } else {
                return variable
            }
        })
        setStudentVariables(newVariables)
        console.log(newVariables)
    }

    function runExperiment() {
        let tempResult = 0
        if (part.functionType === "LINEAR") {
            for (let variable of studentVariables as linearVariable[]) {
                tempResult = tempResult + variable.coefficient * (variable.value+part.constantx)
            }
        }
        if (part.functionType === "LOGISTIC") {
            tempResult = part.logisticVariables.L / (1 + Math.exp(-part.logisticVariables.k * (studentVariables.filter(sv=>sv.id=='LOGVARS')[0].value - part.logisticVariables.x0 + part.constantx)))
        }
        if (part.functionType === "MM") {
            tempResult = (part.logisticVariables.L * (studentVariables.filter(sv=>sv.id=='LOGVARS')[0].value+part.constantx)) / (part.logisticVariables.x0 + (studentVariables.filter(sv=>sv.id=='LOGVARS')[0].value + part.constantx))
        }
        tempResult = Math.floor((tempResult + (Math.random()-0.5) * part.noise + part.constanty) * 100) / 100;
        let newResult: result = { variables: studentVariables, y: tempResult }
        setResults([...results, newResult]);
        console.log(results)

    }

    function clearResults() {

        setResults([]);
        console.log(results)
    }

    function handlePlotVarChange(e: any) {
        let newPlotVar = e.target.value
        setPlotVar(newPlotVar)
        console.log(newPlotVar)
    }



}

