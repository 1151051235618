import { Box, Button, Checkbox, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@material-ui/core"
import { Delete, List, ShortText } from "@material-ui/icons"
import React, { Fragment } from "react"
import { Question } from "../../../../../interfaces/shared/Question"
import { Content, QuestionPart, QuestionPartListDivideIntoGroups, QuestionPartListOrdering } from "../../../../../shared/QuestionSpec"
import uuid from "../../../../../uuid"
import ContentEditor from './ContentEditor'


interface Props {
    part: QuestionPartListDivideIntoGroups
    onUpdate: (oldPart: QuestionPart, newPart: QuestionPart) => void
}

export default class ListDivideIntoGroupsEditor extends React.Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let part = this.props.part

        return <Fragment>
            <div>
                <h3>Action Statement</h3>
                <ContentEditor subject={part} content={part.actionStatement} onUpdateContent={this.onUpdateActionStatement}/>
            </div>
            <h2>Groups</h2>
                {part.groups.map((group, i) => (
                    <Fragment>
                    <Paper>
                        <Box p={1.5}>
                            <h3>Group {i + 1} <Button onClick={() => this.onDeleteGroup(group.id)}><Delete /></Button> </h3>
                            <Paper style={{backgroundColor: '#fafafa'}}>
                                <Box p={1.5}>
                                    <h3><ShortText style={{verticalAlign: 'middle'}}/> Group Heading</h3>
                                    <div>
                                        <ContentEditor subject={group} content={group.heading} onUpdateContent={this.onUpdateGroupHeading} />
                                    </div>
                                </Box>
                            </Paper>
                            <br/>
                            <Paper style={{backgroundColor: '#fafafa'}}>
                                <Box p={1.5}>
                                    <h3><List style={{verticalAlign: 'middle'}}/> Correct Answers</h3>
                                    {
                                        part.options.filter((option) => option._correctGroupId === group.id).map((option, j) => (
                                            <Fragment>
                                            <Paper style={{ backgroundColor: '#efefef' }}>
                                                <Box p={1.5}>
                                                    <ContentEditor subject={option} content={option.content} onUpdateContent={this.onUpdateOption} />
                                                    <br/>
                                                    <br/>
                                                    <Button variant="outlined" onClick={() => this.onDeleteOption(option.id)} color="secondary">- Remove Answer</Button>
                                                </Box>
                                            </Paper>
                                            <br/>
                                            </Fragment>
                                        ))
                                    }
                                    <br/>
                                    <Button color="primary" variant="outlined" onClick={() => this.addOptionToGroup(group.id)}>
                                        + Add Answer
                                    </Button>
                                </Box>
                            </Paper>
                        </Box>
                    </Paper>
                    <br/>
                    </Fragment>
                ))}
                <br/>
                <Button color="primary" variant="outlined" onClick={this.addGroup}>
                    + Add Group
                </Button>
        </Fragment>
    }

    onUpdateGroupHeading = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            groups: part.groups.map(group => {
                if (group.id === subject.id) {
                    return {
                        ...group,
                        heading: newContent
                    }
                } else {
                    return group
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }
    
    onUpdateOption = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            options: part.options.map(option => {
                if (option.id === subject.id) {
                    return {
                        ...option,
                        content: newContent
                    }
                } else {
                    return option
                }
            })
        }

        this.props.onUpdate(part, newPart)
    }

    addGroup = () => {

        let part = this.props.part

        let newPart = {
            ...part,
            groups: part.groups.concat([
                {
                    id: uuid(),
                    heading: { text: null, image: null }
                }
            ])
        }

        this.props.onUpdate(part, newPart)
    }

    onDeleteGroup = (groupId:string) => {

        let part = this.props.part

        let newPart = {
            ...part,
            groups: part.groups.filter(g => g.id !== groupId)
        }

        this.props.onUpdate(part, newPart)
    }

    addOptionToGroup(groupId:string) {

        let part = this.props.part

        let newPart = {
            ...part,
            options: part.options.concat([
                {
                    id: uuid(),
                    content: { text: null, image: null },
                    _correctGroupId: groupId
                }
            ])
        }

        this.props.onUpdate(part, newPart)
    }

    onDeleteOption(optionId:string) {
        let part = this.props.part

        let newPart = {
            ...part,
            options: part.options.filter(opt => opt.id !== optionId)
        }

        this.props.onUpdate(part, newPart)
    }
    
    onUpdateActionStatement = (subject: any, newContent: Content) => {

        let part = this.props.part

        let newPart = {
            ...part,
            actionStatement: newContent
        }

        this.props.onUpdate(part, newPart)
    }
}

