import { Box, Paper } from "@material-ui/core";
import React from "react";
import Stem from "../../../../components/questions/Stem";
import Video from "../../../../components/questions/Video";
import { Score } from "../../../../interfaces/examiner/Score";
import { AnswerPart, AnswerPartCheckboxes, AnswerPartDragAndDrop, AnswerPartListDivideIntoGroups, AnswerPartListOrdering, AnswerPartMultipleTrueFalse, AnswerPartOneWordAnswer, AnswerPartRadioButtons } from "../../../../shared/AnswerSpec";
import { QuestionPart, QuestionPartCheckboxes, QuestionPartDragAndDrop, QuestionPartListDivideIntoGroups, QuestionPartListOrdering, QuestionPartMultipleTrueFalse, QuestionPartOneWordAnswer, QuestionPartRadioButtons, QuestionPartStem, QuestionPartType, QuestionPartVideo } from "../../../../shared/QuestionSpec";
import CheckboxesScore from "./CheckboxesScore";
import ListDivideIntoGroupsScore from "./ListDivideIntoGroupsScore";
import ListOrderingScore from "./ListOrderingScore";
import MultipleTrueFalseScore from "./MultipleTrueFalseScore";
import OneWordAnswerScore from "./OneWordAnswerScore";
import RadioButtonsScore from "./RadioButtonsScore";


interface Props {
    part: QuestionPart
    answerParts: AnswerPart[]
    scores: Score[]
}

interface State {
}

export default class SittingQuestionPartScore extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
        }
    }

    render() {
        let { part, answerParts, scores } = this.props
        console.log("answerParts",answerParts)
        var element

        switch(part.type) {

            case QuestionPartType.Stem:
                element = <Stem part={part as QuestionPartStem} />
                break

            case QuestionPartType.Video:
                element = <Video part={part as QuestionPartVideo} />
                break

            // case QuestionPartType.PlotFunction:
            //     element = <PlotFunction part={part as QuestionPartPlotFunction} />
            //     break

            case QuestionPartType.Checkboxes:
                element = <CheckboxesScore part={part as QuestionPartCheckboxes} answers={answerParts as AnswerPartCheckboxes[]} scores={scores} />
                break

            // case QuestionPartType.DragAndDrop:
            //     element = <DragAndDrop part={part as QuestionPartDragAndDrop}  answer={answerPart as AnswerPartDragAndDrop} onUpdate={this.onUpdate} />
            //     break

            case QuestionPartType.ListDivideIntoGroups:
                element = <ListDivideIntoGroupsScore part={part as QuestionPartListDivideIntoGroups}  answers={answerParts as AnswerPartListDivideIntoGroups[]} scores={scores} />
                break

            case QuestionPartType.ListOrdering:
                element = <ListOrderingScore part={part as QuestionPartListOrdering} answers={answerParts as AnswerPartListOrdering[]} scores={scores} />
                break

            case QuestionPartType.MultipleTrueFalse:
                element = <MultipleTrueFalseScore part={part as QuestionPartMultipleTrueFalse} answers={answerParts as AnswerPartMultipleTrueFalse[]}  scores={scores} />
                break

            case QuestionPartType.OneWordAnswer:
                element = <OneWordAnswerScore part={part as QuestionPartOneWordAnswer} answers={answerParts as AnswerPartOneWordAnswer[]}  scores={scores} />
                break

            case QuestionPartType.RadioButtons:
                element = <RadioButtonsScore part={part as QuestionPartRadioButtons} answers={answerParts as AnswerPartRadioButtons[]}  scores={scores} />
                break

            default:
                element = <p>Part type not implemented (yet!)</p>
                // throw new Error('unknown question part type: ' + part.type)
        }

        return (
            <Box m={1}>
                <Paper>
                    <Box p={1.5}>
                        {element}
                        {/* <br/> */}
                        {/* <Grid container justify="flex-end">
                                <Button variant="outlined" color="secondary" onClick={this.onClickRemove}>Remove</Button>
                        </Grid> */}
                    </Box>
                </Paper>
            </Box>
        )
    }

}


