
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage, ShoppingCart, People, GetApp, Description, Cancel } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Exam } from '../../../interfaces/teacher/Exam';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import { Payment } from '../../../interfaces/teacher/Payment,';
import { privateDecrypt } from 'crypto';
import { Packer } from 'docx';
import FileSaver from 'file-saver';
import CancelPaymentDialog from '../../examiner/payments/CancelPaymentDialog';

interface Props {
}

interface State {
  data:Exam[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc'
}

export default class TeacherPaymentsList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'created',
      sortOrder:'desc',
    }
  }

   columns: any[] = [
          {
              name: 'Reference',
              selector: 'reference',
              sortable: true,
              wrap: true
          },
         {
            name: 'Created',
            selector: 'created',
            sortable: true,
            cell: (payment: Payment) => new Date(payment.created).toLocaleDateString()
        },
        {
            name: 'Exam Title',
            selector: 'exam',
            sortable: true,
            wrap: true,
        },
        {
          name: 'Cost',
          selector: 'price',
          sortable: true,
          cell: (payment: Payment) => "£" + payment.price.toString() +  ".00"
        },
        {
          name: 'Number of Students',
          selector: 'numStudents',
          sortable: true
        },
        {
          name: 'Payment Method',
          selector: 'paymentMethod',
          sortable: true,
          cell: (payment: Payment) => {
            if (payment.paymentMethod === "STRIPE"){
              return "Card"
            }
            if (payment.paymentMethod === "MANUAL"){
              return "Invoice"
            }
            if (payment.paymentMethod === "FREE"){
              return "Free"
            }
            else return "NA"
          }
      },
      {
          name: 'Paid',
          selector: 'paid',
          sortable: true,
          cell: (payment: Payment) => payment.paid ? new Date(payment.paidDate).toLocaleDateString() : (payment.cancelled ? "Cancelled" : "Awaiting confirmation")
      },
      {
        name: 'Download',
        cell: (payment: Payment) => <Fragment>
          {
            !payment.cancelled && <Fragment>
          
            {
              !payment.paid && payment.paymentMethod == "MANUAL" &&
                    <Fragment>
                        <Button onClick={() => this.downloadInvoice(payment)}><Description />  Invoice </Button>
                    </Fragment>
           }
           {
              payment.paid &&
                    <Fragment>
                        <Button onClick={() => this.downloadReceipt(payment)}><Description />  Receipt</Button>
                    </Fragment>
            }
            </Fragment>
      }
        </Fragment>
      },
    ]

  render() {

    let { data, totalRows, rowsPerPage, page } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
           <DataTable
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder } = this.state

    let { payments, total } = await (
        await request(`/api/teacher/payments?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: payments, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

  downloadInvoice= async(payment:Payment) => {
    let r = await request(`/api/teacher/payments/${payment.id}/getInvoice`)

    let results = await r.arrayBuffer()

    var blob = new Blob([results], { type: 'application/pdf' })
    FileSaver.saveAs(blob, `${payment.reference}_INVOICE.pdf`)
  }

  downloadReceipt= async(payment:Payment) => {
    let r = await request(`/api/teacher/payments/${payment.id}/getReceipt`)

    let results = await r.arrayBuffer()

    var blob = new Blob([results], { type: 'application/pdf' })
    FileSaver.saveAs(blob, `${payment.reference}_RECEIPT.pdf`)
  }

}