
import React from "react"
import { QuestionPartStem } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartStem
}

export default function Stem(props:Props) {

    let { part } = props

    return <Content content={part.content} />
}

