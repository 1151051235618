
import React from "react"
import { AnswerPart, AnswerPartListDivideIntoGroups } from "../../shared/AnswerSpec"
import { QuestionPartListDivideIntoGroups } from "../../shared/QuestionSpec"
import Content from './Content'
import DragDrop, { List } from "./helpers/DragDrop"

interface Props {
    part:QuestionPartListDivideIntoGroups
    answer:AnswerPartListDivideIntoGroups|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function ListDivideIntoGroups(props:Props) {

    let { part, answer, onUpdate } = props

    let unsorted = part.options.filter(opt => !answer || !answer.optionToGroup.filter(a => a.optionId === opt.id)[0])

    let groupLists: List[] = part.groups.map(g => {
        return {
            id: g.id,
            heading: <p><Content content={g.heading} /></p>,
            entries: answer ? answer!.optionToGroup.filter(a => a.group === g.id).map(opt => {
                return {
                    id: opt.optionId,
                    content: <Content content={part.options.filter(o => o.id === opt.optionId)[0].content} />
                }
            }) : []
        }
    })

    let lists:List[] = [
        {
            heading: <p  style={{whiteSpace: 'pre-line', fontSize: '1.1rem', fontFamily: 'Lucida Sans', padding: `0 5px` }}>Unsorted</p>,
            entries: unsorted.map(opt => {
                return {
                    id: opt.id,
                    content: <Content content={opt.content} />
                }
            })
        } as List
    ].concat(groupLists)

    return <div>
         <Content content={part.actionStatement} />
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <DragDrop lists={lists} onChange={handleChange} />
    </div>

    function handleChange(lists:any[]) {

        let newAnswer:AnswerPartListDivideIntoGroups = { questionPartId: part.id, optionToGroup: [] }

        for(let list of lists) {
            if(list.id) {
                for(let item of list.entries) {
                    newAnswer.optionToGroup.push({ optionId: item.id, group: list.id })
                }
            }
        }

        onUpdate(newAnswer)
    }
}
