
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, Select, MenuItem } from '@material-ui/core'
import { Question } from "../../../../interfaces/shared/Question";
import { QuestionPartType } from '../../../../shared/QuestionSpec'
import SittingQuestion from "../../../../components/questions/SittingQuestion";
import { AnswerSpec } from "../../../../shared/AnswerSpec";

interface Props {
    question:Question,
    onClose:()=>void
}

interface State {
    answer:AnswerSpec|null,
}

export default class TestQuestionDialog extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            answer: null
        }
    }

    render() {

        let { question } = this.props
        let { answer } = this.state

        return (
            <div>
            <Dialog fullScreen open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <div style={{ width: "70%", margin: "0 auto" }}>
                    <DialogTitle id="form-dialog-title">{question.description}</DialogTitle>
                    <DialogContent>
                        <SittingQuestion studentCode={`TEST123`} question={question} answer={answer} onUpdateAnswer={this.onUpdateAnswer} />
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={this.onClose} color="secondary">
                        Close Question Preview
                    </Button>
                    </DialogActions>
                </div>
            </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    onUpdateAnswer = (newAnswer:AnswerSpec)=> {

        this.setState({ answer: newAnswer })
    }
}


