import sizeOf from "buffer-image-size";
import { AlignmentType, Document, HeadingLevel, Media, Packer, Paragraph, TabStopPosition, TabStopType, TextRun, PageBreak, Header, Footer, ImageRun } from "docx";
import { optionCSS } from "react-select/src/components/Option";
import { getConfigSync } from "../../../api";
import { Paper } from "../../../interfaces/shared/Paper";
import { Content, QuestionPart, QuestionPartCheckboxes, QuestionPartListDivideIntoGroups, QuestionPartListOrdering, QuestionPartMultipleTrueFalse, QuestionPartOneWordAnswer, QuestionPartRadioButtons, QuestionPartStem, QuestionPartType } from "../../../shared/QuestionSpec";

export class DocumentCreator2 {
    // tslint:disable-next-line: typedef
    public async create(paper:Paper): Promise<Document> {
        let COMPANY = getConfigSync().invoiceCompany
        let WEBSITE = getConfigSync().invoiceWebsite
        const document = new Document({
            sections: [{
                headers: {
                    default: new Header({
                        children: [new Paragraph(`Copyright 1996 - ${new Date().getFullYear()} ${COMPANY}`)],
                    }),
                },
                footers: {
                    default: new Footer({
                        children: [
                            new Paragraph(WEBSITE),
                            new Paragraph(`${COMPANY} Registered Charity No. 1191037`),
                        ],
                    }),
                },
                children: [
                    new Paragraph({
                        text: paper.title + " Mark Scheme",
                        heading: HeadingLevel.TITLE,
                    }),
                    this.createHeading(`Duration: ${paper.duration.toString()} minutes`),
                    this.createHeading(`Total marks: ${this.countMarks(paper).toString()}`),
                    ... await (await Promise.all(paper.questions
                        .map(async (question: any, i: number) => {
                            const arr: Paragraph[] = [];
                            arr.push(
                                this.createQuestionHeader(i + 1)
                            );
                            let j = 0;
                            for (let part of question.questionSpec.parts){
                                arr.push(
                                    new Paragraph({
                                        text: `Part ${++j} of ${question.questionSpec.parts.length}`,
                                        heading: HeadingLevel.HEADING_3,
                                        spacing: {
                                            before: 200,
                                        },
                                    })
                                );
                                arr.push.apply(arr, await this.displayQuestionPart(part));
                            }
                            arr.push(
                                new Paragraph({
                                    children: [new PageBreak()],
                                })
                            );
                            console.log(arr);
                            return arr;
                        })))
                        .reduce((prev:any, curr:any) => prev.concat(curr), []),
                ],
            }
            ],})




        return document;
    }

    public createHeading(text: string): Paragraph {
        return new Paragraph({
            text: text,
            heading: HeadingLevel.HEADING_1,
            thematicBreak: true,
        });
    }

    public createQuestionHeader(i:number): Paragraph {
        return new Paragraph({
            heading: HeadingLevel.HEADING_2,
            children: [
                new TextRun({
                    text: "Question ",
                }),
                new TextRun({
                    text: `${i.toString()}`,
                }),
            ],
        });
    }

    public displayQuestionPart(part: QuestionPart): any {
        switch (part.type) {

            case QuestionPartType.Checkboxes:
                return this.displayCheckboxes(part as QuestionPartCheckboxes)
            case QuestionPartType.ListDivideIntoGroups:
                return this.displayListDivideIntoGroups(part as QuestionPartListDivideIntoGroups)
            case QuestionPartType.ListOrdering:
                return this.displayListOrdering(part as QuestionPartListOrdering)
            case QuestionPartType.MultipleTrueFalse:
                return this.displayMultipleTrueFalse(part as QuestionPartMultipleTrueFalse)
            case QuestionPartType.OneWordAnswer:
                return this.displayOneWordAnswer(part as QuestionPartOneWordAnswer)
            case QuestionPartType.RadioButtons:
                return this.displayRadio(part as QuestionPartRadioButtons)
            case QuestionPartType.Stem:
                return this.displayStem(part as QuestionPartStem)
  
            case QuestionPartType.DragAndDrop:
                return
            case QuestionPartType.Video:
                return
            case QuestionPartType.PlotFunction:
                return 
            default:
                return
        }
    }

    public async displayStem(questionPart:QuestionPartStem) {
        const arr: Paragraph[] = [];
        if (questionPart.content.text){
            arr.push.apply(arr,await this.displayContent(questionPart.content))
        }
        return(arr)    
    }

    public async displayCheckboxes(questionPart:QuestionPartCheckboxes) {
        const arr: Paragraph[] = [];
        if (questionPart.actionStatement){
            arr.push.apply(arr,await this.displayContent(questionPart.actionStatement))
        }
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: (questionPart.marks > 1) ? `${questionPart.marks} marks` : `${questionPart.marks} mark`,
                        bold: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Choose as many as appropriate`,
                        italics: true,
                    }),
                ],
            })
        );
        await Promise.all(questionPart.options.map(async (option, i:number) => {
            arr.push.apply(arr,
                await this.displayContent(option.content, i + 1, option._isCorrect),
            );
        }))
        console.log(arr)
        return(arr)
    }

    public async displayOneWordAnswer(questionPart:QuestionPartOneWordAnswer) {
        const arr: Paragraph[] = [];
        if (questionPart.actionStatement){
            arr.push.apply(arr,await this.displayContent(questionPart.actionStatement))
        }
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: (questionPart.marks > 1) ? `${questionPart.marks} marks` : `${questionPart.marks} mark`,
                        bold: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Write something below`,
                        italics: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: questionPart._correctAnswers[0] ? questionPart._correctAnswers[0].text : "No correct answers",
                    }),
                ],
            })
        );
        console.log(arr)
        return(arr)
    }

    public async displayRadio(questionPart:QuestionPartRadioButtons) {
        const arr: Paragraph[] = [];
        if (questionPart.actionStatement){
            arr.push.apply(arr,await this.displayContent(questionPart.actionStatement))
        }
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: (questionPart.marks > 1) ? `${questionPart.marks} marks` : `${questionPart.marks} mark`,
                        bold: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Choose ONE`,
                        italics: true,
                    }),
                ],
            })
        );
        await Promise.all(questionPart.options.map(async (option, i:number) => {
            arr.push.apply(arr,
                await this.displayContent(option.content, i + 1, option._isCorrect),
            );
        }))
        console.log(arr)
        return(arr)
    }

    
    public async displayMultipleTrueFalse(questionPart:QuestionPartMultipleTrueFalse) {
        const arr: Paragraph[] = [];
        if (questionPart.actionStatement){
            arr.push.apply(arr,await this.displayContent(questionPart.actionStatement))
        }
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: (questionPart.marks > 1) ? `${questionPart.marks} marks` : `${questionPart.marks} mark`,
                        bold: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Mark the following as TRUE or FALSE`,
                        italics: true,
                    }),
                ],
            })
        );
        await Promise.all(questionPart.questions.map(async (option, i:number) => {
            arr.push.apply(arr,
                await this.displayContent(option.content, i + 1),
            );
            arr.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: option._answer.toString().toUpperCase(),
                            bold:true,
                        }),
                    ],
                }))
        }))
        console.log(arr)
        return(arr)
    }

    public async displayListOrdering(questionPart:QuestionPartListOrdering) {
        const arr: Paragraph[] = [];
        if (questionPart.actionStatement){
            arr.push.apply(arr,await this.displayContent(questionPart.actionStatement))
        }
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: (questionPart.marks > 1) ? `${questionPart.marks} marks` : `${questionPart.marks} mark`,
                        bold: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Put into the correct order`,
                        italics: true,
                    }),
                ],
            })
        );
        await Promise.all(questionPart.options.map(async (option:any, i:number) => {
            arr.push.apply(arr,
                await this.displayContent(option.content, i + 1),
            );
        }))
        console.log(arr)
        return(arr)
    }

    public async displayListDivideIntoGroups(questionPart:QuestionPartListDivideIntoGroups) {
        const arr: Paragraph[] = [];
        if (questionPart.actionStatement){
            arr.push.apply(arr,await this.displayContent(questionPart.actionStatement))
        }
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: (questionPart.marks > 1) ? `${questionPart.marks} marks` : `${questionPart.marks} mark`,
                        bold: true,
                    }),
                ],
            })
        );
        arr.push(
            new Paragraph({
                children: [
                    new TextRun({
                        text: `Sort into groups`,
                        italics: true,
                    }),
                ],
            })
        );
        for(let group of questionPart.groups) {
            let one = await this.displayContent(group.heading, undefined, true);
            arr.push.apply(arr,
                one
            );
            let two = await Promise.all(questionPart.options.filter(option => option._correctGroupId === group.id).map
            (async (option:any, i:number) => {
                    return await this.displayContent(option.content);
            }))
            for (let t of two){
            arr.push.apply(arr,
                t
            );
            }
        }
        console.log(arr)
        return(arr)
    }

    public async displayContent(content:Content, label?:number, isBold?:boolean) {
        const arr: Paragraph[] = [];
        if (content.text){
                if (label){
                arr.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: `${String.fromCharCode(96+label)}) \t ${content.text}`,
                            bold: isBold
                        }),
                    ],
                    spacing: {
                        after: 200,
                    },
                }))
            }
            if (!label){
                arr.push(
                new Paragraph({
                    children: [
                        new TextRun({
                            text: content.text,
                            bold: isBold
                        }),
                    ],
                    spacing: {
                        after: 200,
                    },
                }))
            }
        }
        if (content.image){
            arr.push(
            new Paragraph({
                children: [
                    await makeImageRun(content.image, content.imageSize),
                new TextRun({
                    text:"",
                    break:1,
                }),
                new TextRun({
                    text: content.imageAlt||''
                }),
                ],
            }))
        }
        return(arr)
    }  
    
    public countMarks(paper:Paper){
        let marks = 0
        for (let question of paper.questions){
            for (let part of question.questionSpec.parts){
                marks += part.marks
            }
        }
        return marks
     }

    // https://stackoverflow.com/a/12646864/712294
    public shuffleArray(array:{id:string,content:Content}[]) {
    for (let i = array.length - 1; i > 0; i--) {
        const j = Math.floor(Math.random() * (i + 1));
        [array[i], array[j]] = [array[j], array[i]];
    }
    return array
}
    
}  

function get(url:string) {
    return new Promise<ArrayBuffer>((accept, reject) => {
        var req = new XMLHttpRequest();
        req.open("GET", url, true);
        req.responseType = "arraybuffer";

        req.onload = function(event) {
            var resp:ArrayBuffer = req.response;
            if(resp) {
                accept(resp);
            }
        };

        req.send(null);
    });
}

async function makeImageRun(imageUrl:string, imageSize?:number):Promise<ImageRun>{
    let image = await get(getConfigSync().apiUrl + "/api/images/" + imageUrl)
    var dimensions = sizeOf(new Buffer(image));
    return new ImageRun({
        data: Buffer.from(image),
        transformation: {
            width: imageSize||350,
            height: (imageSize||350)/dimensions.width * dimensions.height,
        },
        });
    
}

function imageStream(imageStream: any): import("docx").ParagraphChild {
    throw new Error("Function not implemented.");
}
