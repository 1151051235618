

import { Exam } from '../../../interfaces/teacher/Exam'
import React from 'react';
import TeacherContainer from '../TeacherContainer';
import TeacherExamsList from './TeacherExamsList';
import { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';

interface Props {
}

interface State {
  exams:Exam[]|null
}

export default class TeacherExamsPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      exams: null
    }
  }

  render() {

    if(!isLoggedIn('teacher')) {
        return <Redirect to='/login' />
    }


    return (
          <TeacherContainer heading="Exams">
            <TeacherExamsList/>
          </TeacherContainer>
    )
  }

}

