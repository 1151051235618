
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle, FormControlLabel, Checkbox } from '@material-ui/core'
import { Paper } from "../../../interfaces/shared/Paper";
import ExaminerPaperList from "../papers/ExaminerPaperList";
import { Examiner } from "../../../interfaces/examiner/Examiner";

interface Props {
    examiner:Examiner
    onEdit:(examiner:Examiner) => void
    onClose:() => void
    isSuperuser:boolean
}

interface State {
    examiner:Examiner
}

export default class EditExaminerDialog  extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)

        this.state = {
            examiner:this.props.examiner
          }
    }

    render() {
        let canPressAdd = this.state.examiner.password ? this.state.examiner.password.length>=12 : true
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{`Edit ${this.state.examiner.username}`}</DialogTitle>
                <DialogContent>
                <TextField
                            margin="dense"
                            label="Password"
                            fullWidth
                            variant="filled"
                            name="password"
                            defaultValue={this.state.examiner.password}
                            onChange={this.handleChange}
                            helperText="Passwords must be >=12 characters"
                />
                {this.props.isSuperuser && <FormControlLabel
                    control={
                        <Checkbox checked={this.state.examiner.superuser} onClick={() => this.handletoggleSuperuser()} />}
                    label="Is superuser?"
                />}
                </DialogContent>
                <DialogActions>
                <Button disabled = {!canPressAdd} onClick={this.onEdit} color="secondary">
                    Edit
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    onEdit = () => {
        this.props.onEdit(this.state.examiner)
    }

    handleChange = async (ev: any) => {

        let examiner = this.state.examiner as Examiner
        let newExaminer:Examiner = { ...examiner, [ev.target.name]: ev.target.value }
        await this.setState(prevState => ({ ...prevState, examiner: newExaminer }))
    }

    handletoggleSuperuser = async () => {
        let examiner = this.state.examiner as Examiner
        let newExaminer:Examiner = { ...examiner, superuser: !examiner.superuser }
        await this.setState(prevState => ({ ...prevState, examiner: newExaminer }))
      }
    
}