
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Table, Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage, ShoppingCart, People, GetApp, Description, Cancel, Done } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Exam } from '../../../interfaces/teacher/Exam';
import DataTable from 'react-data-table-component';
import request, { getConfigSync } from '../../../api';
import { Payment } from '../../../interfaces/teacher/Payment,';
import { privateDecrypt } from 'crypto';
import { Packer } from 'docx';
import FileSaver from 'file-saver';
import CancelPaymentDialog from './CancelPaymentDialog';
import ConfirmPaymentDialog from './ConfirmPaymentDialog';

interface Props {
}

interface State {
  data: Exam[] | null
  totalRows: number
  rowsPerPage: number
  page: number
  sortColumn: string
  sortOrder: 'asc' | 'desc'
  showCancelDialog: Payment | null
  showConfirmDialog: Payment | null
  searchQuery: string
  unpaidOnly: boolean
}

export default class ExaminerPaymentsList extends React.Component<Props, State> {

  constructor(props: Props) {

    super(props)

    this.state = {
      data: [],
      totalRows: 0,
      rowsPerPage: 10,
      page: 1,
      sortColumn: 'created',
      sortOrder: 'desc',
      showCancelDialog: null,
      showConfirmDialog: null,
      searchQuery: getConfigSync().competitionShortName,
      unpaidOnly: false,
    }
  }

  columns: any[] = [
    {
      name: 'Reference',
      selector: 'reference',
      sortable: true,
      wrap: true
    },
    {
      name: 'Created',
      selector: 'created',
      sortable: true,
      cell: (payment: Payment) => new Date(payment.created).toLocaleDateString()
    },
    {
      name: 'Exam',
      selector: 'exam',
      sortable: false,
      wrap: true,
    },
    {
      name: 'School',
      sortable: false,
      wrap: true,
      cell: (payment: Payment) => payment.teacher.school
    },
    {
      name: 'Cost',
      selector: 'price',
      sortable: true,
      cell: (payment: Payment) => "£" + payment.price.toString() + ".00"
    },
    {
      name: 'Number of Students',
      selector: 'numStudents',
      sortable: true
    },
    {
      name: 'Method',
      selector: 'paymentMethod',
      sortable: true,
      cell: (payment: Payment) => {
        if (payment.paymentMethod === "STRIPE") {
          return "Card"
        }
        if (payment.paymentMethod === "MANUAL") {
          return "Invoice"
        }
        if (payment.paymentMethod === "FREE") {
          return "Free"
        }
        else return "NA"
      }
    },
    {
      name: 'Paid',
      selector: 'paid',
      sortable: true,
      cell: (payment: Payment) => payment.paid ? new Date(payment.paidDate).toLocaleDateString() : (payment.cancelled ? "Cancelled" : "Awaiting confirmation")
    },
    {
      name: 'Download',
      cell: (payment: Payment) => <Fragment>
        {
          !payment.cancelled && <Fragment>

            {
              !payment.paid && payment.paymentMethod == "MANUAL" &&
              <Fragment>
                <Button onClick={() => this.downloadInvoice(payment)}><Description /> </Button>
              </Fragment>
            }
            {
              payment.paid &&
              <Fragment>
                <Button onClick={() => this.downloadReceipt(payment)}><Receipt /></Button>
              </Fragment>
            }
          </Fragment>
        }
      </Fragment>
    },
    {
      name: 'Manual Payment',
      cell: (payment: Payment) => payment.paymentMethod === "MANUAL" && <Fragment>
        {
          !payment.cancelled && !payment.paid && <Fragment>
            <Button onClick={() => this.confirmPayment(payment)}><Done /></Button>
          </Fragment>
        }
        {
          !payment.cancelled && !payment.paid && <Fragment>
            <Button onClick={() => this.cancelPayment(payment)}><Cancel /></Button>
          </Fragment>
        }
      </Fragment>
    }
  ]

  render() {

    let { data, totalRows, rowsPerPage, page, unpaidOnly, searchQuery } = this.state

    if (data === null) {
      return <div>Loading...</div>
    }

    return (
      <Fragment>
        {this.state.showCancelDialog && <CancelPaymentDialog payment={this.state.showCancelDialog} onAccept={this.onAccept} onClose={this.onClose} />}

        {this.state.showConfirmDialog && <ConfirmPaymentDialog payment={this.state.showConfirmDialog} onAccept={this.onAccept2} onClose={this.onClose2} />}
        <div style={{display: "flex"}}> 
        <TextField margin="dense"
          id="searchQuery" label="Search..."
          defaultValue={searchQuery}
          onChange={(e) => this.handleFieldChange(e.target.value)}
        />
        <FormControlLabel
          control={
            <Checkbox checked={unpaidOnly} onClick={() => this.toggleUnpaidOnly()} />
          }
          label="Unpaid only"
        />
        <Button color="primary" variant="outlined" onClick={this.downloadPayments} style={{marginLeft: "auto"}}>
          Download all
          </Button>
          </div>
        <br />
        <DataTable
          columns={this.columns}
          data={data}
          pagination
          paginationServer
          paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
          sortServer
          onSort={this.handleSort}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
          onChangePage={this.handleChangePage}
          noHeader
        />
      </Fragment>
    )
  }

  componentDidMount() {
    this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder, unpaidOnly, searchQuery } = this.state

    let { payments, total } = await (
      await request(`/api/examiner/payments?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&searchQuery=${searchQuery}&unpaidOnly=${unpaidOnly}`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: payments, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage: number) => {
    await this.setState(prevState => ({ ...prevState, rowsPerPage }))
    this.reload()
  }

  handleChangePage = async (page: number) => {
    await this.setState(prevState => ({ ...prevState, page }))
    this.reload()
  }

  handleSort = async (column: any, sortDirection: any) => {
    await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
    this.reload()
  }

  downloadInvoice = async (payment: Payment) => {
    let r = await request(`/api/examiner/payments/${payment.id}/getInvoiceWord`)

    let results = await r.arrayBuffer()

    var blob = new Blob([results], { type: 'application/docx' })
    FileSaver.saveAs(blob, `${payment.reference}_INVOICE.docx`)
  }

  downloadReceipt = async (payment: Payment) => {
    let r = await request(`/api/examiner/payments/${payment.id}/getReceiptWord`)

    let results = await r.arrayBuffer()

    var blob = new Blob([results], { type: 'application/docx' })
    FileSaver.saveAs(blob, `${payment.reference}_RECEIPT.docx`)
  }

  cancelPayment = async (payment: Payment) => {
    await this.setState(prevState => ({ ...prevState, showCancelDialog: payment }))
  }

  onAccept = async (payment: Payment) => {
    await request(`/api/examiner/payments/${payment.id}`, {
      method: 'DELETE',
    })
    await this.setState(prevState => ({ ...prevState, showCancelDialog: null }))
    this.reload()
  }

  onClose = async () => {
    await this.setState(prevState => ({ ...prevState, showCancelDialog: null }))
  }

  confirmPayment = async (payment: Payment) => {
    await this.setState(prevState => ({ ...prevState, showConfirmDialog: payment }))
  }

  onAccept2 = async (payment: Payment) => {
    await request(`/api/examiner/payments/${payment.id}/confirm`, {
      method: 'PUT',
    })
    await this.setState(prevState => ({ ...prevState, showConfirmDialog: null }))
    this.reload()
  }

  onClose2 = async () => {
    await this.setState(prevState => ({ ...prevState, showConfirmDialog: null }))
  }


  handleFieldChange = async (searchQuery: string) => {
    await this.setState(prevState => ({ ...prevState, searchQuery }))
    this.reload()
  }

  toggleUnpaidOnly = async () => {
    let unpaidOnly = !this.state.unpaidOnly
    await this.setState(prevState => ({ ...prevState, unpaidOnly }))
    this.reload()
  }

  downloadPayments = async () => {
    // await this.setState(prevState => ({ ...prevState, downloading: true}))
    let r = await request(`/api/examiner/payments/download`)

    let results = await r.text()

    var blob = new Blob([results], { type: 'text/csv' })
    FileSaver.saveAs(blob, 'payments.csv')
    // await this.setState(prevState => ({ ...prevState, downloading: false}))
  }

}
