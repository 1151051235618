

import React from 'react';
import TeacherContainer from '../TeacherContainer';
import TeacherSittingsList from './TeacherSittingsList';
import request, { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import TeacherPapersList from './TeacherPapersList';
import { Dashboard } from '../../../interfaces/teacher/Dashboard';

interface Props {
}

interface State {
  defaultPassword: string
}

export default class TeacherInvigilationPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      defaultPassword: ''
    }

    this.load()
  }

  render() {

    if(!isLoggedIn('teacher')) {
        return <Redirect to='/login' />
    }


    return (
          <TeacherContainer heading="Invigilation">
              <h2>Papers</h2>
              <TeacherPapersList/>
              <h2>Active Exams</h2>
              <p> The default password for your students is <strong>{this.state.defaultPassword}</strong></p>
            <TeacherSittingsList/>
          </TeacherContainer>
    )
  }

  async load() {

    let res:Dashboard = await (await request('/api/teacher')).json()

    this.setState((prevState) => ({ ...prevState,  defaultPassword: res.defaultPassword}))
  }

}

