

import React from 'react';
import { Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../../api';
import ExaminerContainer from '../ExaminerContainer';
import ExaminerQuestionList from './ExaminerQuestionList';

interface Props {
}

interface State {
}

export default class ExaminerExamsPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      exams: null
    }
  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    return (
          <ExaminerContainer heading="Questions">
            <ExaminerQuestionList/>
        </ExaminerContainer>
    )
  }

}

