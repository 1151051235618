
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@material-ui/core';
import { Edit, QuestionAnswer, Delete, Add, FindInPage, Cancel, PlaylistAddCheck } from '@material-ui/icons'
import { Exam } from '../../../interfaces/examiner/Exam'
import { Paper } from '../../../interfaces/shared/Paper'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Question } from '../../../interfaces/shared/Question';
import DataTable from 'react-data-table-component';
import request from '../../../api';

interface Props {
    onSelect:(question:Question)=>void
}

interface State {
  data:Question[]|null
  totalRows:number
  rowsPerPage:number
  page:number
  sortColumn:string
  sortOrder:'asc'|'desc',
}

export default class ExaminerQuestionPickerList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      data:[],
      totalRows:0,
      rowsPerPage:10,
      page:1,
      sortColumn:'created',
      sortOrder:'desc',
    }
  }

   columns: any[] = [
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            cell: (question: Question) => new Date(question.created).toLocaleDateString()
        },
        {
            name: 'Description',
            selector: 'description',
            sortable: true
        },
        {
            cell: (question: Question) => <Fragment>
                <Button onClick={() => this.props.onSelect(question)}><PlaylistAddCheck /></Button>
            </Fragment>
        }
    ]

  render() {

    let { data, totalRows, rowsPerPage, page } = this.state

    if(data === null) {
        return <div>Loading...</div>
    }

    return (
        <Fragment>
            <DataTable
                columns={this.columns}
                data={data}
                pagination
                paginationServer
                paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                sortServer
                onSort={this.handleSort}
                onChangeRowsPerPage={this.handleChangeRowsPerPage}
                onChangePage={this.handleChangePage}
                noHeader
            />
      </Fragment>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let { page, rowsPerPage, sortColumn, sortOrder } = this.state

    let { questions, total } = await (
        await request(`/api/examiner/questions?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}&filter=nopaper`)
    ).json()

    this.setState(prevState => ({ ...prevState, data: questions, totalRows: total }))
  }

  handleChangeRowsPerPage = async (rowsPerPage:number) => {
      await this.setState(prevState => ({ ...prevState, rowsPerPage }))
      this.reload()
  }

  handleChangePage = async (page:number) => {
      await this.setState(prevState => ({ ...prevState, page }))
      this.reload()
  }

  handleSort = async (column:any, sortDirection:any) => {
      await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
      this.reload()
  }

}
