// CustomLink.js
import React, { ReactNode } from 'react';

interface CustomLinkProps {
  children: ReactNode;
  [key: string]: any; // Allow any additional props
}

const studentContentTd: React.FC<CustomLinkProps> = ({ children, ...props }) => {
  console.log('Rendering studentContentTd:', children);
  return (
    props.isHeader ?
    <th {...props}  style={{ padding: 10 }}>
      {children}
    </th>
    :
    <td {...props}  style={{ padding: '2px 10px' }}>
      {children}
    </td>
  );
};

export default studentContentTd;
