
import React from "react";

import { Button, Dialog, DialogContent, DialogContentText, TextField, DialogActions, DialogTitle } from '@material-ui/core'
import { Paper } from "../../../interfaces/shared/Paper";
import ExaminerPaperList from "../papers/ExaminerPaperList";
import { Examiner } from "../../../interfaces/examiner/Examiner";

interface Props {
    onCreate:(examiner:Examiner) => void
    onClose:() => void
}

interface State {
    examiner:Examiner
}

export default class NewExaminerDialog  extends React.Component<Props, State> {

    constructor(props:Props) {

        super(props)
        this.state = {
            examiner: {
                id:'',
                username:'',
                superuser:false,
                password:''
            }
          }
    }

    render() {
        let canPressAdd = this.state.examiner.password!.length>12 && this.state.examiner.username.length>3
        return (
            <div>
            <Dialog open={true} onClose={this.props.onClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">{`New Examiner`}</DialogTitle>
                <DialogContent>
                <TextField
                            autoFocus
                            margin="dense"
                            label="Username"
                            fullWidth
                            variant="filled"
                            name="username"
                            defaultValue={this.state.examiner.username}
                            onChange={this.handleChange}
                            helperText="Usernames must be >3 characters and unique"
                        />
                <TextField
                            margin="dense"
                            label="Password"
                            fullWidth
                            variant="filled"
                            name="password"
                            defaultValue={this.state.examiner.password}
                            onChange={this.handleChange}
                            helperText="Passwords must be >12 characters"
                        />
                </DialogContent>
                <DialogActions>
                <Button disabled ={!canPressAdd} onClick={this.onCreate} color="secondary">
                    Add
                </Button>
                <Button onClick={this.onClose} color="secondary">
                    Cancel
                </Button>
                </DialogActions>
            </Dialog>
            </div>
        );
    }

    onClose = () => {
        this.props.onClose()
    }

    onCreate = () => {
        this.props.onCreate(this.state.examiner)
    }

    handleChange = async (ev: any) => {

        let examiner = this.state.examiner as Examiner
        let newExaminer:Examiner = { ...examiner, [ev.target.name]: ev.target.value }
        await this.setState(prevState => ({ ...prevState, examiner: newExaminer }))
    }
    
}