

import { Button, debounce, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Paper as MaterialPaper, Link, Paper, Box, InputLabel, Select, MenuItem, FormControlLabel, Checkbox } from '@material-ui/core';
import { Teacher } from '../../../interfaces/examiner/Teacher'
import React, { Fragment } from 'react';
import ExaminerContainer from '../ExaminerContainer';

import '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SavingIndicator from '../../../components/SavingIndicator';
import { Cancel, Delete } from '@material-ui/icons';
import request, { getConfigSync, isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';
import countryList from 'react-select-country-list'
import ExaminerTeacherExams from './ExaminerTeacherExams';
import { Exam, ExamHasTeacher } from '../../../interfaces/examiner/Exam';
import EnrolTeacherDialog from './EnrolTeacherDialog';
import IncreaseStudentsDialog from './IncreaseStudentsDialog';
import TeacherDeleteDialog from './TeacherDeleteDialog';
import { useHistory } from 'react-router-dom';

interface Props {
  id: string
}

interface State {
  teacher: Teacher | null,
  saving: boolean
  canReset: boolean
  enrolTeacherDialog: Exam | null
  increaseStudentsDialog: ExamHasTeacher | null
  deleteTeacherDialog: Teacher | null
  resetPasswordMessage: string | null
}

export default class ExaminerTeacherPage extends React.Component<Props, State> {

  constructor(props: Props) {

    super(props)

    this.state = {
      teacher: null,
      saving: false,
      canReset: true,
      enrolTeacherDialog: null,
      increaseStudentsDialog: null,
      deleteTeacherDialog:null,
      resetPasswordMessage:null,
    }
  }

  render() {
    let { teacher, saving, canReset, resetPasswordMessage } = this.state

    if (!isLoggedIn('examiner')) {
      return <Redirect to='/login' />
    }


    if (!teacher) {
      return <ExaminerContainer heading={"Loading..."}>Loading...</ExaminerContainer>
    }
    const options = countryList().getData()
    return (
      <ExaminerContainer heading={teacher.name + ' - ' + teacher.school + ' - ' + teacher.schoolCode}>
        <SavingIndicator saving={saving} />
        {this.state.enrolTeacherDialog && <EnrolTeacherDialog onEnrolTeacher={this.onEnrolTeacher} onClose={this.onCloseEnrolTeacherDialog} exam={this.state.enrolTeacherDialog} />}
        {this.state.increaseStudentsDialog && <IncreaseStudentsDialog onAddStudents={this.onIncreaseStudents} onClose={this.onCloseIncreaseStudentsDialog} examHasTeacher={this.state.increaseStudentsDialog} />}
        {this.state.deleteTeacherDialog && <TeacherDeleteDialog teacher={this.state.deleteTeacherDialog} onClose={this.closeDeleteTeacherDialog} onDelete={this.handleDeleteTeacher} />}
        <Fragment>
          School Code: {teacher.schoolCode}
          <br />
          School Student Default Password: {teacher.defaultPassword}
          <br /><br />
          <Button disabled={!canReset} onClick={() => this.handleResetPassword()} variant="outlined">E-mail new password</Button>
        </Fragment>
        {!canReset && resetPasswordMessage && <p>{resetPasswordMessage}</p>}
        <br />
        <br />
        <ExaminerTeacherExams exams={teacher.exams} onClickExam={this.onClickExam} />
        <br />
        <TextField margin="dense"
          id="name" label="Name"
          fullWidth defaultValue={teacher.name}
          onChange={(e) => this.handleFieldChange('name', e)} />
        <TextField margin="dense"
          id="email" label="Email"
          fullWidth defaultValue={teacher.email}
          onChange={(e) => this.handleFieldChange('email', e)} />
        <TextField margin="dense"
          id="school" label="School"
          fullWidth defaultValue={teacher.school}
          onChange={(e) => this.handleFieldChange('school', e)} />
        <TextField margin="dense"
          id="urn" label="URN"
          fullWidth defaultValue={teacher.urn}
          onChange={(e) => this.handleFieldChange('urn', e)} />
        <InputLabel id="location-label">Location</InputLabel>
        <Select
          required
          label="Location"
          value={teacher.location}
          onChange={(e) => this.handleFieldChange('location', e)}
        >
          <MenuItem
            key={"Overseas"}
            value={"Overseas"}>
            {"Overseas"}
          </MenuItem>
          <MenuItem
            key={"UK"}
            value={"UK"}>
            {"UK"}
          </MenuItem>
          <MenuItem
            key={"Test"}
            value={"Test"}>
            {"Test"}
          </MenuItem>
        </Select>
        <InputLabel id="schooltype-label">School Type</InputLabel>
        <Select
          required
          label="School Type"
          value={teacher.schoolType}
          onChange={(e) => this.handleFieldChange('schoolType', e)}
        >
          <MenuItem
            key={"Non-fee-paying"}
            value={"Non-fee-paying"}>
            {"Non-fee-paying"}
          </MenuItem>
          <MenuItem
            key={"Fee-paying"}
            value={"Fee-paying"}>
            {"Fee-paying"}
          </MenuItem>
          <MenuItem
            key={"Unspecified"}
            value={"Unspecified"}>
            {"Unspecified"}
          </MenuItem>
          <MenuItem
            key={"Test"}
            value={"Test"}>
            {"Test"}
          </MenuItem>
        </Select>
        <br />
        <FormControlLabel
          control={
            <Checkbox
              onChange={this.toggleConsent}
              checked={teacher.consent2 == "accept"}
              color="primary"
            />}
          label={`Please keep me informed about ${getConfigSync().competitionShortName} competitions`}
        />
        <br />
        <FormControlLabel
          control={
            <Checkbox
              onChange={this.toggleAuthenticated}
              checked={teacher.isAuthenticated }
              color="primary"
            />}
          label={`Allow teacher to use invoices?`}
        />
        <h3>Address</h3>
        <TextField margin="dense"
          id="line1" label="Line 1"
          fullWidth defaultValue={teacher.addressLine1}
          onChange={(e) => this.handleFieldChange('addressLine1', e)} />
        <TextField margin="dense"
          id="line2" label="Line 2"
          fullWidth defaultValue={teacher.addressLine2}
          onChange={(e) => this.handleFieldChange('addressLine2', e)} />
        <TextField margin="dense"
          id="addressCity" label="City"
          fullWidth defaultValue={teacher.addressCity}
          onChange={(e) => this.handleFieldChange('addressCity', e)} />
        <TextField margin="dense"
          id="addressState" label="County"
          fullWidth defaultValue={teacher.addressState}
          onChange={(e) => this.handleFieldChange('addressState', e)} />
        <TextField margin="dense"
          id="addressPostcode" label="Postcode"
          fullWidth defaultValue={teacher.addressPostcode}
          onChange={(e) => this.handleFieldChange('addressPostcode', e)} />
        <InputLabel id="addressCountry-label">Country</InputLabel>
        <Select margin="dense"
          id="addressCountry" label="Country"
          fullWidth defaultValue={teacher.addressCountry}
          onChange={(e) => this.handleFieldChange('addressCountry', e)}>
          <MenuItem
            value={"GB"}>
            {"United Kingdom"}
          </MenuItem>
          {options.map((o, i) => {
            return (
              <MenuItem
                key={i}
                value={o.value}>
                {o.label}
              </MenuItem>
            );
          })}
        </Select>
        <TextField margin="dense"
          id="phoneNumber" label="Telephone Number"
          fullWidth defaultValue={teacher.phoneNumber}
          onChange={(e) => this.handleFieldChange('phoneNumber', e)} />
      <br />
      <br />
      <Table>
         <TableRow>
          <TableCell>Time</TableCell>
          <TableCell>Action</TableCell>
          <TableCell>Success</TableCell>
          <TableCell>Comment</TableCell>
         </TableRow>
        <TableBody>
        {teacher.authLogs.map(a=>{ 
        return(
          <TableRow>
          <TableCell>{new Date(a.createdAt).toLocaleString()}</TableCell>
          <TableCell>{a.action}</TableCell>
          <TableCell>{a.success ? 'Success' : 'Fail'}</TableCell>
          <TableCell>{a.comment}</TableCell>
          </TableRow>
          )})}
        </TableBody>
   </Table>
          
      <br />
      <br />
      <Button onClick={() => this.openDeleteTeacherDialog(teacher)}>Delete Teacher<Delete /></Button>
      </ExaminerContainer>
    )
  }

  componentDidMount() {

    this.reloadTeacher()

  }

  private async reloadTeacher() {

    let teacher = await (await request('/api/examiner/teachers/' + this.props.id)).json()

    this.setState(prevState => ({ ...prevState, teacher }))
  }

  async saveWithDelay() {
    await this.setState(prevState => ({ ...prevState, saving: true }))
    this.saveDebounced()
  }

  saveDebounced = debounce(async () => this.save(), 1000)

  async save() {
    await request('/api/examiner/teachers/' + this.props.id, {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify(this.state.teacher)
    })
    await this.setState(prevState => ({ ...prevState, saving: false }))
  }

  handleFieldChange = async (field: string, e: any) => {
    let teacher = this.state.teacher as Teacher
    let newTeacher: Teacher = { ...teacher, [field]: e.target.value }

    await this.setState(prevState => ({ ...prevState, teacher: newTeacher }))
    this.save()
  }

  handleResetPassword = async () => {
    let message:string = await (await request('/api/examiner/teachers/' + this.props.id + '/resetPassword',
      {
        method: 'POST',
      })).text()
    let teacher = this.state.teacher
    teacher!.tempPassword = true
    this.setState(prevState => ({ ...prevState, teacher, canReset: false, resetPasswordMessage: message }))
  }

  onClickExam = (examId: string) => {
    let exam = this.state.teacher?.exams.filter(e => e.id == examId)[0]
    let enrolled = exam!.teachers.length > 0
    console.log(enrolled)
    if (enrolled) {
      this.setState(prevState => ({ ...prevState, increaseStudentsDialog: exam!.teachers[0] }))
    } else {
      this.setState(prevState => ({ ...prevState, enrolTeacherDialog: exam! }))
    }
  }

  onIncreaseStudents = async (n: number) => {
    console.log(this.state.increaseStudentsDialog!.examId, n)
    await request('/api/examiner/examHasTeacher/' + this.state.increaseStudentsDialog!.id, {
      method: 'PUT',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        n: n
      })
    })
    this.reloadTeacher()
    this.setState(prevState => ({ ...prevState, increaseStudentsDialog: null }))
  }

  onCloseIncreaseStudentsDialog = () => {
    this.setState(prevState => ({ ...prevState, increaseStudentsDialog: null }))
  }

  onEnrolTeacher = async () => {
    console.log(this.state.enrolTeacherDialog!.id)
    await request('/api/examiner/examHasTeacher', {
      method: 'POST',
      headers: { 'content-type': 'application/json' },
      body: JSON.stringify({
        examId: this.state.enrolTeacherDialog!.id,
        teacherId: this.props.id
      })
    })
    this.reloadTeacher()
    this.setState(prevState => ({ ...prevState, enrolTeacherDialog: null }))
  }

  onCloseEnrolTeacherDialog = () => {
    this.setState(prevState => ({ ...prevState, enrolTeacherDialog: null }))
  }

  toggleConsent = async (event: { target: { checked: boolean; }; }) => {
    let teacher = this.state.teacher as Teacher
    let consent = ""
    if (event.target.checked) { consent = "accept" }
    let newTeacher: Teacher = { ...teacher, consent2: consent }
    await this.setState(prevState => ({ ...prevState, teacher: newTeacher }))
    this.save()
  }

  toggleAuthenticated = async (event: { target: { checked: boolean; }; }) => {
    let teacher = this.state.teacher as Teacher
    let newTeacher: Teacher = { ...teacher, isAuthenticated: event.target.checked }
    await this.setState(prevState => ({ ...prevState, teacher: newTeacher }))
    this.save()
  }

  handleDeleteTeacher = async (teacher:Teacher) => {
    await request('/api/examiner/teachers/' + teacher.id, { method: 'DELETE' })
    window.location.href = './';
  }

  closeDeleteTeacherDialog = async () => {
    await this.setState(prevState => ({ ...prevState, deleteTeacherDialog:null }))
  }

  openDeleteTeacherDialog = async (teacher:Teacher | null) => {
    await this.setState(prevState => ({ ...prevState, deleteTeacherDialog:teacher }))
  }


}
