
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Table } from '@material-ui/core';
import { Edit, Delete, Add, FindInPage, Check, PlaylistAddCheck, PieChart, Equalizer, GetApp } from '@material-ui/icons'
import { Exam } from '../../../interfaces/examiner/Exam'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Paper } from '../../../interfaces/shared/Paper';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import FileSaver from 'file-saver';
import { DocumentCreator } from './paperGenerator';
import { Packer } from 'docx';
import { DocumentCreator2 } from './answerGenerator';
import PaperDeleteDialog from './QuestionDeleteDialog';

interface Props {
    onSelect?: (paper: Paper) => void
    readOnly?: boolean
    exclude?: Paper[]
}

interface State {
    data: Paper[] | null
    totalRows: number
    rowsPerPage: number
    page: number
    sortColumn: string
    sortOrder: 'asc' | 'desc'
    deletePaperDialog: Paper | null
    totalMarks: {paperId:string, totalMarks:number}[]
}

export default class ExaminerPaperList extends React.Component<Props, State> {

    constructor(props: Props) {

        super(props)

        this.state = {
            data: [],
            totalRows: 0,
            rowsPerPage: 10,
            page: 1,
            sortColumn: 'created',
            sortOrder: 'desc',
            deletePaperDialog: null,
            totalMarks: []
        }
    }

    columns: any[] = [
        {
            name: 'Created',
            selector: 'created',
            sortable: true,
            cell: (paper: Paper) => new Date(paper.created).toLocaleDateString()
        },
        {
            name: 'Title',
            selector: 'title',
            sortable: true,
            wrap: true,
        },
        {
            name: 'Marks',
            cell: (paper: Paper) => <Fragment>
                {this.state.totalMarks.filter(t => t.paperId === paper.id)[0].totalMarks}
        </Fragment>
        },
        this.props.readOnly !== true && {
            name: 'Analysis',
            cell: (paper: Paper) => <Fragment>
                {

                    <Fragment>
                        <Button component={Link} to={`/examiner/papers/${paper.id}/scores`}><Equalizer /></Button>
                    </Fragment>
                }
            </Fragment>
        },
        this.props.readOnly !== true && {
            name: 'Download Paper',
            cell: (paper: Paper) => <Fragment>
                {

                    <Fragment>
                        <Button onClick={() => this.downloadPaper(paper.id)}><GetApp /></Button>
                    </Fragment>
                }
            </Fragment>
        },
        this.props.readOnly !== true && {
            name: 'Download Answers',
            cell: (paper: Paper) => <Fragment>
                {

                    <Fragment>
                        <Button onClick={() => this.downloadPaperAnswers(paper.id)}><GetApp /></Button>
                    </Fragment>
                }
            </Fragment>
        },
        {
            right: true,
            cell: (paper: Paper) => <Fragment>
                {

                    this.props.readOnly !== true && <Fragment>
                        <Button component={Link} to={"/examiner/papers/" + paper.id}><Edit /></Button>
                        <Button onClick={() => this.openDeletePaperDialog(paper)}><Delete /></Button>
                    </Fragment>
                }
                {
                    this.props.onSelect &&
                    <Button onClick={() => this.props.onSelect && this.props.onSelect(paper)}><PlaylistAddCheck /></Button>
                }
            </Fragment>
        }
    ].filter(column => column)

    renderActions() {
    }

    render() {

        let { data, totalRows, rowsPerPage, page } = this.state

        if (data === null) {
            return <div>Loading...</div>
        }

        return (
            <Fragment>
                {
                    this.props.readOnly !== true &&
                    <Fragment>
                        <Button variant="outlined" color="primary" onClick={this.handleCreatePaper}>
                            Create Paper
                        </Button>
                        <br />
                        <br />
                    </Fragment>
                }
                {this.state.deletePaperDialog && <PaperDeleteDialog paper={this.state.deletePaperDialog} onClose={this.closeDeletePaperDialog} onDelete={this.handleDeletePaper} />}

                <DataTable
                    columns={this.columns}
                    data={data}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                paginationRowsPerPageOptions = {[10,25,50,100,500]}
                    sortServer
                    onSort={this.handleSort}
                    onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    onChangePage={this.handleChangePage}
                    noHeader
                />
            </Fragment>
        )
    }

    componentDidMount() {
        this.reload()
    }

    private async reload() {

        let { page, rowsPerPage, sortColumn, sortOrder } = this.state

        let { papers, total, totalMarks } = await (
            await request(`/api/examiner/papers?offset=${(page - 1) * rowsPerPage}&limit=${rowsPerPage}&sortBy=${sortColumn}&order=${sortOrder}`)
        ).json()

        this.setState(prevState => ({ ...prevState, data: papers, totalRows: total, totalMarks:totalMarks }))
    }

    handleChangeRowsPerPage = async (rowsPerPage: number) => {
        await this.setState(prevState => ({ ...prevState, rowsPerPage }))
        this.reload()
    }

    handleChangePage = async (page: number) => {
        await this.setState(prevState => ({ ...prevState, page }))
        this.reload()
    }

    handleSort = async (column: any, sortDirection: any) => {
        await this.setState(prevState => ({ ...prevState, sortColumn: column.selector, sortOrder: sortDirection }))
        this.reload()
    }

    handleCreatePaper = async () => {
        await request('/api/examiner/papers', { method: 'POST' })
        this.reload()
    }

    handleDeletePaper = async (paper: Paper) => {
        await request('/api/examiner/papers/' + paper.id, { method: 'DELETE' })
        this.reload()
        await this.setState(prevState => ({ ...prevState, deletePaperDialog: null }))
    }

    openDeletePaperDialog = async (paper: Paper) => {
        await this.setState(prevState => ({ ...prevState, deletePaperDialog: paper }))
    }

    closeDeletePaperDialog = async () => {
        await this.setState(prevState => ({ ...prevState, deletePaperDialog: null }))
    }

    downloadPaper = async (paperId: string) => {
        let paper: Paper = await (await request(`/api/examiner/papers/${paperId}`)).json()
        const documentCreator = new DocumentCreator();
        const doc = await documentCreator.create(
            paper
        );
        Packer.toBlob(doc).then(blob => {
            console.log(blob);
            saveAs(blob, `${paper.title}.docx`);
            console.log("Document created successfully");
        });
    }


    downloadPaperAnswers = async (paperId: string) => {
        let paper: Paper = await (await request(`/api/examiner/papers/${paperId}`)).json()
        const documentCreator2 = new DocumentCreator2();
        const doc = await documentCreator2.create(
            paper
        );
        Packer.toBlob(doc).then(blob => {
            console.log(blob);
            saveAs(blob, `${paper.title}_ANSWER.docx`);
            console.log("Document created successfully");
        });
    }
}
