

import React from 'react';
import ExaminerContainer from '../ExaminerContainer';
import ExaminerExaminerList from './ExaminerExaminersList';
import { Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../../api';

interface Props {
}

interface State {
  isSuperuser:boolean
}

export default class ExaminerExaminersPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      isSuperuser:false
    }
  }

  async componentDidMount(){
    if(isLoggedIn('superuser')) {
      await this.setState(prevState => ({ ...prevState, isSuperuser: true }))
    }
  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    return (
          <ExaminerContainer heading="Examiners">
            <ExaminerExaminerList isSuperuser = {this.state.isSuperuser} />
        </ExaminerContainer>
    )
  }

}

