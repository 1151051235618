
import { Button, debounce, Table, TableBody, TableCell, TableContainer, TableRow, TextField, Paper as MaterialPaper, Link, Paper, Box, Select, MenuItem, InputLabel } from '@material-ui/core';
import { Teacher } from '../../../interfaces/examiner/Teacher'
import React, { Fragment } from 'react';
import TeacherContainer from '../TeacherContainer';
import { Link as Link2 } from 'react-router-dom'
import '@date-io/date-fns';
import Grid from '@material-ui/core/Grid';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SavingIndicator from '../../../components/SavingIndicator';
import { Cancel } from '@material-ui/icons';
import { Student } from '../../../interfaces/teacher/Student';
import request, { isLoggedIn } from '../../../api';
import { Redirect } from 'react-router-dom';

interface Props {
  examId: string
  id: string
}

interface State {
  student:Student|null,
  saving:boolean
  canReset: boolean
}

export default class TeacherStudentPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      student: null,
      saving: false,
      canReset: true
    }
  }

  render() {

    let { student, saving, canReset } = this.state


    if(!isLoggedIn('teacher')) {
        return <Redirect to='/login' />
    }


    if(!student) {
        return <TeacherContainer heading={"Loading..."}>Loading...</TeacherContainer>
    }

    return (
        <TeacherContainer heading={student.username}>
            <SavingIndicator saving={saving} />
                <div><h2>{student.username}</h2></div>
                <Button onClick={this.handleResetPassword} color="secondary" variant="outlined" disabled={!canReset}>
                    Reset User Password
                </Button>
                {!canReset && <p>Password reset</p>}
                <TextField autoFocus margin="dense"
                    id="name" label="Name"
                    fullWidth defaultValue={student.name}
                    onChange={(e) => this.handleFieldChange('name', e)} />
                    <br /> <br /> <br />
                    <InputLabel id="yeargroup-label">Year Group</InputLabel>
                   <Select
                        required
                        label="Year Group"
                        value = {student.yearGroup}
                        onChange = {(e) => this.handleFieldChange('yearGroup', e)}
                    >
                        <MenuItem
                            key={"Year Group A (ages 13-14)"}
                            value={"Year Group A (ages 13-14)"}>
                              {"Year Group A (ages 13-14)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group B (ages 14-15)"}
                            value={"Year Group B (ages 14-15)"}>
                              {"Year Group B (ages 14-15)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group C (ages 15-16)"}
                            value={"Year Group C (ages 15-16)"}>
                              {"Year Group C (ages 15-16)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group D (ages 16-17)"}
                            value={"Year Group D (ages 16-17)"}>
                              {"Year Group D (ages 16-17)"}
                        </MenuItem>
                        <MenuItem
                            key={"Year Group E (ages 17-18)"}
                            value={"Year Group E (ages 17-18)"}>
                              {"Year Group E (ages 17-18)"}
                        </MenuItem>
                    </Select>
                    <br />
                    <br />
                    <br />
                <Button component={Link2} to={`/teacher/exams/${this.props.examId}`}>Back</Button>
        </TeacherContainer>
    )
  }

  componentDidMount() {

    this.reloadStudent()

  }

  private async reloadStudent() {

    let student = await (await request(`/api/teacher/exams/${this.props.examId}/students/` + this.props.id)).json()

    this.setState(prevState => ({ ...prevState, student }))
  }

    handleFieldChange = async (field:string, e:any) => {
      let student = this.state.student as Student
      let newStuednt:Student = { ...student, [field]: e.target.value }

      await this.setState(prevState => ({ ...prevState, student: newStuednt}))
      this.save()
    }

    handleResetPassword = async () => {
      await request(`/api/teacher/exams/${this.props.examId}/students/${this.props.id}/resetPassword`, {
        method: 'PUT'
      })
      this.setState(prevState => ({ ...prevState, canReset: false }))
    }


    async save() {
      await request(`/api/teacher/exams/${this.props.examId}/students/${this.props.id}`, {
          method: 'PUT',
          headers: { 'content-type': 'application/json' },
          body: JSON.stringify(this.state.student)
      })
  }

}
