
import { Box, Button, Paper, Grid, TextField } from "@material-ui/core"
import React, { Component, Fragment } from "react"
import { Question } from "../../../../../interfaces/shared/Question"
import { QuestionPart, QuestionPartCheckboxes, QuestionPartDragAndDrop, QuestionPartPlotFunction, QuestionPartListDivideIntoGroups, QuestionPartListOrdering, QuestionPartMultipleTrueFalse, QuestionPartOneWordAnswer, QuestionPartRadioButtons, QuestionPartStem, QuestionPartType, QuestionPartVideo } from "../../../../../shared/QuestionSpec"
import CheckboxesEditor from "./CheckboxesEditor"
import DragAndDropEditor from "./DragAndDropEditor"
import ListDivideIntoGroupsEditor from "./ListDivideIntoGroupsEditor"
import ListOrderingEditor from "./ListOrderingEditor"
import MultipleTrueFalseEditor from "./MultipleTrueFalseEditor"
import OneWordAnswerEditor from "./OneWordAnswerEditor"
import RadioButtonsEditor from "./RadioButtonsEditor"
import StemEditor from "./StemEditor"
import VideoEditor from "./VideoEditor"
import PlotFunctionEditor from "./PlotFunctionEditor"

interface Props {
    part: QuestionPart
    onUpdate: (oldPart: QuestionPart, newPart: QuestionPart) => void
}

export default class Editor extends Component<Props> {

    constructor(props: Props) {
        super(props)
    }

    render() {

        let { part, onUpdate } = this.props

        var element

        switch (part.type) {

            case QuestionPartType.Stem:
                element = <StemEditor part={part as QuestionPartStem} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.Video:
                element = <VideoEditor part={part as QuestionPartVideo} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.Checkboxes:
                element = <CheckboxesEditor part={part as QuestionPartCheckboxes} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.DragAndDrop:
                element = <DragAndDropEditor part={part as QuestionPartDragAndDrop} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.ListDivideIntoGroups:
                element = <ListDivideIntoGroupsEditor part={part as QuestionPartListDivideIntoGroups} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.ListOrdering:
                element = <ListOrderingEditor part={part as QuestionPartListOrdering} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.MultipleTrueFalse:
                element = <MultipleTrueFalseEditor part={part as QuestionPartMultipleTrueFalse} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.OneWordAnswer:
                element = <OneWordAnswerEditor part={part as QuestionPartOneWordAnswer} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.RadioButtons:
                element = <RadioButtonsEditor part={part as QuestionPartRadioButtons} onUpdate={this.onUpdate} />
                break

            case QuestionPartType.PlotFunction:
                element = <PlotFunctionEditor part={part as QuestionPartPlotFunction} onUpdate={this.onUpdate} />
                break

            default:
                throw new Error('unknown question part type: ' + part.type)
        }

        return (
            <Fragment>
                <h2>{part.type}</h2>
                {
                    (part.type !== QuestionPartType.Stem && part.type !== QuestionPartType.Video && part.type !== QuestionPartType.PlotFunction) &&
                    <Fragment>
                        <TextField size="small" variant="outlined" label="Marks" type="number" onChange={this.onChangeMarks} value={part.marks} />
                        <br />
                        <br />
                    </Fragment>
                }
                {element}
            </Fragment>
        )
    }

    onUpdate = (oldPart: QuestionPart, newPart: QuestionPart) => {
        this.props.onUpdate(oldPart, newPart)
    }

    onChangeMarks = (e: any) => {

        let part = this.props.part

        part.marks = parseInt(e.target.value)

        this.props.onUpdate(this.props.part, this.props.part)
    }


}
