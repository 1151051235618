import React, { Fragment, useState } from 'react';
import Divider from '@material-ui/core/Divider';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Receipt, QuestionAnswer, People, Dashboard, Timer, Person, ExitToApp, Payment, AssignmentInd } from '@material-ui/icons';
import { makeStyles, useTheme, Theme, createStyles } from '@material-ui/core/styles';
import ResponsiveDrawer from '../../ResponsiveDrawer';
import { Box, CssBaseline } from '@material-ui/core';
import { Link, Redirect } from 'react-router-dom'
import request from '../../api';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    toolbar: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
    }
  })
);

interface Props {
  heading:string
  children:any
}

export default function ExaminerContainer(props: Props) {
  const classes = useStyles();
  const [navigate, setNavigate] = useState(false);
  if (navigate) {
    request('/api/examiner/logout/', { method: 'DELETE' })
    sessionStorage.removeItem(`ukbc_examiner`)
    return <Redirect to="/login" push={true} />
  }
  const drawer = (
    <div>
      <div className={classes.toolbar} />
      <Divider />
      <List>
          <ListItem button component={Link} to="/examiner">
            <ListItemIcon> <Dashboard /> </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>
          <ListItem button component={Link} to="/examiner/questions">
            <ListItemIcon> <QuestionAnswer /> </ListItemIcon>
            <ListItemText primary="Questions" />
          </ListItem>
          <ListItem button component={Link} to="/examiner/papers">
            <ListItemIcon> <Receipt /> </ListItemIcon>
            <ListItemText primary="Papers" />
          </ListItem>
          <ListItem button component={Link} to="/examiner/exams">
            <ListItemIcon> <Timer /> </ListItemIcon>
            <ListItemText primary="Exams" />
          </ListItem>
          <ListItem button component={Link} to="/examiner/teachers">
            <ListItemIcon> <Person /> </ListItemIcon>
            <ListItemText primary="Teachers" />
          </ListItem>
          <ListItem button component={Link} to="/examiner/payments">
            <ListItemIcon> <Payment /> </ListItemIcon>
            <ListItemText primary="Payments" />
          </ListItem>
          <ListItem button component={Link} to="/examiner/examiners">
            <ListItemIcon> <AssignmentInd /> </ListItemIcon>
            <ListItemText primary="Examiners" />
          </ListItem>
          <ListItem button onClick={()=> setNavigate(true)}>
            <ListItemIcon> <ExitToApp /> </ListItemIcon>
            <ListItemText primary="Logout" />
          </ListItem>
      </List>
      {/* <Divider />
      <List>
      </List> */}
    </div>
  );

  return <div className={classes.root}>
    <CssBaseline/>
    <ResponsiveDrawer heading={props.heading}>
      {drawer}
    </ResponsiveDrawer>

    <main className={classes.content}>
      <div className={classes.toolbar} />
      <Box>
      {props.children}
      </Box>
    </main>
    </div>

}

