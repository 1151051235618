
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Paper as MaterialPaper } from '@material-ui/core';
import { Edit, Delete, Add, FindInPage } from '@material-ui/icons'
import { Exam } from '../../../interfaces/examiner/Exam'
import React, { Fragment } from 'react';
import { Link, Redirect } from 'react-router-dom'
import { Paper } from '../../../interfaces/shared/Paper'
import ExaminerContainer from '../ExaminerContainer';
import ExaminerPaperList from './ExaminerPaperList';
import { isLoggedIn } from '../../../api';

interface Props {
}

interface State {
}

export default class ExaminerPapers extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    return (
          <ExaminerContainer heading="Papers">
              <ExaminerPaperList/>
      </ExaminerContainer>
    )
  }


}
