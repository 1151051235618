

import { Teacher } from '../../../interfaces/examiner/Teacher'
import React from 'react';
import ExaminerContainer from '../ExaminerContainer';
import ExaminerTeacherList from './ExaminerTeachersList';
import { Redirect } from 'react-router-dom';
import { isLoggedIn } from '../../../api';

interface Props {
}

interface State {
  exams:Teacher[]|null
  redirectToTeacherId:string
}

export default class ExaminerTeachersPage extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
      exams: null,
      redirectToTeacherId:''
    }
  }

  render() {

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    if(this.state.redirectToTeacherId) {
        return <Redirect push={true} to={'/examiner/teachers/' + this.state.redirectToTeacherId} />
    }

    return (
          <ExaminerContainer heading="Teachers">
            <ExaminerTeacherList onClickTeacher={this.onClickTeacher} />
        </ExaminerContainer>
    )
  }

  onClickTeacher = (teacherId:string) => {
      this.setState(prevState => ({ ...prevState, redirectToTeacherId: teacherId }))
  }

}

