
import { Checkbox, Grid, Radio } from "@material-ui/core"
import React from "react"
import { AnswerPart, AnswerPartRadioButtons } from "../../shared/AnswerSpec"
import { QuestionPartRadioButtons } from "../../shared/QuestionSpec"
import Content from './Content'

interface Props {
    part:QuestionPartRadioButtons
    answer:AnswerPartRadioButtons|null
    onUpdate:(newAnswerPart:AnswerPart)=>void
}

export default function RadioButtons(props:Props) {

    let { part } = props

    return <div>
         <Content content={part.actionStatement} />
         <i><div>{(part.marks > 1) ? `${part.marks} marks` : `${part.marks} mark`}</div></i>
         <table>
         {
             part.options.map((option) => {
                let selected:boolean = props.answer !== null && props.answer.selectedButtonId === option.id
                 return <tr>
                    <td>
                                <Radio checked={selected} onClick={() => handleClickOption(option.id)} />

                    </td>
                    <td>
                                <Content content={option.content} />
                    </td>
                </tr>
             })
         }
         </table>
    </div>

    function handleClickOption(optionId:string) {
        
        let answer:AnswerPartRadioButtons = {
            questionPartId: props.part.id,
            selectedButtonId: optionId
        }

        props.onUpdate(answer)

    }
}

