
import { Button, List, ListItem, ListItemIcon, ListItemText, TableBody, TableCell, TableContainer, TableHead, TableRow, Table, Grid, Paper as PaperUi, Box } from '@material-ui/core';
import { Edit, Receipt, Delete, Add, FindInPage } from '@material-ui/icons'
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom'
import { Paper } from '../../../interfaces/teacher/Paper';
import DataTable from 'react-data-table-component';
import request from '../../../api';
import { spacing } from '@material-ui/system';

interface Props {
}

interface State {
    papers:Paper[]|null
}

export default class TeacherPapersList extends React.Component<Props, State> {

  constructor(props:Props) {

    super(props)

    this.state = {
        papers: null
    }

    this.reload()
  }


  render() {

    let { papers } = this.state

    if(papers === null) {
        return <div>Loading...</div>
    }

    return (
        <Grid container spacing={1}>
            {
                papers.map(paper => (
                    <Grid item xs={12} sm={6} md={3}>
                        <PaperUi>
                          <Box p={2}>
                            <div>
                                <strong>{paper.title}</strong>
                            </div>
                            <div>
                                <i>{paper.duration}  minutes</i>
                            </div>
                            <div>
                                {
                                    paper.isActive ?
                                        <Button onClick={() => this.deactivate(paper.id)}>De-activate paper</Button> :
                                        <Button disabled={!paper.canActivate}  onClick={() => this.activate(paper.id)}>Activate paper</Button> 
                                }
                            </div>
                          </Box>
                      </PaperUi>
                    </Grid>
                ))
            }
      </Grid>
    )
  }

  componentDidMount() {
      this.reload()
  }

  private async reload() {

    let papers = await (
        await request(`/api/teacher/papers`)
    ).json()

    this.setState(prevState => ({ ...prevState, papers }))
  }

  deactivate = async (paperId:string) => {

    await request(`/api/teacher/papers/${paperId}/deactivate`,{
      method:'POST'
    })

    this.reload()

  }

  activate = async (paperId:string) => {

    await request(`/api/teacher/papers/${paperId}/activate`,{
      method:'POST'
    })

    this.reload()

  }

}
