
import { Button, CircularProgress, debounce, Link, Paper as MaterialPaper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@material-ui/core";
import React, { Fragment } from "react";
import ExaminerContainer from "../ExaminerContainer";
import { Paper } from '../../../interfaces/shared/Paper'
import SavingIndicator from "../../../components/SavingIndicator";
import { Question } from "../../../interfaces/shared/Question";
import { Cancel, Delete } from "@material-ui/icons";
import AddQuestionToPaperDialog from "./AddQuestionToPaperDialog";
import request, { isLoggedIn } from "../../../api";
import { Redirect } from "react-router-dom";

interface Props {
  id: string
}

interface State {
    paper:Paper|null,
    saving:boolean,
    showAddQuestionDialog:boolean
}

export default class ExaminerEditPaperPage extends React.Component<Props, State> {

  constructor(props:Props) {

      super(props)

      this.state = {
          paper:null,
          saving:false,
          showAddQuestionDialog:false
      }

      this.loadPaper()
  }

  render() {

    let paper:Paper|null = this.state.paper
    let saving = this.state.saving

    if(!isLoggedIn('examiner')) {
        return <Redirect to='/login' />
    }


    if(!paper) {
        return <ExaminerContainer heading={"Loading..."}>Loading...</ExaminerContainer>
    }

      return (
          <ExaminerContainer heading={"Edit Paper"}>
              {
                  this.state.showAddQuestionDialog &&
                  <AddQuestionToPaperDialog paper={paper} onAdd={this.onAddQuestionToPaper} onClose={this.onCloseAddQuestionDialog} />
              }
              <SavingIndicator saving={saving}/>
                <TextField
                    autoFocus
                    margin="dense"
                    id="name"
                    label="Paper Title"
                    fullWidth
                    defaultValue={paper.title}
                    onChange={this.handlePaperTitleChange}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    id="duration"
                    label="Duration (minutes)"
                    fullWidth
                    defaultValue={paper.duration}
                    onChange={this.handlePaperDurationChange}
                />
                <h2>Questions</h2>
             <TableContainer component={MaterialPaper}>
            <Table aria-label="simple table">
                <TableBody>
                    {paper.questions.map((question:Question, i:number) => (
                        <TableRow>
                            <TableCell component="th" scope="option">
                                {!question.isArchived && <Fragment>
                                    {i > 0 && <Button onClick={() => this.moveQuestion(i, i - 1)}>▲</Button>}
                                    {i < paper!.questions.length - 1 && <Button onClick={() => this.moveQuestion(i, i + 1)}>▼</Button>}
                                    <Button onClick={() => this.removeQuestion(i)}><Cancel /></Button>
                                </Fragment>}
                            </TableCell>
                            <TableCell align="left">
                                <Link href={'/examiner/questions/' + question.id}>{question.description}</Link>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell colSpan={3}>
                            <Button color="primary" variant="outlined" onClick={this.addQuestion}>
                                + Add Question
                            </Button>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
          </ExaminerContainer>
      )
  }


    handlePaperTitleChange = async (ev: any) => {

        let paper = this.state.paper as Paper

        let newPaper:Paper = { ...paper, title: ev.target.value }

        await this.setState(prevState => ({ ...prevState, paper: newPaper }))
        this.saveWithDelay()
    }

    handlePaperDurationChange = async (ev: any) => {

        let paper = this.state.paper as Paper

        let newPaper:Paper = { ...paper, duration: parseInt(ev.target.value) }

        await this.setState(prevState => ({ ...prevState, paper: newPaper }))
        this.saveWithDelay()
    }

    private async loadPaper() {

        let paper = await (await request('/api/examiner/papers/' + this.props.id)).json()

        this.setState(prevState => ({ ...prevState, paper }))
    }


    async saveWithDelay() {
        await this.setState(prevState => ({ ...prevState, saving: true }))
        this.saveDebounced()
    }

    saveDebounced = debounce(async () => this.save(), 1000)

    async save() {
        await request('/api/examiner/papers/' + this.props.id, {
            method: 'PUT',
            headers: { 'content-type': 'application/json' },
            body: JSON.stringify(this.state.paper)
        })
        await this.setState(prevState => ({ ...prevState, saving: false }))
    }

    addQuestion = () => {
        this.setState(prevState => ({ ...prevState, showAddQuestionDialog: true }))
    }

    onAddQuestionToPaper = async (question:Question) => {
        let body = {
            paper: this.props.id
        }
        await request(`/api/examiner/questions/${question.id}/paper`, {
            method: 'POST',
            headers: {
                'content-type': 'application/json'
            },
            body: JSON.stringify(body)
        })
        await this.setState(prevState => ({ ...prevState, showAddQuestionDialog: false }))
        this.loadPaper()
    }

    onCloseAddQuestionDialog = () => {
        this.setState(prevState => ({ ...prevState, showAddQuestionDialog: false }))
    }

    async moveQuestion(n: number, n2: number) {

        let paper = this.state.paper as Paper

        let newPaper:Paper = { ...paper }

        newPaper.questions.splice(n2, 0, newPaper.questions.splice(n, 1)[0])

        for(let n = 0; n < newPaper.questions.length; ++ n) {
            newPaper.questions[n].order = n
        }

        await this.setState(prevState => ({ ...prevState, paper: newPaper }))
        this.save()
    }

    async removeQuestion(n: number) {

        let paper = this.state.paper as Paper

        let newPaper:Paper = { ...paper }

        newPaper.questions.splice(n, 1)

        await this.setState(prevState => ({ ...prevState, paper: newPaper }))
        this.save()
    }


}






